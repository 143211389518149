import actions from '../actions/actions';

const initialState = {
    chipToDelete: null,
};

const filterCheckboxReducer = (state = initialState, action = {}) => {
    if (action.type === actions.UPDATE_FILTER_CHECKBOXES) {
        return { chipToDelete: action.newCheckBoxData };
    }
    return state;
};

export default filterCheckboxReducer;

import formRequest from './formRequest';

const refreshTokenUrl = `${window.config.AUTHENTICATION_URL}/refresh/access-token`;
const destroyRefreshTokenUrl = `${window.config.AUTHENTICATION_URL}/destroy/refresh-token`;

const requestAccessTokenWithRefreshToken = async () => {
    try {
        return formRequest(refreshTokenUrl);
    } catch (e) {
        throw new Error(`Fetch Error when request AccessToken With refresh token\n ${e}`);
    }
};


const destroyRefreshToken = async () => {
    try {
        return formRequest(destroyRefreshTokenUrl);
    } catch (e) {
        throw new Error(`Fetch Error when destroying refresh token\n ${e}`);
    }
};

export {
    requestAccessTokenWithRefreshToken,
    destroyRefreshToken,
};

const chunkString = (string, length) => string.match(new RegExp(`.{1,${length}}`, 'g'));

const getWordArray = (wordArrayTemp, seg, maxChar, resultArrayTemp) => {
    let wordArray = wordArrayTemp;
    const resultArray = resultArrayTemp;
    if (wordArray?.length >= 2) {
        wordArray = chunkString(seg, maxChar - 1);
        if (wordArray.length >= 1) {
            // Add every piece of word but the last, concatenated with '-' at the end
            wordArray.slice(0, -1).forEach((piece) => {
                resultArray.push(`${piece}-`);
            });
            // finally, add the last piece
            resultArray.push(wordArray[wordArray.length - 1]);
        }
    } else {
        resultArray.push(seg);
    }
    return resultArray;
};

const getHyphenatedText = (word, maxChar, addHyphens) => {
    let resultArray = [];
    let wordChunks = chunkString(word, maxChar);
    if (maxChar >= 1 && wordChunks?.length >= 1) {
        // just one under maxChar in order to add the innerword separator '-'
        wordChunks = chunkString(word, maxChar - 1);
        if (addHyphens && wordChunks.length >= 1) {
            const wordsFromString = word.split(' ');
            wordsFromString.forEach((seg) => {
                const wordArray = chunkString(seg, maxChar);
                resultArray = getWordArray(wordArray, seg, maxChar, resultArray);
            });
        } else {
            resultArray.push(chunkString(word, maxChar));
        }
    }
    // If nothing done, just use the same word
    if (resultArray.length === 0) {
        resultArray.push(word);
    }
    return resultArray;
};

export { chunkString, getHyphenatedText };

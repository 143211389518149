import styled from 'styled-components';
import CropFreeSharpIcon from '@material-ui/icons/CropFreeSharp';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core';
import theme from '../../../../tap-fe-components/src/theme';

export const FullScreenImage = styled.div`
    width: auto;
    padding: 16px;
`;

export const SmallImg = styled.img`
    ${props => (props.styleImg)}
`;

export const ImageWrapDiv = styled.div`
    width: 100%;
    position: relative;
    overflow-y: hidden;
`;

export const CloseButton = styled.button`
    border: none;
    background: transparent;
`;

export const OpenButton = styled.button`
    position: absolute;
    border: none;
    background: transparent;
    right: 0;
    height: 44px;
    width: 44px;
    margin: 12px 12px 0 0;
    padding: 0;
    z-index: 1;
`;

export const OpenButtonWrapper = styled.div`
    position: absolute;
    right: 0;
`;

export const HeaderImageStyle = styled.div`
    display: flex;
    align-items: center;
    background-color: ${theme.popupHeadingBgColor};
`;

export const HeaderTitle = styled.p`
    margin: 8px 0 0 16px;
    text-align: center;
    width: calc(100% - 55px);
    padding-bottom: 10px;
    font-size: 24px;
`;

export const StyledClose = withStyles({
    root: {
        fill: theme.accentColor,
        width: '24px',
        height: '24px',
        cursor: 'pointer',
        borderRadius: '50%',
        '&:hover': {
            background: theme.closeButtonBgColor,
        },
    },
})(CloseIcon);

export const StyledOpen = withStyles({
    root: {
        fill: theme.accentColor,
        width: '44px',
        height: '44px',
        cursor: 'pointer',
        border: `2px solid ${theme.accentColor}`,
        borderRadius: '6px',
        padding: '4px',
        background: '#ffffff',
    },
})(CropFreeSharpIcon);

export const DummyImageRender = styled.div`
    position: absolute;
    display: none;
`;

export const OuterWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: ${props => (1 / props.ratio) * 100}%;
`;

export const InnerWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

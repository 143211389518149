import originalFetch from 'unfetch';
import { redirect } from '../../helpers/logout/logout';

class HttpService {
    static responseHandler = async ({ status }) => {
        if (status === 401) {
            await redirect();
        }
    }

    static fetch = (input, init, ...args) => {
        const response = originalFetch(input, init, ...args);

        response.then(HttpService.responseHandler);

        return response;
    }
}

export default HttpService;

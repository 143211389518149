import actions from '../actions/actions';

const initialState = {
    tab: '',
};

const selectedStateReducer = (state = initialState, action = {}) => {
    if (action.type === actions.CHANGE_TAB) {
        return { tab: action.newSelectedData };
    }
    return state;
};

export default selectedStateReducer;

import React from 'react';
import PropTypes from 'prop-types';
import enhanceWithClickOutside from 'react-click-outside';
import cx from 'classnames';
import './scss/DrawingToolOptions.scss';

class DrawingToolOptions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeOption: props.options.find(option => option === props.value),
            showOptions: false,
            zIndex: 0,
        };

        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.setActiveOption = this.setActiveOption.bind(this);
        this.toggleOptions = this.toggleOptions.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.value !== nextProps.value) {
            this.setState({
                activeOption: nextProps.value,
            });
        }
    }

    setActiveOption(option) {
        this.setState({ activeOption: option });
        this.hideOptions();
        this.props.update(option);
    }

    getSelectedOptionIcon() {
        const { alt, Icon, t } = this.props;
        const { activeOption } = this.state;

        if (!this.props.shouldIconUpdate || !activeOption) {
            return typeof Icon === 'string' ? <img src={Icon} alt={alt} /> : <Icon />;
        }

        if (activeOption.useIcon) {
            return (
                <img
                    src={activeOption.icon}
                    alt={t(activeOption.translationKey, activeOption.defaultText)}
                />
            );
        }
        return typeof Icon === 'string' ? <img src={Icon} alt={alt} /> : <Icon color={activeOption.value} />;
    }

    handleClickOutside() {
        this.setState({ showOptions: false, zIndex: 0 });
    }

    hideOptions() {
        this.setState({ showOptions: false, zIndex: 0 });
    }

    toggleOptions() {
        if (this.state.showOptions) {
            this.hideOptions();
        } else {
            this.setState({ showOptions: true, zIndex: 100 });
        }
    }

    render() {
        const { isActive, options, t } = this.props;
        const { showOptions, zIndex } = this.state;
        const buttonClassName = cx({
            'drawing-tool-options__button': true,
            'drawing-tool-options__button-with-options': true,
            'drawing-tool-options__button-with-options--active': isActive,
        });

        return (
            <div className="drawing-tool-options" style={{ zIndex }}>
                <button
                    type="button"
                    className={buttonClassName}
                    onClick={() => { this.toggleOptions(); }}
                >
                    {this.getSelectedOptionIcon()}
                </button>
                {
                    showOptions && (
                        <ol className="drawing-tool-options__options">
                            {
                                options.map(option => (
                                    <li className="drawing-tool-options__option" key={option.value}>
                                        <button
                                            type="button"
                                            className="drawing-tool-options__button"
                                            onClick={() => { this.setActiveOption(option); }}
                                            style={
                                                option.useIcon ?
                                                    { backgroundColor: '#fff' } :
                                                    { backgroundColor: option.value }}
                                        >
                                            {
                                                option.useIcon ? (
                                                    <span className="drawing-tool-options__option-icon">
                                                        {
                                                            option.scale ? (
                                                                <img
                                                                    src={option.icon}
                                                                    alt={t(option.translationKey, option.defaultText)}
                                                                    style={{ transform: `scale(${option.scale})` }}
                                                                />
                                                            ) : (
                                                                <img
                                                                    src={option.icon}
                                                                    alt={t(option.translationKey, option.defaultText)}
                                                                />
                                                            )}

                                                    </span>
                                                ) : (
                                                    <span className="drawing-tool-options__option-text">
                                                        {option.value}
                                                    </span>
                                                )}
                                        </button>
                                    </li>
                                ))
                            }
                        </ol>
                    )}
            </div>
        );
    }
}

DrawingToolOptions.defaultProps = {
    isActive: false,
    shouldIconUpdate: true,
};

const option = PropTypes.shape({
    defaultText: PropTypes.string.isRequired,
    translationKey: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    scale: PropTypes.number,
    useIcon: PropTypes.bool.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
});

DrawingToolOptions.propTypes = {
    alt: PropTypes.string.isRequired,
    Icon: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
    ]).isRequired,
    isActive: PropTypes.bool,
    options: PropTypes.arrayOf(
        option.isRequired,
    ).isRequired,
    shouldIconUpdate: PropTypes.bool,
    update: PropTypes.func.isRequired,
    value: option.isRequired,
    t: PropTypes.func.isRequired,
};

export default enhanceWithClickOutside(DrawingToolOptions);

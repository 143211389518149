import actions from '../actions/actions';

const initialState = {
    isPrinting: false,
};

const printReportReducer = (state = initialState, action = {}) => {
    if (action.type === actions.PRINT_REPORT) {
        return { isPrinting: action.isPrinting };
    }
    return state;
};

export default printReportReducer;

import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';

import {
    withStyles, TextField,
} from '@material-ui/core';
import theme from '../../../../../../tap-fe-components/src/theme';
import Tooltip from '../../../../../../tap-fe-components/src/components/Tooltip';


const styles = {
    root: {
        background: props => (props.disabled ? theme.disableInputField : theme.white),
        borderRadius: props => (props.disabled ? '4px' : '8px'),
        border: props => (props.disabled ?
            `2px solid ${theme.disableInputFieldBorder}` : `2px solid ${theme.borderShade1}`),
        caretColor: theme.accentColor,
        width: '100%',
        '&.disabled': {
            background: theme.disableInputField,
        },
        '&:focus-within, &:hover': {
            '& $labelShrinked': {
                padding: '0 4px',
                background: theme.white,
            },
        },
        '&:hover': {
            border: props => (props.disabled ?
                `2px solid ${theme.disableInputFieldBorder}` : `2px solid ${theme.studentViewInputColor}`),
            background: props => (props.disabled ? theme.disableInputField : theme.backgroundColorShade2),
        },
    },
    labelRoot: {
        color: '#656CAD',
        font: '400 16px Roboto',
        lineHeight: '18px',
        '&$labelShrinked': {
            color: '#5D618E',
            left: '0px',
        },
    },
    labelShrinked: {
        padding: '0 4px',
        background: theme.white,
    },
    outlinedInputRoot: {
        border: 'none',
        paddingBottom: props => (props.showCharacterLength ? '60px' : null),
    },
    outlinedInputFocused: {},
    outlinedInputDisabled: {},
    outlinedInputNotchedOutline: {
        border: 'none',
    },
    outlinedInputInput: {
        color: theme.studentViewInputColor,
        font: '700 16px Roboto',
        lineHeight: '20px',
        '-webkit-text-fill-color': theme.studentViewInputColor,
    },
    outlinedInputInputMultiline: {
        color: theme.studentViewInputColor,
        font: 'inherit',
        lineHeight: 'inherit',
    },
    helper: {
        color: '#505050',
        fontSize: '14px',
        marginTop: '-60px',
        display: 'flex',
        lineHeight: '14px',
        borderTop: '1px solid rgba(194, 195, 212, 0.25)',
        padding: '12px 0',
    },
};

class EditableQuestionTextField extends React.Component {
    constructor(props) {
        super(props);
        const { value } = props;
        this.state = {
            uniqueId: uuid(),
            textValue: value,
        };
    }

    handleChange = (event) => {
        const { target: { value } } = event;
        const { onChange } = this.props;
        this.setState({
            textValue: value,
        });
        onChange(event);
    }

    render() {
        const {
            classes, InputProps, InputLabelProps, toolTipLabel, multilineCustomLabel, ...otherProp
        } = this.props;
        const { uniqueId, textValue } = this.state;
        const {
            labelRoot, labelShrinked, outlinedInputRoot, outlinedInputNotchedOutline,
            outlinedInputFocused, outlinedInputDisabled, outlinedInputInput, outlinedInputInputMultiline, inputBaseRoot,
            ...otherClasses
        } = classes;
        return (
            <Tooltip title={multilineCustomLabel ? toolTipLabel : ''}>
                <TextField
                    id={`textid-${uniqueId}`}
                    {...otherProp}
                    classes={otherClasses}
                    className="text-field-focus"
                    InputProps={{
                        ...InputProps,
                        classes: {
                            root: `${outlinedInputRoot} no-outline text-field-input`,
                            focused: outlinedInputFocused,
                            disabled: outlinedInputDisabled,
                            notchedOutline: outlinedInputNotchedOutline,
                            input: outlinedInputInput,
                            inputMultiline: outlinedInputInputMultiline,
                            inputBaseRoot,
                        },
                    }}
                    InputLabelProps={{
                        ...InputLabelProps,
                        classes: {
                            root: labelRoot,
                            shrink: labelShrinked,
                        },
                    }}
                    FormHelperTextProps={{ classes: { root: classes.helper }, component: 'div' }}
                    value={textValue}
                    onChange={this.handleChange}
                />
            </Tooltip>
        );
    }
}

EditableQuestionTextField.propTypes = {
    classes: PropTypes.shape({
        labelRoot: PropTypes.string.isRequired,
        labelShrinked: PropTypes.string.isRequired,
        outlinedInputRoot: PropTypes.string.isRequired,
        outlinedInputNotchedOutline: PropTypes.string.isRequired,
        outlinedInputFocused: PropTypes.string.isRequired,
        outlinedInputDisabled: PropTypes.string.isRequired,
        outlinedInputInput: PropTypes.string.isRequired,
        outlinedInputInputMultiline: PropTypes.string.isRequired,
        inputBaseRoot: PropTypes.string.isRequired,
        helper: PropTypes.string.isRequired,
    }).isRequired,
    InputProps: PropTypes.oneOfType([PropTypes.object]).isRequired,
    InputLabelProps: PropTypes.shape({
        shrink: PropTypes.bool,
    }).isRequired,
    value: PropTypes.oneOfType([PropTypes.string]),
    onChange: PropTypes.func.isRequired,
};

EditableQuestionTextField.defaultProps = {
    value: '',
};

export default withStyles(styles)(EditableQuestionTextField);

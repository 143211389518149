const allowedHtmlTags = [
    // 'div',
    'span',

    // 'p',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',

    'b',
    'i',
    'u',

    'table',
    'th',
    'tr',
    'td',

    'img',
    'a',
    'link',
];

const NodeTypeValues = {
    element: 'element',
    text: 'text',
    cData: 'cdata',
};

const tags = {
    emptyString: '',
    p: 'p',
    value: 'value',
    correctResponse: 'correctResponse',
    expectedLength: 'expectedLength',
    textEntryInteraction: 'textEntryInteraction',
    extendedTextInteraction: 'extendedTextInteraction',
    cardinality: 'cardinality',
    mapping: 'mapping',
    mapEntry: 'mapEntry',
    single: 'single',
    multiple: 'multiple',
    simpleChoice: 'simpleChoice',
    inlineChoice: 'inlineChoice',
    simpleMatchSet: 'simpleMatchSet',
    responseDeclaration: 'responseDeclaration',
    outcomeDeclaration: 'outcomeDeclaration',
    itemBody: 'itemBody',
    choiceInteraction: 'choiceInteraction',
    inlineChoiceInteraction: 'inlineChoiceInteraction',
    matchInteraction: 'matchInteraction',
    associableHotspot: 'associableHotspot',
    applicationXml: 'application/xhtml+xml',
    parsererror: 'parsererror',
    assessmentItem: 'assessmentItem',
    identifier: 'identifier',
    responseProcessing: 'responseProcessing',
    stringMatch: 'stringMatch',
    match: 'match',
    caseSensitive: 'caseSensitive',
    false: 'false',
    variable: 'variable',
    mapKey: 'mapKey',
    mappedValue: 'mappedValue',
    title: 'title',
    chartData: 'chartData',
    xAxis: 'xAxis',
    yAxis: 'yAxis',
    gridLabel: 'gridLabel',
    pointMatrix: 'pointMatrix',
    point: 'point',
    selectablePoints: 'selectablePoints',
    barMatrix: 'barMatrix',
    bar: 'bar',
    snap: 'snap',
    barGroup: 'barGroup',
    legend: 'legend',
    legendLabel: 'legendLabel',
    selectPointInteraction: 'selectPointInteraction',
    prompt: 'prompt',
    div: 'div',
    table: 'table',
    caption: 'caption',
    graphicGapMatchInteraction: 'graphicGapMatchInteraction',
    object: 'object',
    gapImg: 'gapImg',
    gapText: 'gapText',
    drawingInteraction: 'drawingInteraction',
    img: 'img',
    responseCondition: 'responseCondition',
    responseIf: 'responseIf',
    and: 'and',
    hashText: '#text',
};

const stringAttributes = [
    'prefix',
    'suffix',
];

const booleanAttributes = [
    'caseSensitive',
    'shuffle',
];

const numberAttributes = [
    'mappedValue',
    'width',
    'height',
    'matchMax',
    'maxAssociations',

    'expectedLines',
    'expectedLength',
    'maxChoices',
    'minChoices',

    'minorTickInterval',
    'majorTickInterval',
    'xIdentifier',
    'yIdentifier',

    'min',
    'max',
];

const ViewMode = {
    allOrNothing: 'defaultScoring',
    partialScoring: 'partialScoring',
};

export {
    allowedHtmlTags,
    NodeTypeValues,
    tags,

    stringAttributes,
    booleanAttributes,
    numberAttributes,
    ViewMode,
};

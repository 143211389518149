import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import {
    FormControlLabel, FormControl, Radio, RadioGroup, FormGroup,
} from '@material-ui/core';
import theme from '../../../../../../tap-fe-components/src/theme';

const choiceOptionPadding = props => (props.height ? `${+Number(props.height / 2).toFixed(2)}px` : '0px');
const choiceImageHeight = ({ height }) => height && `${height}px`;
const choiceImageWidth = ({ width }) => width && `${width}px`;

const ChoiceRadio = withStyles({
    root: {
        color: theme.studentViewInputColor,
        backgroundColor: '#FFFFFF',
        backgroundClip: 'content-box',
        width: '40px',
        height: '40px',
        marginBottom: 'auto',
        marginLeft: '6px',
        marginRight: '8px',
        '&$checked': {
            color: theme.studentViewInputColor,
            '&:hover': {
                background: theme.studentViewHoverBackground,
            },
        },
        '&:hover': {
            background: theme.studentViewHoverBackground,
        },
        '&:active, &.Mui-focusVisible': {
            background: theme.studentViewActiveBackground,
        },
        '&.Mui-focusVisible': {
            outline: `1px solid ${theme.focusOutline}`,
        },
        '&.Mui-disabled': {
            color: theme.studentViewInputColor,
            opacity: '0.5',
        },
        '& svg': {
            fill: 'currentColor',
            width: '20px',
            height: '20px',
        },
    },
    checked: {},
    disabled: {},
})(Radio);

const ChoiceRadioGroup = withStyles({
    root: {
        flex: '1',
        position: 'relative',
    },
})(RadioGroup);

const StyledFormGroup = withStyles({
    root: {
        display: 'block',
    },
})(FormGroup);

const RadioControlLabel = withStyles({
    root: {
        backgroundColor: props => (props.hasTick ? '#FAFAFA' : '#FFFFFF'),
        background: props => (props.hasTick ? '' : 'radial-gradient(circle, #FFFFFF 9px, #FAFAFA 9px)'),
        paddingLeft: props => (props.hasTick ? '7px' : ''),
        borderRadius: '6px',
        marginLeft: '0px',
        marginRight: '0px',
    },
})(FormControlLabel);

const ChoiceControl = withStyles({
    root: {
        width: '100%',
    },
})(FormControl);

const ChoiceText = styled.div`
    flex: 1.5;
    flex-wrap: wrap;
    min-height: 60px;
    display: flex;
    align-items: center;

    &> p, &> img {
        &:first-child {
            margin: 0;
        }
        &:not(:first-child) {
            margin: 16px 0 0;
        }
    }
    &> img {
        max-width: 376px;
        @media (min-width: 1366px) and (max-width: 1600px) {
            &.question-container {
                max-width: 300px;
            }
        }
    }
`;

const SingleQuestionWrapper = styled.div`
    display: flex;
    color: #332A7C;
    align-items: center;
    padding: 8px 0;
`;

const SingleChoiceQuestionWrapper = styled.div`
    display: flex;
    color: #332A7C;
    align-items: center;
    padding: 8px 0;
`;

const ChoiceOptionsWrapper = styled.div`
    display: flex;
    color: #332A7C;
    align-items: center;
    justify-content: flex-end;
`;

const OptionText = styled.div`
    font: 16px Roboto;
    color: #332A7C;
    font-weight: 500;
    width: 94px;
    text-align: center;
    &:not(:last-child) {
        margin-right: 16px;
    }
`;

const OptionHeader = styled.div`
    text-align: center;
    display: flex;
    margin: 0;
    padding-bottom: 18px;
`;

const OptionHeaderText = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

const ChoiceOption = styled.div`
    display: flex;
    justify-content: center;
    padding-top: ${choiceOptionPadding};
    padding-bottom: ${choiceOptionPadding};
    background-color: #FAFAFA;
    border-radius: 6px;
    cursor: pointer;
    &:not(:last-child) {
        margin-right: 16px;
    }
    pointer-events: ${props => (props.disabled ? 'none' : 'all')};
`;

const RadioControlLabelWrapper = styled.div`
    display: flex;
    justify-content: ${props => (props.hasTick ? 'flex-start' : 'center')};
    width: 94px;
    background-color: ${props => (props.hasTick ? '#FAFAFA' : 'inherit')};
    border-radius: 6px;
`;

const Answerlabel = styled.div`
    color: #5D618E;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    margin-left: 0px;
    margin-bottom: 16px;
`;

const NoGuidanceWrapper = styled.div`
    color: #332A7C;
`;

const QuestionContainer = styled.div`
    display: flex;
`;

const LeftSideWrapper = styled.div`
    flex: 1;
    min-width: 376px;
    overflow-x: auto;
    margin-right: 16px;
    margin-top: ${props => props.marginTop};
    @media (min-width: 1366px) and (max-width: 1600px) {
        &.question-container {
            min-width: 300px;
        }
    }
`;

const RightSideWrapper = styled.div`
    overflow: hidden;
    position: relative;

    .shadow {
        bottom: 0;      
        left: 0;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 0;
        transition: all .3s ease-out;
    }

    &.off-left {
        .shadow-left {
            background-image: linear-gradient(to right, rgba(255,255,255,0.8), rgba(255,255,255,0) 10%);
        }
    }

    &.off-right {    
        .shadow-right {
            background-image: linear-gradient(to left, rgba(255,255,255,0.8), rgba(255,255,255,0) 10%);
        }
    }
`;

const ScrollBox = styled.div`
    overflow-x: auto;
`;
export const TickIconWrapper = styled.div`
    margin-left: 0;
    align-self: center;
    padding: 8px 8px 8px 0;

    svg {
        vertical-align: middle;
    }
`;

const ChoiceImage = styled.img`
    border-radius: 6px;
    min-width: 200px;
    min-height: 200px;
    max-height: 360px;
    max-width: 100%;
    height: ${choiceImageHeight};
    width: ${choiceImageWidth};
    @media (min-width: 1366px) and (max-width: 1600px) {
        &.question-container {
            max-width: 300px;
        }
    }
`;

const TtsOnlyDiv = styled.div`
    display: none;
`;

export {
    OptionText, ChoiceRadio, RadioControlLabel, StyledFormGroup, OptionHeaderText, ChoiceOption,
    ChoiceControl, ChoiceText, ChoiceImage, OptionHeader, SingleChoiceQuestionWrapper, ChoiceRadioGroup,
    RadioControlLabelWrapper, Answerlabel, NoGuidanceWrapper, ChoiceOptionsWrapper,
    QuestionContainer, LeftSideWrapper, RightSideWrapper, SingleQuestionWrapper, ScrollBox, TtsOnlyDiv,
};

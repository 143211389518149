import tokenHasExpired from './tokenHasExpired';

export const getAccessToken = () => localStorage.getItem('accessToken');
export const getAccessTokenExpiry = () => localStorage.getItem('accessTokenExpiry');
export const setAccessToken = (token) => {
    localStorage.setItem('accessToken', token);
};
export const deleteAccessToken = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('accessTokenExpiry');
};
export const accessTokenIsValid = () => (
    !!(localStorage.getItem('exp') && !tokenHasExpired(localStorage.getItem('exp'))));
export const setUserToken = (token) => {
    localStorage.setItem('userToken', token);
};
export const getUserToken = () => localStorage.getItem('userToken');

export const clearLocalStorage = () => {
    localStorage.clear();
};
export const setUserName = (firstName, lastName, username) => {
    localStorage.setItem('firstName', firstName);
    localStorage.setItem('lastName', lastName);
    if (username !== undefined) {
        localStorage.setItem('username', username);
    }
};

export const getUserName = () => localStorage.getItem('username');

export const setTokenExpiry = (exp) => {
    localStorage.setItem('exp', exp);
};

import actions from '../actions/actions';

const initialState = {
    standardsInQuestion: [],
};

const standardsInQuestionReducer = (state = initialState, action = {}) => {
    if (action.type === actions.UPDATE_STANDARDS_IN_QUESTION) {
        if (state.standardsInQuestion !== action.standardsInQuestion) {
            return { standardsInQuestion: action.standardsInQuestion };
        }
    }
    return state;
};

export default standardsInQuestionReducer;

import UserRoles from '../components/NavigationDrawer/UserRoles.enum';
import {
    getDistrictId,
    getIsLTIUser,
    getUserId,
    getUserRole,
} from './tokenHelper';

const pendoInitialize = (data) => {
    if (!window.pendo?.identify) {
        return;
    }

    const TRACK_ROLES = [
        UserRoles.AUTHOR,
        UserRoles.TEACHER,
        UserRoles.STUDENT,
        UserRoles.SCHOOL_ADMIN,
        UserRoles.DISTRICT_OWNER,
        UserRoles.TWIG_ADMIN,
    ];

    const {
        id,
        district_id: districtId,
        role,
        isltiUser,
    } = data;

    if (!TRACK_ROLES.includes(role)) {
        return;
    }

    const visitor = {
        id,
        is_lti_user: isltiUser,
        role,
    };

    const account = {
        id: districtId,
    };

    window.pendo.identify({
        visitor,
        account,
    });
};

const pendoClearSession = () => {
    // eslint-disable-next-line no-unused-expressions
    window.pendo?.clearSession?.();
};

const pendoInitializeIfNotAlready = () => {
    if (window.pendo?.isAnonymousVisitor?.()) {
        const id = getUserId();
        const districtId = getDistrictId();
        const role = getUserRole();
        const isltiUser = getIsLTIUser();

        pendoInitialize({
            id,
            district_id: districtId,
            role,
            isltiUser,
        });
    }
};

export {
    pendoInitialize,
    pendoClearSession,
    pendoInitializeIfNotAlready,
};

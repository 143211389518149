import {
    deleteAccessToken, deleteRefreshToken,
    clearLocalStorage,
} from '../../helpers/tokenHelper';
import {
    destroyRefreshToken,
} from '../../helpers/authentication';
import ssoLoginUrl from './urls';

const unloadUser = async () => {
    try {
        await destroyRefreshToken();
        await deleteAccessToken();
        await deleteRefreshToken();
        await clearLocalStorage();
        window.location.href = `${ssoLoginUrl}`;
        return true;
    } catch (error) {
        return false;
    }
};

export default unloadUser;

import styled from 'styled-components';

const imageHeight = ({ height }) => height && `${height}px`;
const imageWidth = ({ width }) => width && `${width}px`;

const OptionText = styled.div`
    margin: 11px 11px 11px 0 !important;
    font: 16px Roboto;
    color: #332A7C;
    text-decoration: none;
    font-weight: 400;

    .sync_sent_highlighted {
        white-space: nowrap;
    }
`;

const OptionImage = styled.img`
    border-radius: 6px;
    margin-top: 11px;
    min-width: 200px;
    min-height: 200px;
    max-height: 360px;
    max-width: 484px;
    height: ${imageHeight};
    width: ${imageWidth};
`;

const OptionImageDiv = styled.div`
`;

const TtsOnlySpan = styled.span`
    display: none;
`;

export {
    OptionText, OptionImage, TtsOnlySpan, OptionImageDiv,
};

import React from 'react';
import {
    Router, Route, Switch, Redirect,
} from 'react-router-dom';
import { createBrowserHistory } from 'history';
import CMSRoute from './apps/CMS/routes';
import StudentRoute from './apps/Student/routes';
import TeacherRoute from './apps/Teacher/routes';
import LoginRoute from './apps/Login/routes';
import PreviewRoute from '../../tap-questions-component/src/routes';
import AdminRoute from './apps/Admin/routes';
import PsychometricAdminRoute from './apps/PsychometricAdmin/routes';
import {
    loginCallbackRoute,
    logoutCallbackRoute,
    loginRoute,
    ltiLoginRoute,
} from './apps/Authentication/routes';

const history = createBrowserHistory();

const Routes = () => (
    <Router history={history}>
        <Switch>
            {loginCallbackRoute}
            {logoutCallbackRoute}
            {loginRoute}
            {LoginRoute}
            {PreviewRoute}
            {CMSRoute}
            {AdminRoute}
            {PsychometricAdminRoute}
            {StudentRoute}
            {TeacherRoute}
            {ltiLoginRoute}
            <Route path="*" key="home-redirect" render={() => <Redirect to="/" />} />
        </Switch>
    </Router>
);

export default Routes;
export { history };

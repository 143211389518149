import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';

const LoaderWrapper = styled.div`
    background: #ffffff;
    color: #666666;
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 5000;
    top: 0;
    left: 0;
    float: left;
    text-align: center;
    padding-top: 20%;
    opacity: .80;
    `;

const LoadingPlaceholder = () => (
    <LoaderWrapper>
        <CircularProgress
            color="secondary"
            size={50}
            disableShrink
        />
    </LoaderWrapper>
);

export default LoadingPlaceholder;

// COMMON
export const QUESTION_XML = 'xmlns="http://www.imsglobal.org/xsd/imsqti_v2p2"';
export const ALTERNATIVE = 'Alternative';
export const TEXTHTML = 'text/html';
export const AND = 'and';
export const XAXIS = 'xAxis';
export const YAXIS = 'yAxis';
export const POINT = 'point';
export const STRING_MATCH = 'stringMatch';
export const MATCH = 'match';
export const SET_OUTCOME_VALUE = 'setOutcomeValue';
export const MAP_RESPONSE = 'mapResponse';
export const XHTML_XML = 'application/xhtml+xml';
export const TEXT = 'text';
export const DROP = 'drop';
export const trueText = 'true';
export const RESIZE = 'resize';
export const RIGHT = 'right';
export const XCOORDINATE = 'xCoordinate';
export const DESC = 'desc';
export const POINT_X = 'x';
export const OUTLINED = 'outlined';
export const X_IDENTIFIER_LABEL = 'xIdentifier';
export const FALSE_TEXT = 'false';
export const RESPONSE = 'RESPONSE';
export const POINT_X1 = 'x1';
export const EMPTY_STRING = '';
export const ROLE_APPLICATION = 'application';
export const IMAGETEXT = 'image';
export const OPTIONTEXT = 'option';
export const CHECKBOX = 'checkbox';
export const SELECT_OPTION_TEXT = 'select option';
export const IMAGEDOTTEXT = 'image.';
export const CATEGORY = 'Category';

// TAGS
export const DIV = 'div';
export const P_TAG = 'p';
export const CORRECT_RESPONSE = 'correctResponse';
export const VALUE = 'value';
export const CHOICE_INTERACTION = 'choiceInteraction';
export const SIMPLE_CHOICE = 'simpleChoice';
export const RESPONSE_DECLARATION = 'responseDeclaration';
export const TR_TAG = 'tr';
export const CAPTION = 'caption';
export const MATCH_INTERACTION = 'matchInteraction';
export const SIMPLE_MATCH_SET = 'simpleMatchSet';
export const INLINE_CHOICE_INTERACTION = 'inlineChoiceInteraction';
export const INLINE_CHOICE = 'inlineChoice';
export const DATA_WIDTH = 'data-width';
export const ITEM_BODY = 'itemBody';
export const EXTENDED_TEXT_INTERACTION = 'extendedTextInteraction';
export const TEXT_ENTRY_INTERACTION = 'textEntryInteraction';
export const DRAWING_INTERACTION = 'drawingInteraction';
export const OBJECT = 'object';
export const GRAPHIC_GAP_MATCH_INTERACTION = 'graphicGapMatchInteraction';
export const ASSOCIABLE_HOTSPOT = 'associableHotspot';
export const GRID_LABEL = 'gridLabel';
export const POINT_MATRIX = 'pointMatrix';
export const OUTCOME_DECLARATION = 'outcomeDeclaration';
export const RESPONSE_CONDITION = 'responseCondition';
export const SUM = 'sum';
export const LEGEND_LABEL = 'legendLabel';
export const BAR_GROUP = 'barGroup';
export const ASSESSMENT_ITEM = 'assessmentItem';
export const BAR_MATRIX = 'barMatrix';
export const SELECT_POINT_INTERACTION = 'selectPointInteraction';
export const MAPPING = 'mapping';
export const MAP_ENTRY = 'mapEntry';
export const CHART_DATA = 'chartData';
export const BAR = 'bar';
export const SNAP = 'snap';
export const IMAGE = 'img';
export const IMG = 'img';

// ATTRIBUTES
export const CLASS = 'class';
export const QUESTION = 'question';
export const PROMPT = 'prompt';
export const RESPONSE_IDENTIFIER = 'responseIdentifier';
export const IDENTIFIER = 'identifier';
export const SELECT_LABEL = 'selectLabel';
export const PLACEHOLDER_TEXT = 'placeholderText';
export const EXPECTED_LENGTH = 'expectedLength';
export const EXPECTED_LINES = 'expectedLines';
export const WIDTH = 'width';
export const HEIGHT = 'height';
export const DATA = 'data';
export const DATASTAMP = 'data-stamp';
export const DATAWIDTH = 'data-width';
export const DATAHEIGHT = 'data-height';
export const COORDS = 'coords';
export const SHAPE = 'shape';
export const MACTH_MAX = 'matchMax';
export const TYPE = 'type';
export const DATA_HOTSPOT_IDENTIFIER = 'data-hotspot-identifier';
export const HOTSPOT_LABEL = 'hotspotLabel';
export const SNAPPING = 'snapping';
export const BASE_TYPE = 'baseType';
export const LABEL = 'label';
export const XIDENTIFIER = 'xIdentifier';
export const YIDENTIFIER = 'yIdentifier';
export const SRC = 'src';
export const ALT = 'alt';
export const PREDEFINED_TEXT = 'predefinedText';

// QUERY SELECTOR
export const GAP_IMG = 'gapImg';
export const GAP_TEXT = 'gapText';

// IDENTIFIERS
export const CHOICE_MULTIPLE = 'choiceMultiple';
export const CHOICE = 'choice';
export const CHOICE_MATRIX = 'choiceMatrix';
export const SINGLE_LINE_TEXTENTRY = 'singleLineTextEntry';
export const MULTI_LINE_TEXTENTRY = 'multiLineTextEntry';
export const EXTENDED_TEXT = 'extendedText';
export const EDITABLE_TABLE_TEXT = 'editableTableText';
export const EDITABLE_TABLE_DROP = 'editableTableDrop';
export const DRAWING = 'drawing';
export const ASSOCIATE = 'associate';
export const GROUP_ASSOCIATE = 'groupAssociate';
export const GENERIC = 'generic';
export const TEXT_ENTRY = 'textEntry';
export const MIXED_TABLE = 'mixedTable';
export const TABLE_WITH_CHECKBOX = 'tableWithCheckbox';
export const LINE_CHART = 'lineChart';
export const BAR_CHART_WITH_SNAPPING = 'barChartWithSnapping';
export const GROUPED_BAR_CHART_WITH_SNAPPING = 'groupedBarChartWithSnapping';
export const BAR_CHART_WITHOUT_SNAPPING = 'barChartWithoutSnapping';
export const GROUPED_BAR_CHART_WITHOUT_SNAPPING = 'groupedBarChartWithoutSnapping';
export const BAR_CHART = 'barChart';
export const TRUE_VALUE_LOWER = 'true';
export const TRUE_VALUE_UPPER = 'TRUE';
export const GROUPED_BAR_CHART = 'groupedBarChart';
export const STAMP_IMAGES = 'stampImages';

// Constant values
export const removePointIconButton = 'remove-graph-point-button';
export const removePointIconLabel = 'Remove graph point';
export const backgroundCanvas = 'background-canvas';
export const foregroundCanvas = 'foreground-canvas';
export const fixedText = 'fixed';
export const canvasDimension = '2d';
export const legend = 'Legend';
export const option = 'Option';
export const errorMessageContainerClass = 'ErrorMessageContainer';

// Aria Label
export const GRAPH_MSG = "Here's a graph.";
export const XAXIS_MSG = 'The x-axis ranges from';
export const TO = 'to';
export const COMMA = ',';
export const LABELLED_AS = 'labelled as';
export const YAXIS_MSG = 'y-axis ranges from';
export const IS_FIXED = 'Is fixed point';
export const END_OF_GRAPH = 'End of graph';
export const PRESS_BACKSPACE = 'Press backspace to remove the point';
export const PRESS_SPACE = 'Press space to plot the point';

import { createStore, combineReducers } from 'redux';
import headerStateReducer from './reducers/headerStateReducer';
import loaderStateReducer from './reducers/loaderStateReducer';
import selectedStandardsStateReducer from './reducers/selectedStandardsStateReducer';
import filterChipsReducer from './reducers/filterChipsReducer';
import filterCheckboxReducer from './reducers/filterCheckboxReducer';
import selectedStateReducer from './reducers/selectedStateReducer';
import standardsInQuestionReducer from './reducers/standardsInQuestionReducer';
import maintainCheckboxStateReducer from './reducers/maintainCheckboxStateReducer';
import printReportReducer from './reducers/printReportReducer';
import showHideLoaderReducer from './reducers/showHideLoaderReducer';
import liveClassMonitoringTableCellStateReducer from './reducers/liveClassMonitoringTableCellStateReducer';
import editAssessmentDirtyReducer from './reducers/editAssessmentDirtyReducer';
import updateTtsLanguageReducer from './reducers/ttsLanguageDataReducer';
import showSnackBarOnSaveReducer from './reducers/showSnackBarOnSaveReducer';
import subscriptionManagerReducer from './reducers/subscriptionManagerReducer';
import logoutCallbackReducer from './reducers/logoutCallbackReducer';
import sharePageFilterStateReducer from './reducers/sharePageFilterStateReducer';
import tokenStateReducer from './reducers/tokenStateReducer';

const rootReducer = combineReducers({
    headerState: headerStateReducer,
    filterChips: filterChipsReducer,
    filterCheckBoxes: filterCheckboxReducer,
    selectedStandardsState: selectedStandardsStateReducer,
    standardsInQuestionState: standardsInQuestionReducer,
    loaderState: loaderStateReducer,
    selectedState: selectedStateReducer,
    previousCheckboxState: maintainCheckboxStateReducer,
    printReport: printReportReducer,
    loaderListState: showHideLoaderReducer,
    lcmTableCellState: liveClassMonitoringTableCellStateReducer,
    editAssessmentState: editAssessmentDirtyReducer,
    ttsLanguageData: updateTtsLanguageReducer,
    snackBarState: showSnackBarOnSaveReducer,
    subscriptionManagerState: subscriptionManagerReducer,
    logoutCallbackState: logoutCallbackReducer,
    sharePageFilterState: sharePageFilterStateReducer,
    token: tokenStateReducer,
});

const store = createStore(rootReducer);

export default store;

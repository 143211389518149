import styled from 'styled-components';

const QuestionTextWrapper = styled.div`
    color: #332A7C;
    margin-bottom: ${props => (props.isBottomPadding ? '24px' : '0px')};
    word-break: break-word;

    .question {
        margin-bottom: ${props => (props.isBottomPadding ? '16px' : '0px')};
    }

    .question > p:first-child {
        margin-top: 0;
    }

    .question > p:last-child {
        margin-bottom: ${props => (props.isBottomPadding || props.isExtraTag ? '16px' : '0px')};
    }    

    &> p:last-child {
        margin: 0 0;
        white-space: normal;
        margin-bottom: ${props => (props.isBottomPadding ? '16px' : '0px')};
        margin-top: ${props => (props.isOtherTag ? '16px' : '0px')};
    }

    table {
        border-collapse: collapse;
        border-style: solid;
    }

    .visually-hidden {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
`;

const TTSOnly = styled.span`
    display: none;
`;

export { QuestionTextWrapper, TTSOnly };

const UserRoles = {
    AUTHOR: 'AUTHOR',
    TEACHER: 'TEACHER',
    STUDENT: 'STUDENT',
    SCHOOL_ADMIN: 'SCHOOL_ADMIN',
    DISTRICT_OWNER: 'DISTRICT_OWNER',
    TWIG_ADMIN: 'TWIG_ADMIN',
    SUBSCRIPTION_MANAGER: 'SUBSCRIPTION_MANAGER',
};

export default UserRoles;

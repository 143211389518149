import en from '../../../json/en.json';

class StringTable {
    static decodedStringTable = en;

    static baseApiUrl = '';

    static getString(app, primaryKey, secondaryKey = null, values = null) {
        let string;

        if (secondaryKey === null) {
            string = this.decodedStringTable[app][primaryKey];
        } else {
            string = this.decodedStringTable[app][primaryKey][secondaryKey];
        }

        if (string && values) {
            return string.replace(/{([^{}]*)}/g,
                (a, b) => {
                    const r = values[b];
                    return typeof r === 'string' || typeof r === 'number' ? r : a;
                });
        }
        return string;
    }
}

export default StringTable;

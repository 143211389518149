import React from 'react';
import PropTypes from 'prop-types';

const Fill = ({ color }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22">
        <g fill={color} stroke="#CFCDCD" strokeWidth="2">
            <rect x="1" y="1" width="20" height="20" rx="2" />
        </g>
    </svg>
);

Fill.defaultProps = {
    color: 'rgba(0, 0, 0, 0)',
};

Fill.propTypes = {
    color: PropTypes.string,
};

export default Fill;

const fonts = {
    buttonFontWeight: 'bold',
};

const colors = {
    primaryColor: '#1B8367',
    black: '#000000',
    white: '#FFFFFF',
    red: '#D10107',
    navBGColor: '#332A7C',
    selectedColor: '#120957',
    borderColor: 'rgba(0, 0, 0, 0.12)',
    textFieldBorderBottomColor: 'rgba(112, 112, 112, 1)',
    shrinkTextColor: 'rgba(102, 102, 102, 1)',
    underlineColor: 'rgba(34, 34, 34, 1)',
    backgroundColor: '#FBFBFB',
    searchBoxBorderColor: '#707070',
    closeButtonBgColor: 'rgba(27, 131, 103, 0.04)',
    popupHeadingBgColor: 'rgb(244, 244, 244)',
    secondaryTextColor: 'rgba(102, 102, 102, 1)',

    // style guide update
    styleGuidePrimaryColor: '#343A72',
    styleGuidePrimaryColorShade1: '#4C5283',
    styleGuidePrimaryColorShade2: '#E7E7F7',
    accentColor: '#16734A',
    accentColorHover: '#055D45',
    accentColorActive: '#064131',
    accentColorHoverLight: '#EDF5F3',
    accentColorActiveLight: '#C8E1DA',
    redStatus: '#CA2017',
    greenStatus: '#00792D',
    boxShadow: '#0000003D',
    filterShadow: 'rgba(0, 0, 0, 0.24)',
    focusOutline: '#222222',
    errorColorHover: '#FFDFDF',
    errorColorActive: '#A80800',
    errorColorActiveLight: '#FFB1B1',
    accentColorHoverBackground: '#16734A14',
    accentColorActiveBackground: '#16734A1F',
    searchBoxActive: '#E7F3F0',
    searchBoxText: '#666666',
    blackText: '#222222',
    boxShadowShade1: '#00000029',
    backgroundColorShade1: '#A7A7FF',
    textDefault: '#666666',
    selectedTextDefault: '#222222',
    menuItemHover: '#E7F3F0',
    menuItemSelected: '#C8E1DA',
    redError: '#CA2017',
    defaultTextNumberField: '#656CAD',
    lightGreyBackground: '#F1F1F1',
    darkGreyBackground: '#E2E2E2',
    blackShade1: '#222222',
    blackShade2: '#070707',
    blackShade3: '#333333',
    studentViewInputColor: '#332A7C',
    studentViewHoverBackground: '#332A7C14',
    studentViewActiveBackground: '#332A7C1F',
    readSpeakerBackground: '#F6F9FC',
    readSpeakerFocusHoverBackground: '#CFD7DF',
    borderShade1: '#767584',
    backgroundColorShade2: '#EFF0F8',
    backgroundColorShade3: '#EBECF6',
    placeholderColor: '#666666',
    searchBarText: '#222222',
    dropDownHover: '#5d618e',
    absentText: '#666666',
    decimalText: '#505050',
    disableInputField: '#EFEFEF',
    disableInputFieldBorder: '#D2D2D2',
    characterCountColor: '#6e6e6e',
    expansionSummaryBorder: '#E0E0E0',
    settingPanel: '#636363',
    headerTitle: '#1E1E20',
    noResultText: '#CDCDCE',
    idTextColor: '#666666',
    chartLabelColor: '#222222',
    datePickerDayColor: '#222222',
    disabledStudentColor: '#16734A',
    feedbackIconColor: '#5A5394',
    feedbackWrapperColor: '#CEEDFF',
    feedbackIconBGColor: 'rgba(231, 231, 247, 0.5)',
    feedbackAddedShareResultsBGColor: '#EDF5F3',
    feedbackNotAddedShareResultsBGColor: '#FFF5CD',
    feedbackAddedShareResultsBorderColor: '#C8E1DA',
    feedbackNotAddedShareResultsBorderColor: '#F5C8A0',

    // Pattern Array Based On Base Color
    slantingLineBaseColor: '#FBAEAE',
    slantingLinePatternBackgroundColor: '#C50000',
    horizontalLinesBaseColor: '#FBCACA',
    horizontalLinesPatternBackgroundColor: '#CA3D3D',
    slantingLineMirrorBaseColor: '#FFDBDB',
    slantingLineMirrorPatternBackgroundColor: '#CF4A4A',
    strokedCirclesBaseColor: '#F6BD89',
    strokedCirclesPatternBackgroundColor: '#755B42',
    closedCirclesBaseColor: '#F5C8A0',
    closedCirclesPatternBackgroundColor: '#8F6F24',
    dotsBaseColor: '#FFF5CD',
    dotsPatternBackgroundColor: '#807340',
    verticalLinesBaseColor: '#DBFEC4',
    verticalLinesPatternBackgroundColor: '#57803C',
    crossBaseColor: '#C3F5C4',
    crossPatternBackgroundColor: '#398F6B',
    diamondsBaseColor: '#ACE9AE',
    diamondsPatternBackgroundColor: '#4D694E',
    squaresBaseColor: '#A1E1A3',
    squaresPatternBackgroundColor: '#007718',
    trianglesBaseColor: '#FFD9B7',
    trianglesPatternBackgroundColor: '#807340',
    slantingLinesMirrorBaseColor: '#FFF5',
    slantingLinesMirrorPatternBackgroundColor: '#807340',
    HeaderBgColor: 'rgba(231, 231, 247)',
    headerTextColor: 'rgb(34, 34, 34)',
};

const theme = {
    ...fonts,
    ...colors,
};

export default theme;

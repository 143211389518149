import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid';
import fullscreen from 'fullscreen';
import cx from 'classnames';
import DrawingToolOptions from './DrawingToolOptions';
import DrawingToolModal from './DrawingToolModal';
import Camera from './tools/Camera';
import ImageUpload from './tools/ImageUpload';
import {
    fillOptions, stampOptions, strokeOptions, strokeWidthOptions, textOptions,
} from './options';
import formatColor from './formatColor';
import Fill from './icons/Fill';
import Stroke from './icons/Stroke';
import arrowIcon from './icons/arrow.svg';
import bringToFrontIcon from './icons/bring-to-front.svg';
import cameraIcon from './icons/camera.svg';
import duplicateIcon from './icons/duplicate.svg';
import ellipseIcon from './icons/ellipse.svg';
import fullscreenIcon from './icons/fullscreen.svg';
import fullscreenExitIcon from './icons/fullscreen-exit.svg';
import imageUploadIcon from './icons/image-upload.svg';
import pencilIcon from './icons/pencil.svg';
import rectangleIcon from './icons/rectangle.svg';
import selectIcon from './icons/select.svg';
import sendToBackIcon from './icons/send-to-back.svg';
import deleteIcon from './icons/delete.svg';
import lineIcon from './icons/line.svg';
import redoIcon from './icons/redo.svg';
import stampIcon from './icons/stamp.svg';
import strokeWidthIcon from './icons/stroke-width.svg';
import textIcon from './icons/text.svg';
import undoIcon from './icons/undo.svg';
import { Translation } from '../Translation';
import './scss/DrawingTool.scss';

// size which is used as a basline to scale from
const baseCanvasSize = 800;

class DrawingTool extends React.Component {
    constructor(props) {
        super(props);
        const { saveToStore, predeterminedImageUrls } = props;
        this.id = uuid();

        this.state = {
            activeTool: 'select',
            loading: true,
            error: false,
            fabric: null,
            fauxFullscreenActive: false,
            fillColor: fillOptions.find(option => option.value === 'rgba(0, 0, 0, 0)'),
            isFullscreen: false,
            strokeColor: strokeOptions.find(option => option.value === 'rgb(0, 0, 0)'),
            strokeWidth: strokeWidthOptions[0],
            textSize: textOptions[2],
            selectedStamp: predeterminedImageUrls.length ? stampOptions(predeterminedImageUrls)[0] : null,
        };

        this.saveToStore = saveToStore.bind(this);
        this.undoStack = [];
        this.redoStack = [];

        this.bringToFront = this.bringToFront.bind(this);
        this.changeStrokeWidth = this.changeStrokeWidth.bind(this);
        this.changeFillColor = this.changeFillColor.bind(this);
        this.changeStamp = this.changeStamp.bind(this);
        this.changeStrokeColor = this.changeStrokeColor.bind(this);
        this.changeTextSize = this.changeTextSize.bind(this);
        this.clear = this.clear.bind(this);
        this.deactivateCameraModal = this.deactivateCameraModal.bind(this);
        this.deactivateImageUploadModal = this.deactivateImageUploadModal.bind(this);
        this.delete = this.delete.bind(this);
        this.exitFullscreen = this.exitFullscreen.bind(this);
        this.goFullscreen = this.goFullscreen.bind(this);
        this.redo = this.redo.bind(this);
        this.saveCanvasState = this.saveCanvasState.bind(this);
        this.sendToBack = this.sendToBack.bind(this);
        this.setCanvasSize = this.setCanvasSize.bind(this);
        this.undo = this.undo.bind(this);
        this.getAvailableWidth = this.getAvailableWidth.bind(this);
        this.getAvailableHeight = this.getAvailableHeight.bind(this);
    }

    async componentDidMount() {
        await this.initialiseCanvas();
        this.initialiseCanvasEvents();
        this.setCanvasSize();
        await this.setInitialState(this.props);

        this.canvas.on('object:modified', () => {
            this.saveCanvasState();
        });

        window.addEventListener('resize', this.setCanvasSize);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.initialState !== this.props.initialState) {
            this.setInitialState(nextProps);
        }
    }

    setActiveTool(activeTool) {
        const { tools } = this.state;

        if (this.activeToolInst) {
            this.activeToolInst.deactivate();
            this.activeToolInst = undefined;
        }
        // Reset colours when changing from text to a shape
        if (this.state.activeTool === 'text' && activeTool !== 'text') {
            const black = fillOptions.find(color => color.value === 'rgb(0, 0, 0)');
            const transparent = strokeOptions.find(color => color.value === 'rgba(0, 0, 0, 0)');
            this.setState({
                fillColor: transparent,
                strokeColor: black,
            });
        }

        this.setState({ activeTool }, () => {
            const Tool = tools[activeTool];
            if (Tool) {
                this.activeToolInst = new Tool(this.canvas, this.state, this.saveCanvasState);
            }
        });
    }

    getPixelPropertyValue(element, property) {
        return parseInt(
            window
                .getComputedStyle(element)
                .getPropertyValue(property)
                .replace('px', ''),
            10,
        );
    }

    getAvailableWidth() {
        return this.drawingToolContainer && this.drawingToolContainer.clientWidth - (
            this.getPixelPropertyValue(this.drawingToolContainer, 'padding-left') +
            this.getPixelPropertyValue(this.drawingToolContainer, 'padding-right')
        );
    }

    getAvailableHeight() {
        return this.element && this.element.clientHeight - (
            this.getPixelPropertyValue(this.element, 'padding-bottom') +
            this.getPixelPropertyValue(this.element, 'padding-top')
        );
    }

    setCanvasSize() {
        const aspectRatio = this.props.aspectRatio.height / this.props.aspectRatio.width;

        // sets a default availableWidth of baseCanvasSize in case for some reason
        // the ref for this.drawingToolContainer is not available.
        const availableWidth = this.getAvailableWidth() || baseCanvasSize;
        // sets a default availableHeight of baseCanvasSize * aspectRatio in case for some reason
        // the ref for this.element is not available.
        const availableHeight = this.getAvailableHeight() || baseCanvasSize * aspectRatio;

        let width;
        let height;

        if ((availableWidth * aspectRatio) <= availableHeight) {
            width = availableWidth;
            height = availableWidth * aspectRatio;
        } else {
            width = availableHeight / aspectRatio;
            height = availableHeight;
        }

        this.canvas.setWidth(width);
        this.canvas.setHeight(height);

        // We use baseCanvasSize as 100% scale and scale everything relative to that based on the available size
        const zoom = width / baseCanvasSize;
        this.canvas.setZoom(zoom);
    }

    getClassName(tool) {
        const className = `drawing-tool__button drawing-tool__button-${tool}`;
        const activeClassName = 'drawing-tool__button--active';
        return this.state.activeTool === tool ? `${className} ${activeClassName}` : className;
    }

    async setInitialState(props) {
        if (props.initialState) {
            this.canvas.loadFromJSON(props.initialState, () => {
                this.saveCanvasState('initialLoad');
                this.setState({ loading: false });
            });
        } else {
            await this.addBackgroundImage();
            this.saveCanvasState('initialLoad');
            this.setState({ loading: false });
        }
    }

    async initialiseCanvas() {
        const { fabric } = await import('fabric');

        const tools = await import('./tools');
        const Canvas = this.props.isStatic ? fabric.StaticCanvas : fabric.Canvas;

        this.canvas = new Canvas(this.id, {
            containerClass: 'drawing-tool__canvas',
            isDrawingMode: false,
            freeDrawingLineWidth: 5,
            preserveObjectStacking: true,
        });

        this.setState({
            fabric,
            tools: tools.default,
        });
    }

    initialiseCanvasEvents() {
        this.canvas.on('mouse:down', (options) => {
            if (options.target) {
                const fillColor = options.target.fill ?
                    fillOptions.find(option => formatColor(options.target.fill) === formatColor(option.value)) :
                    this.state.fillColor;

                const strokeColor = options.target.stroke ?
                    fillOptions.find(option => formatColor(options.target.stroke) === formatColor(option.value)) :
                    this.state.strokeColor;

                const strokeWidth = options.target.strokeWidth ?
                    strokeWidthOptions.find(option => options.target.strokeWidth === option.value) :
                    this.state.strokeWidth;

                this.setState({ fillColor, strokeColor, strokeWidth });
            }
        });

        this.element.tabIndex = 1000;
        this.element.addEventListener('keydown', (e) => {
            if ((e.keyCode || e.which) === 46 || (e.keyCode || e.which) === 8) {
                this.delete();
            }
        });

        this.fullscreen = fullscreen(this.element);

        this.fullscreen.on('attain', () => {
            this.setState({ isFullscreen: true });
        });

        this.fullscreen.on('release', () => {
            this.setState({ isFullscreen: false });
        });

        this.canvas.on('mouse:down', this.onCanvasClick);
        this.isMouseDown = false;
        this.canvas.on('mouse:up', this.onMouseUp);
        this.canvas.on('mouse:move', this.onMouseMove);
        this.canvas.on('text:changed', () => this.saveCanvasState());
        this.canvas.on('path:created', () => this.saveCanvasState());
    }

    async addBackgroundImage() {
        const url = this.props.backgroundImageUrl;
        const { fabric } = this.state;

        // eslint-disable-next-line consistent-return
        return new Promise((resolve) => {
            if (!url) return resolve();

            fabric.Image.fromURL(url, (img) => {
                img.scaleToWidth(baseCanvasSize);
                this.canvas.setBackgroundImage(img, this.canvas.renderAll.bind(this.canvas));
                return resolve();
            });
        });
    }

    bringToFront() {
        this.canvas.bringToFront(this.canvas.getActiveObject());
        this.saveCanvasState();
    }

    changeFillColor(option) {
        this.setState({
            fillColor: option,
        });

        const activeObjects = this.canvas.getActiveObjects();

        if (activeObjects.length > 0) {
            activeObjects.forEach((activeObject) => {
                if (activeObject.type === 'group') {
                    activeObject.forEachObject((o) => {
                        o.setColor(option.value);
                    });
                }
                activeObject.setColor(option.value);
            });
            this.canvas.renderAll();
            this.saveCanvasState();
        }
    }

    changeStrokeColor(option) {
        this.setState({
            strokeColor: option,
        });
        const activeObjects = this.canvas.getActiveObjects();

        if (activeObjects.length > 0) {
            activeObjects.forEach((activeObject) => {
                if (activeObject.type === 'group') {
                    activeObject.forEachObject((o) => {
                        o.setOptions({ stroke: option.value });
                    });
                }
                activeObject.setOptions({ stroke: option.value });
            });
            this.canvas.renderAll();
            this.saveCanvasState();
        }
    }

    changeStrokeWidthOnObject(activeObject, strokeWidth) {
        if (activeObject.type !== 'group') {
            activeObject.setOptions({ strokeWidth });
        } else {
            activeObject.forEachObject((o) => {
                this.changeStrokeWidthOnObject(o, strokeWidth);
            });
        }
    }

    changeStrokeWidth(option) {
        this.setState({
            strokeWidth: option,
        });
        const activeObjects = this.canvas.getActiveObjects();

        if (activeObjects.length > 0) {
            activeObjects.forEach((activeObject) => {
                this.changeStrokeWidthOnObject(activeObject, Number(option.value));
            });
            this.canvas.renderAll();
            this.saveCanvasState();
        }
    }

    changeTextSize(option) {
        const activeObjects = this.canvas.getActiveObjects();

        this.setState({
            textSize: option,
        });

        if (activeObjects.length > 0) {
            activeObjects.forEach((activeObject) => {
                activeObject.setOptions({ fontSize: Number(option.value) });
            });
            this.canvas.renderAll();
            this.saveCanvasState();
        } else {
            const blackFill = fillOptions.find(color => color.value === 'rgb(0, 0, 0)');
            const transparentStroke = strokeOptions.find(color => color.value === 'rgba(0, 0, 0, 0)');
            this.setState({
                fillColor: blackFill,
                strokeColor: transparentStroke,
            }, () => {
                this.setActiveTool('text');
            });
        }
    }

    changeStamp(option) {
        this.setState({
            selectedStamp: option,
        });
        this.setActiveTool('stamp');
    }

    async clear() {
        this.canvas.clear();
        await this.addBackgroundImage();
        this.saveCanvasState();
    }

    delete() {
        this.canvas.getActiveObjects().forEach((activeObject) => {
            this.canvas.remove(activeObject);
        });
        this.canvas.discardActiveObject();
        this.canvas.renderAll();
        this.saveCanvasState();
    }

    exitFullscreen() {
        if (this.isFullscreenSupported()) {
            this.fullscreen.release();
        } else {
            this.setState({
                fauxFullscreenActive: false,
            }, () => { this.setCanvasSize(); });
        }
    }

    goFullscreen() {
        if (this.isFullscreenSupported()) {
            this.fullscreen.request();
        } else {
            this.setState({
                fauxFullscreenActive: true,
            }, () => { this.setCanvasSize(); });
        }
    }

    replay(playStack, saveStack) {
        if (playStack.length > 0) {
            saveStack.push(this.canvasState);
            this.canvasState = playStack.pop();

            this.canvas.clear();
            this.canvas.loadFromJSON(this.canvasState, () => {
                this.canvas.renderAll();
            });
        }
    }

    saveCanvasState(event = 'canvasChanged') {
        this.redoStack = [];

        if (this.canvasState) {
            this.undoStack.push(this.canvasState);
        }
        const state = JSON.stringify(this.canvas.toJSON());
        this.canvasState = state;

        if (event !== 'initialLoad') {
            this.saveToStore(state);
        }
    }

    sendToBack() {
        this.canvas.sendToBack(this.canvas.getActiveObject());
        this.saveCanvasState();
    }

    undo() {
        this.replay(this.undoStack, this.redoStack);
    }

    redo() {
        this.replay(this.redoStack, this.undoStack);
    }

    deactivateCameraModal() {
        this.setState({ activeTool: null });
    }

    deactivateImageUploadModal() {
        this.setState({ activeTool: null });
    }

    isFullscreenSupported() {
        return this.element.requestFullscreen ||
            this.element.mozRequestFullScreen ||
            this.element.webkitRequestFullscreen ||
            this.element.msRequestFullscreen;
    }

    render() {
        const {
            hideControls, isStatic, t, predeterminedImageUrls,
        } = this.props;
        const { Button, LoadingPlaceholder } = this.props.frontEndComponents;
        const {
            activeTool,
            error,
            fauxFullscreenActive,
            isFullscreen,
            loading,
        } = this.state;

        if (error) {
            return (
                <p className="drawing-tool__error">
                    <Translation
                        translationKey="general.generic_error"
                        defaultValue="Sorry, an error occurred."
                    />
                </p>
            );
        }

        const fullscreenControls = (
            <div className="drawing-tool__button-group">
                {
                    isFullscreen || fauxFullscreenActive ? (
                        <button
                            className="drawing-tool__button drawing-tool__button-exit-fullscreen"
                            onClick={this.exitFullscreen}
                        >
                            <img src={fullscreenExitIcon} alt={t('general.exit_full_screen', 'exit fullscreen')} />
                        </button>
                    ) : (
                        <button
                            className="drawing-tool__button drawing-tool__button-fullscreen"
                            onClick={this.goFullscreen}
                        >
                            <img src={fullscreenIcon} alt={t('general.enter_full_screen', 'fullscreen')} />
                        </button>
                    )}
            </div>
        );

        const drawingToolClassName = cx({
            'drawing-tool': true,
            'drawing-tool--no-controls': hideControls,
            'drawing-tool--fullscreen': fauxFullscreenActive,
        });

        const drawingToolFullscreenBackgroundClassName = cx({
            'drawing-tool__fullscreen-background': true,
            'drawing-tool__fullscreen-background--active': fauxFullscreenActive,
        });

        return (
            <div>
                {loading && (
                    <div className="drawing-tool__loading-container">
                        <p>
                            <Translation
                                translationKey="general.drawing_loading"
                                defaultValue="Loading your drawing. Please wait..."
                            />
                        </p>
                        <LoadingPlaceholder />
                    </div>
                )}
                <div className={loading ? 'drawing-tool__wrapper--hidden' : 'drawing-tool__wrapper'}>
                    <div className={drawingToolFullscreenBackgroundClassName} />
                    <div className={drawingToolClassName} ref={(e) => { this.element = e; }}>
                        {
                            this.state.activeTool === 'camera' && (
                                <DrawingToolModal title="Camera" deactivateModal={this.deactivateCameraModal}>
                                    <Camera
                                        canvas={this.canvas}
                                        onCapture={this.deactivateCameraModal}
                                        saveCanvasState={this.saveCanvasState}
                                        frontEndComponents={this.props.frontEndComponents}
                                    />
                                </DrawingToolModal>
                            )
                        }
                        {
                            this.state.activeTool === 'image-upload' && (
                                <DrawingToolModal
                                    title="Image upload"
                                    deactivateModal={this.deactivateImageUploadModal}
                                >
                                    <ImageUpload
                                        canvas={this.canvas}
                                        onUpload={this.deactivateImageUploadModal}
                                        saveCanvasState={this.saveCanvasState}
                                        frontEndComponents={this.props.frontEndComponents}
                                    />
                                </DrawingToolModal>
                            )
                        }

                        {!hideControls && (
                            <div className="drawing-tool__toolbar">
                                <div className="drawing-tool__toolbar-tools">
                                    {
                                        !isStatic && (
                                            <div>
                                                <div className="drawing-tool__button-group">
                                                    <button
                                                        className={this.getClassName('select')}
                                                        onClick={() => { this.setActiveTool('select'); }}
                                                    >
                                                        <img
                                                            src={selectIcon}
                                                            alt={t('general.tool_select', 'select')}
                                                        />
                                                    </button>
                                                    <button
                                                        className={this.getClassName('pencil')}
                                                        onClick={() => { this.setActiveTool('pencil'); }}
                                                    >
                                                        <img
                                                            src={pencilIcon}
                                                            alt={t('general.tool_pencil', 'pencil')}
                                                        />
                                                    </button>
                                                    <button
                                                        className={this.getClassName('line')}
                                                        onClick={() => { this.setActiveTool('line'); }}
                                                    >
                                                        <img src={lineIcon} alt={t('general.tool_line', 'line')} />
                                                    </button>
                                                    <button
                                                        className={this.getClassName('arrow')}
                                                        onClick={() => { this.setActiveTool('arrow'); }}
                                                    >
                                                        <img src={arrowIcon} alt={t('general.tool_arrow', 'arrow')} />
                                                    </button>
                                                    <button
                                                        className={this.getClassName('rectangle')}
                                                        onClick={() => { this.setActiveTool('rectangle'); }}
                                                    >
                                                        <img
                                                            src={rectangleIcon}
                                                            alt={t('general.tool_rectangle', 'rectangle')}
                                                        />
                                                    </button>
                                                    <button
                                                        className={this.getClassName('ellipse')}
                                                        onClick={() => { this.setActiveTool('ellipse'); }}
                                                    >
                                                        <img
                                                            src={ellipseIcon}
                                                            alt={t('general.tool_ellipse', 'ellipse')}
                                                        />
                                                    </button>
                                                    <DrawingToolOptions
                                                        alt={t('general.tool_text', 'text')}
                                                        Icon={textIcon}
                                                        isActive={activeTool === 'text'}
                                                        options={textOptions}
                                                        update={this.changeTextSize}
                                                        value={this.state.textSize}
                                                    />
                                                    {!!predeterminedImageUrls.length &&
                                                        (
                                                            <DrawingToolOptions
                                                                alt={t('general.tool_stamp', 'stamp')}
                                                                Icon={stampIcon}
                                                                isActive={activeTool === 'stamp'}
                                                                options={stampOptions(predeterminedImageUrls)}
                                                                shouldIconUpdate={false}
                                                                update={this.changeStamp}
                                                                value={this.state.selectedStamp}
                                                            />
                                                        )}
                                                </div>

                                                <div className="drawing-tool__button-group">
                                                    {
                                                        'mediaDevices' in navigator && (
                                                            <button
                                                                className={this.getClassName('camera')}
                                                                onClick={() => { this.setActiveTool('camera'); }}
                                                            >
                                                                <img
                                                                    src={cameraIcon}
                                                                    alt={t('general.tool_camera', 'camera')}
                                                                />
                                                            </button>
                                                        )}
                                                    <button
                                                        className={this.getClassName('image-upload')}
                                                        onClick={() => { this.setActiveTool('image-upload'); }}
                                                    >
                                                        <img
                                                            src={imageUploadIcon}
                                                            alt={t('general.tool_image_upload', 'upload')}
                                                        />
                                                    </button>
                                                </div>

                                                <div className="drawing-tool__button-group">
                                                    <DrawingToolOptions
                                                        alt={t('general.tool_fill_color', 'fill color')}
                                                        Icon={Fill}
                                                        options={fillOptions}
                                                        update={(color) => {
                                                            this.changeFillColor(color);
                                                            this.setActiveTool(this.state.activeTool);
                                                        }}
                                                        value={this.state.fillColor}
                                                    />
                                                    <DrawingToolOptions
                                                        alt={t('general.tool_stroke_color', 'stroke color')}
                                                        Icon={Stroke}
                                                        options={strokeOptions}
                                                        update={(color) => {
                                                            this.changeStrokeColor(color);
                                                            this.setActiveTool(this.state.activeTool);
                                                        }}
                                                        value={this.state.strokeColor}
                                                    />
                                                    <DrawingToolOptions
                                                        Icon={strokeWidthIcon}
                                                        alt={t('general.tool_stroke_width', 'stroke width')}
                                                        shouldIconUpdate={false}
                                                        update={(width) => {
                                                            this.setActiveTool('stroke-width');
                                                            this.changeStrokeWidth(width);
                                                        }}
                                                        value={this.state.strokeWidth}
                                                        options={strokeWidthOptions}
                                                    />
                                                </div>

                                                {fullscreenControls}

                                                <div className="drawing-tool__button-group">
                                                    <button
                                                        className={this.getClassName('bring-to-front')}
                                                        onClick={() => {
                                                            this.setActiveTool('bring-to-front'); this.bringToFront();
                                                        }}
                                                    >
                                                        <img
                                                            src={bringToFrontIcon}
                                                            alt={t('general.tool_bring_to_front', 'bring to front')}
                                                        />
                                                    </button>
                                                    <button
                                                        className={this.getClassName('send-to-back')}
                                                        onClick={
                                                            () => {
                                                                this.setActiveTool('send-to-back');
                                                                this.sendToBack();
                                                            }
                                                        }
                                                    >
                                                        <img
                                                            src={sendToBackIcon}
                                                            alt={t('general.tool_send_to_back', 'send to back')}
                                                        />
                                                    </button>
                                                    <button
                                                        className={this.getClassName('undo')}
                                                        onClick={() => { this.setActiveTool('undo'); this.undo(); }}
                                                    >
                                                        <img src={undoIcon} alt={t('general.tool_undo', 'undo')} />
                                                    </button>
                                                    <button
                                                        className={this.getClassName('redo')}
                                                        onClick={() => { this.setActiveTool('redo'); this.redo(); }}
                                                    >
                                                        <img src={redoIcon} alt={t('general.tool_redo', 'redo')} />
                                                    </button>
                                                    <button
                                                        className={this.getClassName('duplicate')}
                                                        onClick={() => { this.setActiveTool('duplicate'); }}
                                                    >
                                                        <img
                                                            src={duplicateIcon}
                                                            alt={t('general.tool_duplicate', 'duplicate')}
                                                        />
                                                    </button>
                                                    <button
                                                        className={this.getClassName('delete')}
                                                        onClick={() => { this.setActiveTool('delete'); this.delete(); }}
                                                    >
                                                        <img
                                                            src={deleteIcon}
                                                            alt={t('general.tool_delete', 'delete')}
                                                        />
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                </div>
                                {
                                    !isStatic && (
                                        <div>
                                            <Button
                                                className="drawing-tool__action-button drawing-tool__clear"
                                                onClick={this.clear}
                                                primary
                                            >
                                                <Translation
                                                    translationKey="general.clear_all"
                                                    defaultValue="Clear All"
                                                />
                                            </Button>
                                        </div>
                                    )}
                                {isStatic && fullscreenControls}
                            </div>
                        )}

                        {
                            fauxFullscreenActive && (
                                <button
                                    className="drawing-tool__close-fullscreen"
                                    onClick={this.exitFullscreen}
                                    aria-label={t('general.aria_exit_full_screen', 'Close')}
                                >
                                    <span className="icon icon-cross" aria-hidden />
                                </button>
                            )
                        }

                        <div className="drawing-tool__container" ref={(e) => { this.drawingToolContainer = e; }}>
                            <canvas
                                id={this.id}
                                width={baseCanvasSize}
                                height={baseCanvasSize * (this.props.aspectRatio.height / this.props.aspectRatio.width)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

DrawingTool.defaultProps = {
    aspectRatio: {
        height: 3,
        width: 4,
    },
    hideControls: false,
    isStatic: false,
    initialState: null,
    saveToStore: () => {},
    backgroundImageUrl: '',
    predeterminedImageUrls: [],
};

DrawingTool.propTypes = {
    aspectRatio: PropTypes.shape({
        height: PropTypes.number,
        width: PropTypes.number,
    }),
    hideControls: PropTypes.bool,
    isStatic: PropTypes.bool,
    saveToStore: PropTypes.func,
    initialState: PropTypes.shape({}),
    frontEndComponents: PropTypes.shape({
        Button: PropTypes.func.isRequired,
        LoadingPlaceholder: PropTypes.func.isRequired,
    }).isRequired,
    backgroundImageUrl: PropTypes.string,
    predeterminedImageUrls: PropTypes.arrayOf(PropTypes.string),
    t: PropTypes.func.isRequired,
};

export default DrawingTool;

import React from 'react';
import { Route } from 'react-router-dom';
import adminUrl from './adminUrl';

const Admin = React.lazy(() => import(/* webpackChunkName: "Admin" */ './'));

const routes = [
    <Route
        path={adminUrl}
        component={Admin}
        key={Admin}
    />,
];


export default routes;

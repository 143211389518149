export default {
    questions: 'questions',
    common: 'common',
    dropDown: 'dropDown',
    essayResponse: 'essayResponse',
    showAnswer: 'showAnswer',
    hideAnswer: 'hideAnswer',
    editableTable: 'editableTable',
    fillableAnswer: 'fillableAnswer',
    fillableSubheading: 'fillableSubheading',
    dropDownOption: 'dropDownOption',
    dropDownCustomOption: 'dropDownCustomOption',
    dropDownNoneOption: 'dropDownNoneOption',
    selectOptionLabel: 'selectOption',
    defaultEssayResponseLabel: 'defaultEssayResponseLabel',
    inputLabel: 'inputLabel',
    showDropDownAnswer: 'showDropDownAnswer',
    hideDropDownAnswer: 'hideDropDownAnswer',
    drawing: 'drawing',
    buttonText: 'buttonText',
    generic: 'generic',
    messageText: 'messageText',
    correctAnswer: 'correctAnswer',
    noGuidanceText: 'noGuidanceText',
    characterLimitText: 'characterLimitText',
    pasteErrorText: 'pasteErrorText',
    clearAll: 'clearAll',
    graph: 'graph',
    xAxisTitle: 'xAxisTitle',
    yAxisTitle: 'yAxisTitle',
    invalidXML: 'invalidXML',
    pagination: 'pagination',
    previousPage: 'previousPage',
    nextPage: 'nextPage',
    of: 'of',
    legendTitle: 'legendTitle',
    errorBoundaryMessage: 'errorBoundaryMessage',
    graphStartText: 'graphStartText',
    xAxisIs: 'xAxisIs',
    yAxisIs: 'yAxisIs',
    yAxisRangesFrom: 'yAxisRangesFrom',
    to: 'to',
    and: 'and',
    labelledAs: 'labelledAs',
    fixedBar: 'fixedBar',
    endOfGraph: 'endOfGraph',
    correctAnswerViewPrefix: 'correctAnswerViewPrefix',
    deletePointText: 'deletePointText',
    groupBarChartAccessibility: 'groupBarChartAccessibility',
    have: 'have',
    barGroups: 'barGroups',
    withText: 'withText',
    barsEachGroup: 'barsEachGroup',
    from: 'from',
    groupBarInstruction: 'groupBarInstruction',
    rangesFrom: 'rangesFrom',
    barGroupLabel: 'barGroupLabel',
    currentPosition: 'currentPosition',
    lineChartAccessibility: 'lineChartAccessibility',
    barChartAccessibility: 'barChartAccessibility',
    fixedPointText: 'fixedPointText',
    pressSpace: 'pressSpace',
    backSpace: 'backSpace',
    pointPlotted: 'pointPlotted',
    pointRemoved: 'pointRemoved',
    lineChartInstruction: 'lineChartInstruction',
    correctAnswerByStudent: 'correctAnswerByStudent',
    wrongAnswer: 'wrongAnswer',
    answerByStudent: 'answerByStudent',
    barsText: 'barsText',
    barChartInstruction: 'barChartInstruction',
    loading: 'loading',
    editableQuestions: 'editableQuestions',
    correctAnswerRequiredError: 'correctAnswerRequiredError',
    enterPointsLabel: 'enterPointsLabel',
    optionsText: 'optionsText',
    pointsLabel: 'pointsLabel',
    shortTextResponseCorrectAnswer: 'shortTextResponseCorrectAnswer',
    caseSensitiveLabel: 'caseSensitiveLabel',
    manualScoredLabel: 'manualScoredLabel',
    setCorrectAnswerText: 'setCorrectAnswerText',
    dropDownChoiceText: 'dropDownChoiceText',
    predefinedTextLabel: 'predefinedTextLabel',
    FIBCorrectAnswerHelperText: 'FIBCorrectAnswerHelperText',
    questionLabelText: 'questionLabelText',
    addOptionIdString: 'addOptionIdString',
    pointsErrorText: 'pointsErrorText',
    setCorrectAnswerDisabledHelperText: 'setCorrectAnswerDisabledHelperText',
    stemsLabel: 'stemsLabel',
    pleaseEnterPointsErrorText: 'pleaseEnterPointsErrorText',
    correctAnswerInstructionTableInput: 'correctAnswerInstructionTableInput',
    tableText: 'tableText',
    textInputText: 'textInputText',
    dropdownText: 'dropdownText',
    choicesForDropdownText: 'choicesForDropdownText',
    pleaseEnterPointsError: 'pleaseEnterPointsError',
    dropdownChoicesLabel: 'dropdownChoicesLabel',
    correctAnswerInstructionTableDrop: 'correctAnswerInstructionTableDrop',
    correctAnswerInstructionCheckbox: 'correctAnswerInstructionCheckbox',
    correctAnswerInstructionMix: 'correctAnswerInstructionMix',
    minXValue: 'minXValue',
    maxXValue: 'maxXValue',
    xAxisStepSize: 'xAxisStepSize',
    xAxisValue: 'xAxisValue',
    minYValue: 'minYValue',
    maxYValue: 'maxYValue',
    yAxisStepSize: 'yAxisStepSize',
    yAxisValue: 'yAxisValue',
    labelText: 'labelText',
    responseErrorText: 'responseErrorText',
    barText: 'barText',
    barsLabel: 'barsLabel',
    defaultValueLabel: 'defaultValueLabel',
    interactiveLabel: 'interactiveLabel',
    snapToValuesLabel: 'snapToValuesLabel',
    yesText: 'yesText',
    noText: 'noText',
    barGraphAnswerHelperText: 'barGraphAnswerHelperText',
    yAxisMinErrorText: 'yAxisMinErrorText',
    yAxisMaxErrorText: 'yAxisMaxErrorText',
    groupLabel: 'groupLabel',
    chartLabel: 'chartLabel',
    yAxisValueTooltip: 'yAxisValueTooltip',
    pointLabel: 'pointLabel',
    chartDefaultValueTooltip: 'chartDefaultValueTooltip',
    chartSnapToValuesTooltip: 'chartSnapToValuesTooltip',
    chartText: 'chartText',
    axisTitleLabel: 'axisTitleLabel',
    x_AxisTitle: 'x_AxisTitle',
    y_AxisTitle: 'y_AxisTitle',
    characterLimitError: 'characterLimitError',
    yAxisSnapMinErrorText: 'yAxisSnapMinErrorText',
    yAxisSnapMaxErrorText: 'yAxisSnapMaxErrorText',
    questionErrorText: 'questionErrorText',
    dropdownRequiredErrorText: 'dropdownRequiredErrorText',
    requiredText: 'requiredText',
    lineGraphAnswerHelperText: 'lineGraphAnswerHelperText',
    validValueErrorText: 'validValueErrorText',
    snapToValueEmptyErrorText: 'snapToValueEmptyErrorText',
    legendLabel: 'legendLabel',
    imageText: 'imageText',
    singleSelectCorrectAnswerHelperText: 'singleSelectCorrectAnswerHelperText',
    yAxisLabel: 'yAxisLabel',
    xAxisLabel: 'xAxisLabel',
    yIdentifierText: 'yIdentifierText',
    tableTitleText: 'tableTitleText',
    pressDelete: 'pressDelete',
    droppedResponseArea: 'droppedResponseArea',
    responseArea: 'responseArea',
    selectOptionText: 'selectOptionLabel',
    fillableAnswerLabel: 'fillableAnswerLabel',
    fillableSubheadingLabel: 'fillableSubheadingLabel',
    droppedDraggableMessage: 'droppedDraggableMessage',
    removedDraggableMessage: 'removedDraggableMessage',
    butDidNotDropMessage: 'butDidNotDropMessage',
    pickedDraggableMessage: 'pickedDraggableMessage',
    pickedDraggableMessageWithinCategory: 'pickedDraggableMessageWithinCategory',
    imageForQuestionText: 'imageForQuestionText',
    ckEditorTextArea: 'ckEditorTextArea',
    removedResponseArea: 'removedResponseArea',
    rubricGuidanceText: 'rubricGuidanceText',
    noGuidanceOrRubric: 'noGuidanceOrRubric',
    subheadingText: 'subheadingText',
    answerText: 'answerText',
    chooseFromFollowingText: 'chooseFromFollowingText',
    errorText: 'errorText',
    errorTextForCondition: 'errorTextForCondition',
};

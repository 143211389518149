import { useEffect } from 'react';
import PropTypes from 'prop-types';
import userManager from '../userManager';

const RedirectToSSOLogin = ({ nextUrl }) => {
    useEffect(() => {
        const callback = async () => {
            window.localStorage.setItem('nextUrl', nextUrl || window.location.href);
            await userManager.signinRedirect();
        };
        callback();
    }, []);

    return null;
};

RedirectToSSOLogin.defaultProps = {
    nextUrl: null,
};

RedirectToSSOLogin.propTypes = {
    nextUrl: PropTypes.string,
};

export default RedirectToSSOLogin;

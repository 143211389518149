/* eslint-disable import/prefer-default-export  */
import React from 'react';
import PropTypes from 'prop-types';
import { Translation as I18NextTranslation } from 'react-i18next';
import { name } from '../package.json';

export const Translation = ({
    translationKey, defaultValue, options, ...props
}) => (
    <I18NextTranslation {...props}>{t => t(`${name}.${translationKey}`, defaultValue, options)}</I18NextTranslation>
);

Translation.defaultProps = {
    options: {},
};

Translation.propTypes = {
    translationKey: PropTypes.string.isRequired,
    defaultValue: PropTypes.string.isRequired,
    options: PropTypes.shape(),
};

const proxyLocalStorage = () => {
    const { setItem, getItem } = localStorage;
    const getIntKey = key => ([...key].reduce((acc, k) => acc + k.charCodeAt(0), 0)) % 100;

    localStorage.setItem = function localStorageSetItem(key, value) {
        const intKey = getIntKey(key);
        const valueArr = [...String(value)];
        const encryptedArr = valueArr.map(v => v.charCodeAt(0) ^ intKey);

        return setItem.call(this, key, JSON.stringify(encryptedArr));
    };

    localStorage.getItem = function localStorageGetItem(key) {
        const value = getItem.call(this, key);
        if (!value) return value;

        try {
            const decryptedArr = JSON.parse(value);
            const intKey = getIntKey(key);
            const decryptedData = decryptedArr.map(d => String.fromCharCode(d ^ intKey)).join('');

            return decryptedData;
        } catch {
            return null;
        }
    };
};

export default proxyLocalStorage;

import {
    isBoolean, sortBy, isNaN,
} from 'lodash';
import {
    DIV, CLASS, QUESTION, PROMPT, QUESTION_XML, IDENTIFIER, MAP_ENTRY, TEXT_ENTRY_INTERACTION,
    GRID_LABEL, YIDENTIFIER, SNAP, fixedText, BAR, XAXIS, XIDENTIFIER, LABEL, YAXIS, BASE_TYPE, P_TAG,
    STRING_MATCH, CASE_SENSITIVE, IMG, RESPONSE_DECLARATION, MAPPING,
} from '../../stringConstants/index';
import {
    NodeTypeValues,
    tags,
    booleanAttributes,
    numberAttributes,
} from '../xmlConversion.enum';
import { convertDataToXML, convertXMLToData } from '../XMLAndJSConversion';

const encodeNodeValue = (data) => {
    const xmlDoc = document.implementation.createDocument(null, 'root');
    const childElement = xmlDoc.createElement('child');
    childElement.setAttribute('attribute', 'value');
    childElement.textContent = data;
    xmlDoc.documentElement.appendChild(childElement);
    const serializer = new XMLSerializer();
    const xmlString = serializer.serializeToString(xmlDoc);
    const startTag = '<child attribute="value">';
    const endTag = '</child>';
    const startIndex = xmlString.indexOf(startTag) + startTag.length;
    const endIndex = xmlString.indexOf(endTag);
    const childContent = xmlString.substring(startIndex, endIndex);
    return childContent;
};

const isPossiblyMalicious = (name, value) => {
    if (['src', 'href', 'xlink:href'].includes(name)) {
        if (value.includes('javascript:') || value.includes('data:text/html')) return true;
    }
    if (name.startsWith('on')) return true;
}

const getOutputHtml = (element) => {
    let content = '';

    for (const node of element.childNodes) {
        if(node.nodeName.toLowerCase() === 'script') {
            node.remove();
            continue;
        }
        if (node.nodeType === Node.TEXT_NODE) {
            content += encodeNodeValue(node.textContent);
        } else if (node.nodeType === Node.ELEMENT_NODE) {
            let openingTag = `<${node.tagName.toLowerCase()}`;
            for (const attr of node.attributes) {
                if (isPossiblyMalicious(attr.name, attr.value)) {
                    node.removeAttribute(attr.name);
                    continue;
                }
                openingTag += ` ${attr.name}="${attr.value}"`;
            }
            openingTag += '>';
            const innerContent = getOutputHtml(node);
            const closingTag = `</${node.tagName.toLowerCase()}>`;
            content += `${openingTag}${innerContent}${closingTag}`;
        }
    }

    return content;
};

const encodeXml = (data) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(data, 'text/html');
    const holder = document.createElement('div');

    const encodeTextNodes = (node) => {
        if (node.nodeType === Node.TEXT_NODE) {
            const textContentForEncode = node.nodeValue;
            const encodedText = document.createElement('div');
            encodedText.textContent = textContentForEncode;
            node.replaceWith(...encodedText.childNodes);
        } else {
            node.childNodes.forEach(child => encodeTextNodes(child));
        }
    };
    encodeTextNodes(doc.body);
    doc.body.childNodes.forEach(child => holder.appendChild(child.cloneNode(true)));
    const outputHtml = getOutputHtml(holder);
    return outputHtml;
};

const encodeHtml = (input) => {
    const doc = new DOMParser().parseFromString(input, 'text/html');
    return doc.documentElement.textContent;
};

const getFilteredElements = (elements, name) => elements?.filter(ele => ele.name !== name);

const getElementsByName = (elements, name) => elements?.filter(ele => ele.name === name);

// function to filter out elements that have question instruction in it.
const filterInstructionElements = elements => elements?.filter(ele => !(ele.name === PROMPT ||
    (ele.name === DIV && ele.attributes[CLASS] === QUESTION)));

const appendClosingTag = (oldHtml, isSelfClosing) => {
    let html = oldHtml;
    if (isSelfClosing) {
        html += '/>';
    } else {
        html += '>';
    }
    return html;
};

const sliceAndUpdatePropertyWithValue = (elements, index, property, value) => {
    if (index > -1) {
        return [
            ...elements.slice(0, index),
            {
                ...elements[index],
                [property]: value,
            },
            ...elements.slice(index + 1),
        ];
    }
    return elements;
};

const appendAttributesToTag = (oldHtml, tagName, attributes, isSelfClosing = false) => {
    let html = oldHtml;
    html += `<${tagName}`;
    const array = Object.entries(attributes);
    if (attributes && array?.length > 0) {
        array.forEach(([key, value], index) => {
            if (index === 0) {
                html += ' ';
            }
            if (value !== undefined && value !== null && value !== '') {
                html += `${key}="${value}"`;
                if (index !== array?.length - 1) {
                    html += ' ';
                }
            }
        });
    }

    html = appendClosingTag(html, isSelfClosing);

    return html;
};

// Function to get html with Image & Text appended based on sequence
const getImageTagWithText = (img, text, imageFirst = true) => {
    let html = '';
    if (imageFirst) {
        html = appendAttributesToTag(html, IMG, img, true);
        if (text) html += `<p> ${text} </p>`;
    } else {
        if (text) html = `<p> ${text} </p>`;
        html += appendAttributesToTag(html, IMG, img, true);
    }

    return html;
};

// Function that gives formatted attributes as a object with changes in type of attribute
const getFormattedAttributes = (attributes) => {
    const attributesList = {};
    if (attributes.length > 0) {
        for (let j = 0; j < attributes.length; j += 1) {
            const attribute = attributes.item(j);
            const value = attribute.nodeValue;
            if (booleanAttributes?.includes(attribute.nodeName)) {
                const booleanValue = value === 'true' || value === 'TRUE';
                attributesList[attribute.nodeName] = booleanValue;
            } else if (numberAttributes?.includes(attribute.nodeName)) {
                attributesList[attribute.nodeName] = value ? Number(value) : value;
            } else {
                attributesList[attribute.nodeName] = value;
            }
        }
    }
    return attributesList;
};

// Function to get object updated only if key has valid value
const getValidObject = (obj) => {
    const objectList = {};
    const list = Object.entries(obj);
    if (list?.length > 0) {
        for (const [key, value] of list) {
            if (![null, undefined, ''].includes(value)) {
                objectList[key] = value;
            }
        }
    }
    return objectList;
};

// Function to update choices of dropdown elements
const updateChoices = (elements, optionsList) => optionsList?.map((option) => {
    const { optionIdentifier, text: choiceText, img } = option;
    const currentChoice = elements?.find(({
        attributes: choiceAttributes,
    }) => choiceAttributes?.[IDENTIFIER] === optionIdentifier);

    let choiceElements = [];
    if (img) {
        choiceElements = [{
            cdata: getImageTagWithText(img, choiceText),
            type: NodeTypeValues.cData,
        }];
    } else {
        choiceElements = [{
            text: choiceText,
            type: NodeTypeValues.text,
        }];
    }
    let newAttributes = {};
    if (currentChoice) {
        newAttributes = currentChoice?.attributes;
    } else {
        newAttributes = {
            identifier: optionIdentifier,
        };
    }
    return {
        ...currentChoice,
        attributes: newAttributes,
        elements: choiceElements,
        type: NodeTypeValues.element,
        name: tags.simpleChoice,
    };
});

const getValuesSeparatedBySeparator = (array, separator = ' ') => {
    let text = '';
    array.forEach((ele, index) => {
        if (ele) {
            text += ele;
            if (index < array?.length - 1) {
                text += separator;
            }
        }
    });
    return text;
};

// function to give single or multiple correct answer for single responseDeclaration type questions
const getCorrectResponses = (responseDeclaration) => {
    let correctResponse = [];
    let responseDeclarationIdentifier = '';
    if (responseDeclaration.length > 0) {
        responseDeclarationIdentifier = responseDeclaration[0]?.getAttribute(tags.identifier);
        correctResponse = responseDeclaration.reduce((values, child) => {
            const valueNodes = [...child.getElementsByTagName(tags.value)];
            return [...values, ...valueNodes.map(nodeChild => nodeChild.textContent)];
        }, []);
    }
    return { responseDeclarationIdentifier, correctResponse };
};

// Function to get optionProps for choices inside xmls with text & image separated
const getOptionProps = (choice, index) => {
    const optionProps = {};
    const children = [...choice.children];

    children.forEach((child) => {
        if (child.tagName === 'img') {
            optionProps.img = {
                src: child.getAttribute('src'),
                alt: child.getAttribute('alt'),
            };
        }
    });
    optionProps.text = choice.textContent.trim();
    optionProps.optionNumber = index + 1;
    optionProps.optionIdentifier = choice.getAttribute(tags.identifier) || '';
    return optionProps;
};

const getQuestionDetails = (xml) => {
    const responseDeclaration = [...xml.getElementsByTagName(tags.responseDeclaration)];
    const mappedCorrectResponses = [...xml.getElementsByTagName(tags.mapEntry)];
    const responseProcessing = [...xml.getElementsByTagName(tags.responseProcessing)];
    const assessmentItem = xml.getElementsByTagName(tags.assessmentItem);
    const identifier = assessmentItem[0].getAttribute(tags.identifier);
    const itemBody = [...xml.getElementsByTagName(tags.itemBody)];
    const mappedCorrectResponse = [];
    const correctResponse = [];
    let caseInsensitiveResponse = [];
    let stringMatchNodes = [];
    if (responseProcessing.length > 0) {
        stringMatchNodes = [...responseProcessing[0].getElementsByTagName(tags.stringMatch)]
            .filter(item => item.getAttribute(tags.caseSensitive) === tags.false);
        caseInsensitiveResponse = stringMatchNodes.map(({ children }) => {
            const variableNode = [...children].filter(node => node.tagName === tags.variable);
            return variableNode[0]?.getAttribute(tags.identifier);
        });
    }
    if (responseDeclaration.length > 0) {
        responseDeclaration.forEach((child) => {
            const xmlIdentifier = child.getAttribute(tags.identifier);
            const valueNodes = [...child.getElementsByTagName(tags.valueTag)];
            correctResponse.push(...valueNodes.map(nodeChild => ({
                responseIdentifier: xmlIdentifier,
                mappedKey: nodeChild.textContent,
                isCaseSensitive: !caseInsensitiveResponse.includes(xmlIdentifier),
            })));
            const mappedCorrectResponsesTags = [...child.getElementsByTagName(tags.mapEntry)];
            if (mappedCorrectResponsesTags.length > 0) {
                mappedCorrectResponse.push(...mappedCorrectResponsesTags.map(mapChild => ({
                    responseIdentifier: xmlIdentifier,
                    mappedKey: mapChild.getAttribute(tags.mapKey),
                    mappedValue: +mapChild.getAttribute(tags.mappedValue),
                })));
            }
        });
    }

    return {
        responseDeclaration,
        mappedCorrectResponses,
        responseProcessing,
        identifier,
        itemBody,
        correctResponse,
        mappedCorrectResponse,
    };
};

// function that combines all question item instructions present in XML
const getQuestionItemInstructionHTML = (xml) => {
    const question = [...xml.getElementsByTagName(DIV)].filter(child => child.getAttribute(CLASS) === QUESTION);
    const prompt = [...xml.getElementsByTagName(PROMPT)];
    const serializer = new XMLSerializer();
    let questionHTML = '';
    const regexPattern = ` ${QUESTION_XML}`;
    if (question.length > 0) {
        const questionElement = question[0];
        const childNodeLength = questionElement?.children?.length;

        const questionText = questionElement?.innerHTML;

        if (childNodeLength === 0 && questionElement?.childNodes?.length > 0) {
            questionHTML += `<${P_TAG}>${questionText}</${P_TAG}>`;
        } else {
            questionHTML += questionText;
        }
    }
    if (prompt.length > 0) {
        let promptHTML = '';
        const promptElement = prompt[0];
        const childNodeLength = promptElement?.children?.length;

        promptHTML = serializer.serializeToString(prompt[0]);

        if (childNodeLength === 0 && promptElement?.childNodes?.length > 0) {
            questionHTML += `<${P_TAG}>${promptHTML}</${P_TAG}>`;
        } else {
            questionHTML += promptHTML;
        }
    }
    questionHTML = questionHTML.split(regexPattern).join('');
    questionHTML = questionHTML.replace(/<prompt>|<\/prompt>/g, '');
    return questionHTML.trim();
};

const updateCorrectResponses = (elements, correctResponse) => elements?.map((ele) => {
    const { name: childName } = ele;
    if (childName === tags.correctResponse) {
        const correctResponseElements = correctResponse?.map(response => ({
            elements: [{
                text: response,
                type: NodeTypeValues.text,
            }],
            name: tags.value,
            type: NodeTypeValues.element,
        }));
        return {
            ...ele,
            elements: correctResponseElements,
        };
    }
    return ele;
});

const getNodeListRecursively = (node) => {
    if (node.nodeType === Node.ELEMENT_NODE) {
        if (node.childNodes.length > 0) {
            return [
                node,
                ...Array.from(node.childNodes).reduce((nodeList, childNode) => [
                    ...nodeList,
                    ...getNodeListRecursively(childNode),
                ], []),
            ];
        }
        return [node];
    }
    if (node.nodeType === Node.TEXT_NODE && !node.textContent.match(/.*(\n).*/)) {
        return [node];
    }
    return [];
};

const findNodeByTagName = (nodeList, name) => nodeList.find(node => node.tagName === name);

const isQuestionItemInstructionEmpty = instruction => (
    instruction === null || instruction === undefined || instruction?.trim() === ''
);

const getDataFilledInNode = (nodeName, data) => {
    const encodedData = encodeXml(data);
    return `<${nodeName}>${encodedData}</${nodeName}>`;
};

const getSelectLabelTag = label => `<p class="selectLabel">${label}</p>`;

const getPromptElement = (questionItemInstruction) => {
    const encodedQuestionItemInstruction = encodeXml(questionItemInstruction);
    return {
        elements: [{
            cdata: encodedQuestionItemInstruction,
            type: NodeTypeValues.cData,
        }],
        name: tags.prompt,
        type: NodeTypeValues.element,
    };
};

const getDivQuestionElement = (questionItemInstruction) => {
    const encodedQuestionItemInstruction = encodeXml(questionItemInstruction);
    return {
        attributes: {
            class: QUESTION,
        },
        elements: [{
            cdata: encodedQuestionItemInstruction,
            type: NodeTypeValues.cData,
        }],
        name: DIV,
        type: NodeTypeValues.element,
    };
};

const getCorrectResponseElement = correctResponses => ({
    name: tags.correctResponse,
    type: NodeTypeValues.element,
    elements: correctResponses?.map(correctResponse => ({
        name: tags.value,
        type: NodeTypeValues.element,
        elements: [{
            text: correctResponse,
            type: NodeTypeValues.text,
        }],
    })),
});

const getMappedCorrectResponseElement = mappedCorrectResponse => ({
    name: tags.mapping,
    type: NodeTypeValues.element,
    elements: mappedCorrectResponse?.map((response) => {
        const { mappedKey, mappedValue, caseSensitive } = response;
        return {
            name: tags.mapEntry,
            type: NodeTypeValues.element,
            attributes: {
                mapKey: mappedKey,
                mappedValue,
                ...(isBoolean(caseSensitive) && { caseSensitive }),
            },
        };
    }),
});

// Function that recursive calls a function passed as argument to get elements updated
const getRecursiveElements = (oldElements, functionToBeCalled, data) => {
    let elements = oldElements;
    if (elements && elements?.length > 0) {
        const newElements = [];
        for (const ele of elements) {
            newElements.push(functionToBeCalled([ele], data));
        }
        elements = newElements;
    }
    return elements;
};

const getCorrectResponseElementForGraph = (correctResponse) => {
    const {
        value,
    } = correctResponse;
    const text = value?.join(' ');
    return {
        elements: [{
            elements: [{
                text,
                type: NodeTypeValues.text,
            }],
            name: tags.value,
            type: NodeTypeValues.element,
        }],
        name: tags.correctResponse,
        type: NodeTypeValues.element,
    };
};

const getMappedCorrectResponseElementForGraph = (mappedResponse) => {
    const {
        value, mappedValue, caseSensitive,
    } = mappedResponse;

    const mapKey = value?.join(' ');
    return {
        elements: [{
            attributes: {
                mapKey,
                mappedValue,
                ...(isBoolean(caseSensitive) && { caseSensitive }),
            },
            name: tags.mapEntry,
            type: NodeTypeValues.element,
        }],
        name: tags.mapping,
        type: NodeTypeValues.element,
    };
};

const getTextEntryElementsForGraph = (elements, axesData) => axesData?.reduce((result, child) => {
    result.push({
        attributes: {
            ...child,
        },
        name: tags.textEntryInteraction,
        type: NodeTypeValues.element,
    });
    return result;
}, elements);

const getAxisElementsBasedOnType = (axis) => {
    const { attributes, axisName, gridLabels } = axis;
    const elements = gridLabels?.map((grid) => {
        const { xIdentifier, yIdentifier, value } = grid;
        return {
            attributes: {
                ...((xIdentifier || xIdentifier === 0) && { xIdentifier }),
                ...((yIdentifier || yIdentifier === 0) && { yIdentifier }),
            },
            elements: [{
                type: NodeTypeValues.text,
                text: encodeXml(value),
            }],
            name: tags.gridLabel,
            type: NodeTypeValues.element,
        };
    });
    return {
        attributes,
        ...(elements?.length > 0 && { elements }),
        name: axisName,
        type: NodeTypeValues.element,
    };
};

const getChartDataElements = (oldElements, data) => {
    const elements = [...oldElements];
    const axes = [];
    const {
        snapping, xAxisTemplateData, yAxisTemplateData,
        xTicksLabel, yTicksLabel, barGraph, groupedBarGraph,
    } = data;

    const isBar = barGraph || groupedBarGraph;

    const encodedXAxisTemplateData = {
        ...xAxisTemplateData,
        labelText: encodeXml(xAxisTemplateData.labelText),
    };

    const encodedYAxisTemplateData = {
        ...yAxisTemplateData,
        labelText: encodeXml(yAxisTemplateData.labelText),
    };

    const xAxis = {
        attributes: encodedXAxisTemplateData,
        gridLabels: isBar ? [] : xTicksLabel,
        axisName: XAXIS,
    };
    const yAxis = {
        attributes: encodedYAxisTemplateData,
        gridLabels: yTicksLabel,
        axisName: YAXIS,
    };

    if (xAxisTemplateData) {
        axes.push(getAxisElementsBasedOnType(xAxis));
    }
    if (yAxisTemplateData) {
        axes.push(getAxisElementsBasedOnType(yAxis));
    }

    elements.push({
        attributes: { snapping },
        elements: axes,
        name: tags.chartData,
        type: NodeTypeValues.element,
    });

    return elements;
};

const getCorrectResponseDataForAxis = (
    identifier, response, oldCorrectResponses, oldMappedCorrectResponses, oldCaseSensitive,
) => {
    const correctResponses = [...oldCorrectResponses];
    const mappedCorrectResponses = [...oldMappedCorrectResponses];
    let newCaseSensitive = oldCaseSensitive;

    const valueNodes = [...response.getElementsByTagName(tags.value)];
    const mapping = [...response.getElementsByTagName(tags.mapping)][0];

    const correctText = valueNodes?.[0]?.textContent?.trim();

    correctResponses.push({
        responseIdentifier: identifier,
        value: [correctText],
        isAxis: true,
    });

    if (mapping) {
        const mapEntry = [...mapping?.getElementsByTagName(MAP_ENTRY)]?.[0];
        if (mapEntry) {
            const attributes = getFormattedAttributes(mapEntry?.attributes);

            const { mapKey, mappedValue, caseSensitive } = attributes || {};

            const caseSensitiveValue = isBoolean(caseSensitive) ? caseSensitive : true;

            newCaseSensitive = newCaseSensitive && caseSensitiveValue;

            mappedCorrectResponses.push({
                responseIdentifier: identifier,
                value: [correctText],
                mappedValue,
                mappedKey: mapKey,
                isAxis: true,
                caseSensitive: caseSensitiveValue,
            });
        }
    }

    return {
        correctResponses,
        mappedCorrectResponses,
        titleCaseSensitive: newCaseSensitive,
    };
};

const isDecimal = str => str?.indexOf('.') > -1;

const getXAxisAndyAxisPoints = (text) => {
    let xAxisPoint = 0;
    let yAxisPoint = 0;
    const regexPattern = /\s+/g;
    if (text) {
        const splitCorrectResponse = text.trim()?.split(regexPattern);
        if (splitCorrectResponse?.length >= 2) {
            const x = splitCorrectResponse[0];
            const y = splitCorrectResponse[1];

            const xHasDecimal = isDecimal(x);
            const yHasDecimal = isDecimal(y);

            xAxisPoint = Number(xHasDecimal ? parseFloat(x) : parseInt(x, 10));
            yAxisPoint = Number(yHasDecimal ? parseFloat(y) : parseInt(y, 10));
        }
    }
    return { xAxisPoint, yAxisPoint };
};

const getCorrectResponseDataForPoints = (identifier, response, oldCorrectResponses, oldMappedCorrectResponses) => {
    const correctResponses = [...oldCorrectResponses];
    const mappedCorrectResponses = [...oldMappedCorrectResponses];

    const valueNodes = [...response.getElementsByTagName(tags.value)];
    const mapping = [...response.getElementsByTagName(tags.mapping)]?.[0];

    const correctText = valueNodes?.[0]?.textContent || '';

    const { xAxisPoint, yAxisPoint } = getXAxisAndyAxisPoints(correctText);

    correctResponses.push({
        responseIdentifier: identifier,
        value: [xAxisPoint, yAxisPoint],
        isAxis: false,
    });

    if (mapping) {
        const mapEntry = [...mapping?.getElementsByTagName(MAP_ENTRY)]?.[0];
        if (mapEntry) {
            const attributes = getFormattedAttributes(mapEntry?.attributes);

            const { mapKey, mappedValue } = attributes || {};

            const {
                xAxisPoint: mappedXAxisPoint,
                yAxisPoint: mappedYAxisPoint,
            } = getXAxisAndyAxisPoints(mapKey);
            mappedCorrectResponses.push({
                responseIdentifier: identifier,
                value: [mappedXAxisPoint, mappedYAxisPoint],
                mappedValue,
                mappedKey: mapKey,
                isAxis: false,
            });
        }
    }

    return { correctResponses, mappedCorrectResponses };
};

const getSensitiveFromStringMatch = (xml) => {
    const stringMatchArray = [...xml.getElementsByTagName(tags.stringMatch)];
    return !stringMatchArray.some((ele) => {
        const attributes = getFormattedAttributes(ele.attributes);
        return attributes.caseSensitive === false;
    });
};

const getGraphCorrectAndMappedResponsesWithIdentifier = (responseDeclaration) => {
    let correctResponses = [];
    let mappedCorrectResponses = [];
    let titleCaseSensitive = true;
    if (responseDeclaration?.length > 0) {
        for (const response of responseDeclaration) {
            const { identifier, baseType } = getFormattedAttributes(response?.attributes);
            if (baseType === 'string') {
                ({
                    correctResponses,
                    mappedCorrectResponses,
                    titleCaseSensitive,
                } = getCorrectResponseDataForAxis(
                    identifier, response, correctResponses, mappedCorrectResponses, titleCaseSensitive,
                ));
            } else {
                ({
                    correctResponses,
                    mappedCorrectResponses,
                } = getCorrectResponseDataForPoints(identifier, response, correctResponses, mappedCorrectResponses));
            }
        }
    }
    return { correctResponses, mappedCorrectResponses, titleCaseSensitive };
};

const getLabels = (itemBody) => {
    const axisResponseIdentifiers = [];
    const textEntryInteraction = [...itemBody.getElementsByTagName(TEXT_ENTRY_INTERACTION)];
    let result = {};
    textEntryInteraction.forEach((cur) => {
        const obj = getFormattedAttributes(cur?.attributes);
        axisResponseIdentifiers.push(obj?.responseIdentifier);
        result = {
            ...result,
            [obj.identifier]: obj,
        };
    });
    return {
        labels: result,
        axisResponseIdentifiers,
    };
};

const getAxesData = (itemBody) => {
    const axesData = [];
    const textEntryInteraction = [...itemBody.getElementsByTagName(TEXT_ENTRY_INTERACTION)];
    if (textEntryInteraction?.length > 0) {
        textEntryInteraction.forEach((element) => {
            const attributes = getFormattedAttributes(element?.attributes);
            axesData.push(attributes);
        });
    }
    return axesData;
};

const getDataBasedOnAxis = (element, axisName) => {
    const axis = [...element.getElementsByTagName(axisName)]?.[0];
    const attributes = getFormattedAttributes(axis?.attributes);

    let gridLabels = [];

    const gridLabelsTag = [...axis.getElementsByTagName(GRID_LABEL)];
    if (gridLabelsTag?.length > 0) {
        gridLabels = gridLabelsTag?.map((grid) => {
            const label = grid?.textContent || '';
            return {
                attributes: getFormattedAttributes(grid.attributes),
                label,
            };
        });
        return {
            attributes,
            axisName,
            gridLabels,
        };
    }
    return { attributes, axisName };
};

const getSnappingPlotsForGraph = (ele) => {
    const snappingPoints = [...ele.getElementsByTagName(SNAP)];
    const snaps = snappingPoints?.map((snap) => {
        const y = snap.getAttribute(YIDENTIFIER);
        const yHasDecimal = isDecimal(y);
        return Number(yHasDecimal ? parseFloat(y).toFixed(2) : parseInt(y, 10));
    });
    return [...new Set(snaps)];
};

const getBarsData = (bars, snapping) => bars?.map((bar) => {
    const barAttributes = getFormattedAttributes(bar?.attributes);
    const { position } = barAttributes;
    let snapPoints = [];

    if (position !== fixedText && snapping) {
        snapPoints = getSnappingPlotsForGraph(bar);
    }

    return {
        ...barAttributes,
        snaps: snapPoints,
    };
});

const getBarElements = bars => bars?.map((bar) => {
    const { snaps, label, ...other } = bar;
    const snapPoints = snaps?.map(snap => ({
        attributes: { [YIDENTIFIER]: snap },
        name: tags.snap,
        type: NodeTypeValues.element,
    }));
    return {
        attributes: { ...other, label: encodeXml(label) },
        name: tags.bar,
        ...(snapPoints?.length > 0 && { elements: snapPoints }),
        type: NodeTypeValues.element,
    };
});

const getBarXAxisTemplateData = (itemBody, tagName = BAR) => {
    const axisTemplate = [...itemBody.getElementsByTagName(XAXIS)];
    let result = {};
    let min = 0;
    let max = 0;
    const bars = [...itemBody.getElementsByTagName(tagName)];
    const barsXIdentifiers = bars.map(bar => Number(bar.attributes.xIdentifier?.value)).filter(num => !isNaN(num));
    min = Math.min(...barsXIdentifiers);
    max = Math.max(...barsXIdentifiers);

    axisTemplate[0].attributes.forEach((cur) => {
        result = {
            ...result,
            [cur.nodeName]: cur.value,
            min,
            max,
            majorTickInterval: barsXIdentifiers.length >= 2 ? Math.abs(barsXIdentifiers[0] - barsXIdentifiers[1]) : 1,
        };
    });
    return {
        ...result,
    };
};

const getAxisTemplateData = (itemBody, axis) => {
    const axisTemplate = [...itemBody.getElementsByTagName(axis)];
    let result = {};
    if (axisTemplate?.length > 0) {
        result = {
            ...getFormattedAttributes(axisTemplate[0]?.attributes),
            prefix: '',
            suffix: '',
        };
    }
    return result;
};

const getCaseSensitiveFromResponseProcessing = (responseProcessing) => {
    const stringMatchArr = [...responseProcessing?.getElementsByTagName(STRING_MATCH)];
    let caseSensitive;
    if (stringMatchArr?.length > 0) {
        caseSensitive = !stringMatchArr.some(stringMatch => stringMatch?.getAttribute(CASE_SENSITIVE) === 'false');
    }

    return caseSensitive;
};

const getCorrectAndMappedResponseForMultipleResponseDeclaration = (responseDeclarations) => {
    const correctResponses = [];
    const mappedCorrectResponses = [];

    if (responseDeclarations?.length > 0) {
        for (const response of responseDeclarations) {
            const responseIdentifier = response?.getAttribute(IDENTIFIER);
            const baseType = response?.getAttribute(BASE_TYPE);

            const valueNodes = [...response.getElementsByTagName(tags.value)];
            const mappingNodes = [...response.getElementsByTagName(tags.mapping)];
            let isCaseSensitive;
            if (mappingNodes?.length > 0) {
                const mapping = mappingNodes[0];
                const mapEntry = [...mapping?.getElementsByTagName(MAP_ENTRY)]?.[0];
                if (mapEntry) {
                    const attributes = getFormattedAttributes(mapEntry?.attributes);
                    const { mapKey, mappedValue, caseSensitive } = attributes || {};
                    if (baseType === 'string') {
                        isCaseSensitive = isBoolean(caseSensitive) ? caseSensitive : true;
                    }
                    mappedCorrectResponses.push({
                        responseIdentifier,
                        mappedValue,
                        mappedKey: mapKey,
                        baseType,
                        ...(isBoolean(isCaseSensitive) && { isCaseSensitive }),
                    });
                }
            }

            if (valueNodes?.length > 0) {
                correctResponses.push({
                    responseIdentifier,
                    correctResponse: valueNodes?.[0].textContent,
                    baseType,
                    ...(isBoolean(isCaseSensitive) && { isCaseSensitive }),
                });
            }
        }
    }
    return { correctResponses, mappedCorrectResponses };
};

const getResponseIdentifierData = (labels, points) => {
    const responseIdentifierData = [];
    const xAxis = {
        identifier: labels?.xAxis?.identifier,
        responseIdentifier: labels?.xAxis?.responseIdentifier,
    };
    if (xAxis?.identifier) responseIdentifierData.push(xAxis);

    const yAxis = {
        identifier: labels?.yAxis?.identifier,
        responseIdentifier: labels?.yAxis?.responseIdentifier,
    };
    if (yAxis?.identifier) responseIdentifierData.push(yAxis);

    const pointsData = points?.filter(({ responseIdentifier }) => responseIdentifier)
        .map(({ responseIdentifier, xIdentifier }) => ({
            identifier: xIdentifier,
            responseIdentifier,
        }));

    if (pointsData?.length > 0) responseIdentifierData.push(...pointsData);
    return responseIdentifierData;
};

const AddMissingTicks = (ticksData, oldTicks) => {
    const ticks = [...oldTicks];
    const {
        min, max, majorTickInterval, identifier,
        prefix, suffix,
    } = ticksData;
    const noOfTicks = (Number(max) - Number(min)) / Number(majorTickInterval);
    if (ticks?.length !== noOfTicks) {
        for (let i = min; i <= max; i += majorTickInterval) {
            const tickAbsent = !ticks?.find(ele => ele[identifier] === i);
            if (tickAbsent) {
                let label = `${prefix || ''}${i}${suffix || ''}`;
                label = label.trim();
                ticks.push({
                    [identifier]: i,
                    value: label,
                });
            }
        }
    }
    return ticks;
};

const getTicksLabels = (itemBody, axis) => {
    let ticks = [];
    const axisTags = [...itemBody.getElementsByTagName(axis)];
    const identifier = axis === XAXIS ? XIDENTIFIER : YIDENTIFIER;
    if (axisTags?.length > 0) {
        const currentAxis = axisTags[0];
        const {
            prefix, suffix, min, max, majorTickInterval,
        } = getFormattedAttributes(currentAxis?.attributes);
        currentAxis.children.forEach((tick) => {
            const attributes = getFormattedAttributes(tick?.attributes);
            const value = tick?.innerHTML || '';
            let label = `${prefix || ''}${value}${suffix || ''}`;
            label = label.trim();
            ticks.push({
                [identifier]: attributes[identifier],
                value: label,
            });
        });
        const ticksData = {
            min, max, majorTickInterval, identifier, prefix, suffix,
        };
        ticks = AddMissingTicks(ticksData, ticks);
    }
    return sortBy(ticks, [identifier]);
};

const getBarChartXTicks = (itemBody, tagName = BAR) => {
    const xTicks = [];
    const bars = [...itemBody.getElementsByTagName(tagName)];
    bars.forEach((xTick) => {
        const attributes = getFormattedAttributes(xTick?.attributes);
        xTicks.push({
            xIdentifier: attributes[XIDENTIFIER],
            value: attributes[LABEL],
        });
    });
    return xTicks;
};

const getSelectPointInteractionElement = (promptElement) => {
    const elements = promptElement ? [promptElement] : [];
    return {
        elements,
        name: tags.selectPointInteraction,
        type: NodeTypeValues.element,
    };
};

const updateXMLJs = (obj, data) => {
    for (const tag of obj) {
        const {
            name, elements: oldElements, attributes, type,
        } = tag;

        const {
            isCaseSensitive,
            identifiersToUpdate,
        } = data;

        let elements = oldElements?.length > 0 ? [...oldElements] : [];

        switch (name) {
        case tags.stringMatch:
        case tags.match: {
            const variableIdentifier = elements?.find(ele => ele.name === 'variable')?.attributes?.[IDENTIFIER];
            const caseSensitive = isBoolean(isCaseSensitive) ? isCaseSensitive : true;
            if (variableIdentifier && identifiersToUpdate.includes(variableIdentifier) && isBoolean(isCaseSensitive)) {
                return {
                    attributes: {
                        ...attributes,
                        caseSensitive,
                    },
                    name: tags.stringMatch,
                    type,
                    elements,
                };
            }
            break;
        }
        // Allow to go in recursion for below tags only
        case undefined:
        case tags.assessmentItem:
        case tags.responseProcessing:
        case tags.responseCondition:
        case tags.responseIf:
        case tags.and: {
            elements = getRecursiveElements(elements, updateXMLJs, data);
            break;
        }
        default: break;
        }
        return {
            ...tag,
            elements,
        };
    }
    return obj;
};

const getCaseSensitiveData = (responseDeclarations, correctResponse) => {
    const identifiersToUpdate = responseDeclarations.reduce((result, ele) => {
        const {
            attributes: {
                baseType,
                identifier,
            },
        } = ele;
        if (baseType === 'string') {
            result.push(identifier);
        }
        return result;
    }, []);

    const allCaseSensitivesFlag = [...new Set(correctResponse.reduce((result, ele) => {
        if (identifiersToUpdate.includes(ele.xmlIdentifier)) {
            result.push(ele.isCaseSensitive);
        }
        return result;
    }, []))];
    return {
        identifiersToUpdate,
        allCaseSensitivesFlag,
    };
};

const updateResponseDeclaration = (
    responseDeclarations, identifiersToUpdate, isCaseSensitive,
) => responseDeclarations?.map((ele) => {
    const {
        attributes: {
            baseType,
            identifier,
        },
        elements: oldElements,
    } = ele;

    let elements = [...oldElements];
    if (baseType === 'string' && identifiersToUpdate.includes(identifier)) {
        const mappingIndex = elements?.findIndex(cur => cur.name === MAPPING);
        if (mappingIndex > -1) {
            const {
                elements: mappingElements,
            } = elements[mappingIndex];

            const mapEntry = mappingElements[0];
            const mapEntryAttributes = {
                ...mapEntry.attributes,
                caseSensitive: isCaseSensitive,
            };
            const newMappingElements = [{
                ...mapEntry,
                attributes: mapEntryAttributes,
            }];

            elements = sliceAndUpdatePropertyWithValue(elements, mappingIndex, 'elements', newMappingElements);
        }
    }
    return {
        ...ele,
        elements,
    };
});

const updateXMLForCaseSensitive = (questionXml, identifier, data) => {
    const domParser = new DOMParser();
    const xml = domParser.parseFromString(questionXml, tags.applicationXml);
    const elements = convertXMLToData(xml, identifier);
    const assessmentItem = elements[0];
    const elementsWoResponseDeclarations = assessmentItem?.elements?.filter(ele => ele.name !== RESPONSE_DECLARATION);
    const responseDeclarationsTags = assessmentItem?.elements?.filter(ele => ele.name === RESPONSE_DECLARATION);
    const { correctResponse } = data;
    const { identifiersToUpdate, allCaseSensitivesFlag } =
        getCaseSensitiveData(responseDeclarationsTags, correctResponse);
    const shouldUpdateIsCaseSensitive = allCaseSensitivesFlag.length > 1;
    if (shouldUpdateIsCaseSensitive) {
        const isCaseSensitiveFlag = allCaseSensitivesFlag.reduce((a, ele) => a && ele, true);
        const responseDeclarations = updateResponseDeclaration(
            responseDeclarationsTags, identifiersToUpdate, isCaseSensitiveFlag,
        );
        const newCorrectResponses = correctResponse.map((response) => {
            if (identifiersToUpdate.includes(response.xmlIdentifier)) {
                return {
                    ...response,
                    isCaseSensitive: isCaseSensitiveFlag,
                };
            }
            return response;
        });

        const newData = {
            isCaseSensitive: isCaseSensitiveFlag,
            identifiersToUpdate,
        };

        const jsObject = [{
            elements: [{
                ...assessmentItem,
                elements: [
                    ...responseDeclarations,
                    ...elementsWoResponseDeclarations,
                ],
            }],
        }];

        const updatedJs = [updateXMLJs(jsObject, newData)];
        return {
            xml: convertDataToXML(updatedJs),
            correctResponseUpdated: newCorrectResponses,
        };
    }
    return {
        xml: questionXml,
        correctResponseUpdated: correctResponse,
    };
};

export {
    getFilteredElements,
    getElementsByName,
    getValidObject,

    // question Item Instruction Functions
    filterInstructionElements,
    getQuestionItemInstructionHTML,
    isQuestionItemInstructionEmpty,
    getPromptElement,
    getDivQuestionElement,
    getSelectPointInteractionElement,

    getImageTagWithText,
    updateChoices,
    getValuesSeparatedBySeparator,
    getCorrectResponses,
    getOptionProps,
    getQuestionDetails,
    updateCorrectResponses,

    // Node Functions
    getNodeListRecursively,
    findNodeByTagName,
    appendAttributesToTag,
    getFormattedAttributes,
    getDataFilledInNode,

    getSelectLabelTag,

    getCorrectResponseElement,
    getMappedCorrectResponseElement,
    getRecursiveElements,

    // Graph
    getGraphCorrectAndMappedResponsesWithIdentifier,
    getCorrectResponseElementForGraph,
    getMappedCorrectResponseElementForGraph,
    getTextEntryElementsForGraph,
    getChartDataElements,
    getLabels,
    getAxesData,
    getDataBasedOnAxis,
    getSnappingPlotsForGraph,
    getBarsData,
    getBarElements,

    getBarXAxisTemplateData,
    getAxisTemplateData,
    getCorrectAndMappedResponseForMultipleResponseDeclaration,
    getResponseIdentifierData,
    getTicksLabels,
    getBarChartXTicks,
    getSensitiveFromStringMatch,
    getCaseSensitiveFromResponseProcessing,
    updateXMLForCaseSensitive,
    encodeXml,
    encodeHtml,
};

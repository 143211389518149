import React from 'react';
import PropTypes from 'prop-types';
import FocusTrap from 'react-focus-trap';
import { withTranslation } from '../../withTranslation';
import './scss/DrawingToolModal.scss';

const DrawingToolModal = ({ children, deactivateModal, t }) => (
    <FocusTrap className="drawing-tool-modal">
        <div className="drawing-tool-modal__content">
            <button
                type="button"
                onClick={deactivateModal}
                aria-label={t('general.aria_close_modal', 'Close dialog')}
                className="drawing-tool-modal__close-button"
            >
                <span aria-hidden="true" className="icon icon-close" />
            </button>
            {children}
        </div>
    </FocusTrap>
);

DrawingToolModal.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element),
    ]).isRequired,
    deactivateModal: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

export default withTranslation()(DrawingToolModal);

import React from 'react';
import PropTypes from 'prop-types';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { Button as MaterialButton } from '@material-ui/core';
import clsx from 'clsx';
import theme from '../../theme';

const Theme = createMuiTheme({
    palette: {
        primary: {
            main: theme.accentColor,
        },
    },
    overrides: {
        MuiButton: {
            root: {
                color: theme.accentColor,
                textTransform: 'none',
                '&$disabled': {
                    color: theme.accentColor,
                    opacity: '0.5',
                    '&:hover': {
                        boxShadow: 'none',
                    },
                },
            },
            outlined: {
                borderWidth: '1px',
                borderColor: theme.accentColor,
                color: theme.accentColor,
                size: theme.buttonFontSize,
                fontWeight: theme.buttonFontWeight,
                borderRadius: '4px',
                backgroundColor: theme.white,
                '&.dropdown-open': {
                    backgroundColor: theme.accentColorActiveLight,
                    borderColor: theme.accentColorActive,
                    color: theme.accentColorActive,
                    boxShadow: 'none',
                },
                '&:hover': {
                    backgroundColor: theme.accentColorHoverLight,
                    boxShadow: '0px 8px 8px #0000003D',
                },
                '&:active': {
                    backgroundColor: theme.accentColorActiveLight,
                    borderColor: theme.accentColorActive,
                    color: theme.accentColorActive,
                    boxShadow: 'none',
                },
                '&$disabled': {
                    borderColor: theme.accentColor,
                    borderWidth: '1px',
                    opacity: '0.5',
                    '&:hover': {
                        boxShadow: 'none',
                        backgroundColor: 'transparent',
                    },
                },
            },
            outlinedPrimary: {
                borderColor: theme.accentColor,
                borderRadius: '4px',
                backgroundColor: theme.white,
                '&.dropdown-open': {
                    backgroundColor: theme.accentColorActiveLight,
                    borderColor: theme.accentColorActive,
                    color: theme.accentColorActive,
                    boxShadow: 'none',
                },
                '&:hover': {
                    backgroundColor: theme.accentColorHoverLight,
                    boxShadow: '0px 8px 8px #0000003D',
                },
                '&:active': {
                    backgroundColor: theme.accentColorActiveLight,
                    borderColor: theme.accentColorActive,
                    color: theme.accentColorActive,
                    boxShadow: 'none',
                },
                '&$disabled': {
                    opacity: '0.5',
                    '&:hover': {
                        boxShadow: 'none',
                        backgroundColor: 'transparent',
                    },
                },
            },
            contained: {
                marginLeft: '12px',
                marginRight: '12px',
                borderRadius: '4px',
                textAlign: 'left',
                height: '36px',
                letterSpacing: 1,
                opacity: '1',
                border: 0,
                padding: '0 30px',
                boxShadow: 'none',
                fontSize: theme.buttonFontSize,
                fontWeight: theme.buttonFontWeight,
                color: theme.white,
                backgroundColor: theme.accentColor,
                '&.dropdown-open': {
                    backgroundColor: theme.accentColorActive,
                    boxShadow: 'none',
                },
                '&$disabled': {
                    color: theme.white,
                    backgroundColor: theme.accentColor,
                    opacity: '0.5',
                    '&:hover': {
                        backgroundColor: theme.accentColor,
                        boxShadow: 'none',
                    },
                },
                '&:hover': {
                    backgroundColor: theme.accentColorHover,
                    boxShadow: '0px 8px 8px #0000003D',
                    '@media (hover: none)': {
                        backgroundColor: theme.accentColorHover,
                        boxShadow: '0px 8px 8px #0000003D',
                    },
                },
                '&:active': {
                    backgroundColor: theme.accentColorActive,
                    boxShadow: 'none',
                },
            },
            containedPrimary: {
                fontSize: theme.buttonFontSize,
                fontWeight: theme.buttonFontWeight,
                color: theme.white,
                backgroundColor: theme.accentColor,
                borderRadius: '4px',
                '&.dropdown-open': {
                    backgroundColor: theme.accentColorActive,
                    boxShadow: 'none',
                },
                '&:hover': {
                    backgroundColor: theme.accentColorHover,
                    boxShadow: '0px 8px 8px #0000003D',
                    '@media (hover: none)': {
                        backgroundColor: theme.accentColorHover,
                        boxShadow: '0px 8px 8px #0000003D',
                    },
                },
                '&:active': {
                    backgroundColor: theme.accentColorActive,
                    boxShadow: 'none',
                },
                '&$disabled': {
                    opacity: '0.5',
                    '&:hover': {
                        backgroundColor: theme.accentColor,
                        boxShadow: 'none',
                    },
                },
            },
        },
    },
});

const Button = (props) => {
    const { className, variant } = props;
    const buttonProps = { ...props };
    return (
        <ThemeProvider theme={Theme}>
            <MaterialButton
                disableRipple
                disableFocusRipple
                {...props}
                className={clsx(
                    variant && 'tap-button',
                    variant && variant === 'contained' && 'tap-button-contained',
                    variant && variant === 'outlined' && 'tap-button-outlined',
                    className,
                )}
            >
                {buttonProps.children}
            </MaterialButton>
        </ThemeProvider>
    );
};

Button.propTypes = {
    className: PropTypes.string,
    variant: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.shape(),
        PropTypes.arrayOf(PropTypes.element),
    ]).isRequired,
};

Button.defaultProps = {
    className: '',
    variant: '',
};

export default Button;

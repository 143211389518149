import styled from 'styled-components';

const LoadingPlaceholder = styled.div`
    background: #ffffff;
    color: #666666;
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 5000;
    top: 0;
    left: 0;
    float: left;
    text-align: center;
    padding-top: 20%;
    opacity: .80;
    `;

export default LoadingPlaceholder;

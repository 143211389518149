import React from 'react';
import { Route } from 'react-router-dom';
import teacherUrl from './urls';

const Teacher = React.lazy(() => import(/* webpackChunkName: "Teacher" */ './'));

const routes = [
    <Route path={teacherUrl} component={Teacher} key={Teacher} />,
];


export default routes;

import axios from 'axios';

export default async (url, data = {}, headers = {}) => {
    let response;
    try {
        response = await axios.post(
            url,
            data,
            { headers, withCredentials: true },
        );
    } catch (e) {
        if (Object.prototype.hasOwnProperty.call(e, 'response') && typeof e.response !== 'undefined' &&
            Object.prototype.hasOwnProperty.call(e.response, 'status') && e.response.status === 403) {
            throw new Error(e.response.data.detail);
        } else {
            throw new Error('The username, password or district ID you entered was incorrect. Please try again.');
        }
    }

    return response;
};

import actions from '../actions/actions';

const initialState = {
    ttsLanguageData: null,
};

const ttsLanguageDataReducer = (state = initialState, action = {}) => {
    if (action.type === actions.UPDATE_TTS_LANGUAGE_DATA) {
        if (state.ttsLanguageData !== action.ttsLanguageData) {
            return { ttsLanguageData: action.ttsLanguageData };
        }
    }
    return state;
};

export default ttsLanguageDataReducer;

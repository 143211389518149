import { gql } from '@apollo/client';
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import { PropTypes } from 'prop-types';
import axios from 'axios';
import uuid from 'uuid';
import b64toBlob from 'b64-to-blob';
import cameraIcon from '../../icons/camera.svg';
import { Translation } from '../../../Translation';
import '../scss/Camera.scss';

export const userUploadedImagesQuery = gql`
    query userUploadedImages($keys: [String]!) {
        userUploadedImages(keys: $keys) {
            images {
                key
                url
            }
        }
    }
`;

class CameraComponent extends React.Component {
    constructor(props) {
        super(props);
        this.id = uuid();
        this.captureImage = this.captureImage.bind(this);
        this.requestAccessToCamera = this.requestAccessToCamera.bind(this);
        this.state = {
            cameraAvailable: 'mediaDevices' in navigator,
            error: false,
            loading: false,
            videoSize: {
                height: 400,
                width: 800,
            },
        };
    }

    async componentDidMount() {
        const { fabric } = await import('fabric');
        if (this.state.cameraAvailable) {
            this.requestAccessToCamera();
        }
        if (this.player) {
            this.player.addEventListener('loadedmetadata', (metadata) => {
                this.setState({
                    videoSize: {
                        height: metadata.target.videoHeight,
                        width: metadata.target.videoWidth,
                    },
                });
            });
        }
        this.setState({
            fabric,
        });
    }

    componentWillUnmount() {
        if (this.player) this.player.srcObject.getVideoTracks().forEach(track => track.stop());
    }

    async getS3ImageUrl(key) {
        return this.props.client.query({
            query: userUploadedImagesQuery,
            variables: { keys: [key] },
        });
    }

    async uploadImageToS3(url, file, fields) {
        const options = {
            headers: {
                'Content-Type': file.type,
            },
        };

        const data = new FormData();
        Object.keys(fields).forEach((fieldKey) => {
            data.append(fieldKey, fields[fieldKey]);
        });
        data.append('Content-Type', file.type);
        data.append('file', file);

        await axios.post(
            url,
            data,
            options,
        );
    }

    async uploadFile(file) {
        const filetype = 'png';
        const response = await this.props.requestPresignedUploadUrl({ variables: { filetype } });

        const { fields, key, url } = response.data.createPresignedPost;

        await this.uploadImageToS3(url, file, fields);

        return key;
    }

    addImageToCanvas(url) {
        const { fabric } = this.state;

        fabric.Image.fromURL(url, (image) => {
            const size = this.props.canvas.width < 800 ? this.props.canvas.width / 2 : 400;
            image.scaleToWidth(size);
            image.set({ top: 50, left: 50 });
            this.props.canvas.add(image);
            this.props.saveCanvasState();
        });
    }

    async handleImageUpload(file) {
        const key = await this.uploadFile(file);

        const getS3ImageUrlsResponse = await this.getS3ImageUrl(key);

        getS3ImageUrlsResponse.data.userUploadedImages.images.forEach((image) => {
            this.addImageToCanvas(image.url);
        });
    }

    async captureImage() {
        this.setState({ loading: true });

        const context = this.canvas.getContext('2d');
        // Draw the video frame to the canvas.
        context.drawImage(this.player, 0, 0, this.canvas.width, this.canvas.height);
        // Stop all video streams.
        if (this.player.srcObject) this.player.srcObject.getVideoTracks().forEach(track => track.stop());

        const contentType = 'image/png';
        const b64Data = this.canvas.toDataURL().replace('data:image/png;base64,', '');
        const fileBlob = b64toBlob(b64Data, contentType);

        try {
            await this.handleImageUpload(fileBlob);
            this.props.onCapture();
        } catch (e) {
            this.setState({ error: true });
        }
    }

    requestAccessToCamera() {
        const constraints = { video: true };

        // Attach the video stream to the video element and autoplay.
        navigator.mediaDevices.getUserMedia(constraints)
            .then((stream) => {
                this.player.srcObject = stream;
            })
            .catch(() => {
                this.setState({ cameraAvailable: false });
            });
    }

    render() {
        const { LoadingPlaceholder } = this.props.frontEndComponents;
        if (this.state.error) {
            return (
                <div>
                    <Translation
                        translationKey="camera.generic_error"
                        defaultValue="Sorry, an error occurred"
                    />
                </div>
            );
        }
        if (this.state.loading) return <LoadingPlaceholder />;

        return (
            <div className="camera-component">
                {
                    !this.state.cameraAvailable ? (
                        <p>
                            <Translation
                                translationKey="camera.camera_not_available"
                                defaultValue="Sorry, camera capture is not available on your device"
                            />
                        </p>
                    ) : (
                        <div>
                            <video
                                autoPlay
                                ref={(player) => { this.player = player; }}
                                className="camera-component__video"
                            />
                            <button type="button" onClick={this.captureImage} className="camera-component__button">
                                <img src={cameraIcon} alt="" />
                                <span className="camera-component__button-text">
                                    <Translation
                                        translationKey="camera.take_photo"
                                        defaultValue="Take photo"
                                    />
                                </span>
                            </button>
                            <canvas
                                width={this.state.videoSize.width}
                                height={this.state.videoSize.height}
                                ref={(canvas) => { this.canvas = canvas; }}
                                style={{ display: 'none' }}
                            />
                        </div>
                    )}
            </div>
        );
    }
}

CameraComponent.propTypes = {
    canvas: PropTypes.shape({
        add: PropTypes.func.isRequired,
        width: PropTypes.number.isRequired,
    }).isRequired,
    client: PropTypes.shape({
        query: PropTypes.func.isRequired,
    }).isRequired,
    onCapture: PropTypes.func.isRequired,
    requestPresignedUploadUrl: PropTypes.func.isRequired,
    saveCanvasState: PropTypes.func.isRequired,
    frontEndComponents: PropTypes.shape({
        LoadingPlaceholder: PropTypes.func.isRequired,
    }).isRequired,
};

export default CameraComponent;

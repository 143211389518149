/* eslint-disable react/prop-types */
import React from 'react';
import {
    OptionText, OptionImage, TtsOnlySpan, OptionImageDiv,
} from './index.style';
import { IMAGETEXT, OPTIONTEXT } from '../../../../../services/stringConstants';
import { encodeHtml } from '../../../../../../../../src/js/services/xmlConversion/QuestionXMLAndData/commonFunctions';

class Option extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { text, img, optionNumber } = this.props;
        let image = null;
        if (img) {
            const {
                src, alt, height, width,
            } = img;
            image = (
                <React.Fragment>
                    <TtsOnlySpan>
                        {IMAGETEXT}
                    </TtsOnlySpan>
                    <OptionImage src={src} alt={alt} height={parseFloat(height)} width={parseFloat(width)} />
                </React.Fragment>
            );
        }
        return (
            <React.Fragment>
                <TtsOnlySpan>
                    {OPTIONTEXT}
                    {' '}
                    {optionNumber}
                </TtsOnlySpan>
                <OptionImageDiv
                    aria-hidden="true"
                >
                    {image}
                </OptionImageDiv>
                <OptionText
                    aria-label={text}
                >
                    {encodeHtml(text)}
                </OptionText>
            </React.Fragment>
        );
    }
}

export default Option;

import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import BaseComponent from '../BaseComponent';
import LoaderWrapper from './LoadingPlaceholder.styles';

class LoadingPlaceholder extends BaseComponent {
    render() {
        return (

            <LoaderWrapper>
                <CircularProgress
                    color="secondary"
                    size={50}
                    disableShrink
                />
            </LoaderWrapper>
        );
    }
}

export default LoadingPlaceholder;

import actions from '../actions/actions';

const showHideLoaderReducer = (state = [], action = {}) => {
    switch (action.type) {
    case actions.SHOW_LOADER:
        return [
            ...state,
            action.loader,
        ];
    case actions.HIDE_LOADER:
        return state.filter(loader => loader !== action.loader);
    default:
        return state;
    }
};

export default showHideLoaderReducer;

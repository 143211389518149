import React from 'react';
import clsx from 'clsx';
import {
    isEqual, isEmpty, isNumber, isNaN,
} from 'lodash';
import PropTypes from 'prop-types';
import { InputAdornment, Typography } from '@material-ui/core';
import CorrectIcon from '../../../../../../tap-fe-components/src/components/Icons/Correct';
import IncorrectIcon from '../../../../../../tap-fe-components/src/components/Icons/Incorrect';
import {
    EditableWrapper,
    TextBoxWrapper,
    NoGuidanceWrapper,
    CorrectAnswerWrapper,
    Answerlabel,
    ControlWrapper,
    TickIconWrapper,
    TickWrapper,
    CharacterCountWrapper,
    ErrorBox,
    CharacterViewWrapper,
    TtsScriptWrapper,
    AnswerPreviewWrapper,
    DataTextView,
    AndormentView,
    AnswerShortView,
} from './index.styles';
import QuestionText from '../QuestionText';
import StringTable from '../../../services/stringTable';
import keys from '../../../constants/keys';
import StyledTextField from './TextField';
import EllipisWithTooltip from
    '../../../../../../tap-fe-components/src/components/EllipisWithTooltip';
import theme from '../../../../../../tap-fe-components/src/theme';

const correctAnswerText = StringTable.getString(keys.questions, keys.common, keys.correctAnswer);
const rubricGuidanceText = StringTable.getString(keys.questions, keys.common, keys.rubricGuidanceText);
const characterLimitText = StringTable.getString(keys.questions, keys.common, keys.characterLimitText);
const noGuidanceOrRubric = StringTable.getString(keys.questions, keys.common, keys.noGuidanceOrRubric);

class EditableQuestion extends React.Component {
    constructor(props) {
        super(props);
        const { expectedLines } = props;
        let { response } = props;
        response = response || [];

        const answer = response[0] ? response[0].responseText : '';
        this.state = {
            showAnswer: false,
            answer,
            shrink: !!answer,
            caseSensitive: this.caseSensitiveMatch(),
            prefixWidth: 0,
            isError: false,
            multilineCustomLabel: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.getResponseData = props.getResponseData.bind(this);
        this.timer = null;
        this.inputRef = React.createRef();
        this.prefixRef = React.createRef();
        this.inputRows = expectedLines;
        this.baseScrollHeight = 0;
        this.lineHeight = 0;
    }

    componentDidUpdate = (prevProps) => {
        const { response, prefix } = this.props;
        if (!isEqual(response, prevProps.response)) {
            this.setResponse();
        }
        if (!isEqual(prefix, prevProps.prefix)) {
            this.setbaseScrollHeight();
        }
    };

    setResponse = () => {
        let { response } = this.props;
        response = response || [];
        const answer = response[0] ? response[0].responseText : '';
        this.setState({ answer });
    };

    shrinkLabel = () => {
        this.setState({ shrink: true });
    };

    isInvalid = (value, isFocusOut = false) => {
        const errorValue = isFocusOut ? null : '';
        const string = typeof value === 'string' ? value.trim() : value;
        const isInvalid = (isEmpty(string) && !isNumber(string)) || isNaN(string);
        const updatedValue = isInvalid ? errorValue : value;
        return { isInvalid, updatedValue };
    }

    expandShrunkLabel = (event) => {
        const {
            target: { value },
        } = event;
        const { setCorrectAnswerResponse, isEditableAssessment, isCorrectAnswerView } = this.props;
        if (event.target.value.length === 0) {
            this.setState({ shrink: false });
        }
        if (isEditableAssessment && isCorrectAnswerView) {
            const { isError } = this.state;
            const { isInvalid, updatedValue } = this.isInvalid(value, true);

            if (isInvalid !== isError) {
                this.setState({
                    isError: !isError,
                });
            }
            setCorrectAnswerResponse([updatedValue], true);
        }
    };

    componentDidMount = () => {
        this.setbaseScrollHeight();
        this.setbLabelHeight();
    }

    setbLabelHeight = () => {
        setTimeout(() => {
            const labelElement = this.inputRef?.current?.labels[0];
            if (labelElement) {
                if (labelElement.offsetHeight > 20) {
                    this.setState({
                        multilineCustomLabel: true,
                    });
                    labelElement.style.display = 'inline-block';
                    labelElement.style.whiteSpace = 'nowrap';
                    labelElement.style.textOverflow = 'ellipsis';
                    labelElement.style.overflow = 'hidden';
                }
            }
        }, 100);
    }

    setbaseScrollHeight = () => {
        const { multiline } = this.props;
        setTimeout(() => {
            if (multiline && this.inputRef.current) {
                const target = this.inputRef.current;
                const tempValue = target.value;
                target.value = '';
                this.baseScrollHeight = target.scrollHeight;
                this.lineHeight = target.scrollHeight / this.inputRows;
                target.value = tempValue;
                this.updateHeight();
            } else if (!multiline && this.prefixRef.current) {
                const prefix = this.prefixRef.current.firstChild;
                this.setState({
                    prefixWidth: prefix ? prefix.offsetWidth + 8 : 0,
                });
            }
        }, 100);
    }

    updateHeight = (event) => {
        if (this.inputRef.current) {
            const target = event ? event.target : this.inputRef.current;
            const { expectedLines } = this.props;
            const minRows = expectedLines || 0;
            target.rows = minRows;
            const rows = Math.ceil((target.scrollHeight - this.baseScrollHeight) / this.lineHeight);
            target.rows = minRows + rows;
            this.inputRows = target.rows;
        }
    }

    handleClick = () => {
        const { showAnswer } = this.state;
        this.setState({
            showAnswer: !showAnswer,
        });
    };


    characterCheckView = (answer, limit) => {
        const {
            disable,
            showCorrectAnswer,
            showGrading,
        } = this.props;
        const characterLimit = Number(limit);
        const showErrorBox = answer?.length === characterLimit;
        const valueLength = answer?.length;
        const isDisabled = showCorrectAnswer || showGrading || disable;
        const displayError = showErrorBox && !isDisabled;
        return (
            <CharacterViewWrapper>
                {displayError && <ErrorBox>{characterLimitText}</ErrorBox>}
                <CharacterCountWrapper>
                    {valueLength?.toLocaleString()}
                    {' '}
                    /
                    {' '}
                    {characterLimit?.toLocaleString()}
                </CharacterCountWrapper>
            </CharacterViewWrapper>
        );
    }

    handleChange = (event) => {
        const { setCorrectAnswerResponse, isEditableAssessment, isCorrectAnswerView } = this.props;
        const { target: { value } } = event;
        this.setState({ answer: value }, () => {
            event.persist();
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                const { answer } = this.state;
                const { responseIdentifier } = this.props;
                let response;
                let isAttempted;
                if (answer !== '') {
                    response = [{
                        xmlIdentifier: responseIdentifier,
                        responseText: answer,
                    }];
                    isAttempted = true;
                } else {
                    response = [];
                    isAttempted = false;
                }

                this.getResponseData({
                    isAttempted,
                    response,
                });
                if (isEditableAssessment && isCorrectAnswerView) {
                    const { isError } = this.state;
                    const { isInvalid, updatedValue } = this.isInvalid(value);
                    if (!isInvalid && isError) {
                        this.setState({
                            isError: false,
                        });
                    }
                    setCorrectAnswerResponse([updatedValue], false);
                }
            }, 500);
        });
    };

    caseSensitiveMatch = () => {
        const { responseIdentifier, checkCorrectResponse } = this.props;
        const response = checkCorrectResponse?.find(value => value?.xmlIdentifier === responseIdentifier);
        if (response) {
            return response.isCaseSensitive !== undefined ? response.isCaseSensitive : true;
        }
        return true;
    };

    getTextBoxWrapperPrefixWidth = (shrink, prefixWidth) => (!shrink ? prefixWidth : 0);

    getStyledTextFieldHelperText = (expectedLength, correctAnswerValue) => (expectedLength ?
        this.characterCheckView(correctAnswerValue, expectedLength) : '');

    getTextFieldProps = () => {
        const {
            prefix,
            suffix,
            multiline,
            expectedLength,
            isEditableAssessment,
        } = this.props;
        const { answer } = this.state;
        const limit = this.getLimit(expectedLength);
        const updateExpectedLength = this.getupdateExpectedLength(limit);
        const CHARACTER_LIMIT = limit;
        let ariaLabel = '';
        if (isEditableAssessment) {
            const { labelText } = this.props;
            ariaLabel = labelText || '';
        }
        const textFieldProps = {
            InputProps: {
                endAdornment: (
                    <InputAdornment position="end">
                        <Typography style={{ color: theme.secondaryTextColor }}>
                            {suffix}
                        </Typography>
                    </InputAdornment>
                ),
            },
            multiline,
            rows: this.inputRows,
            onChange: this.handleChange,
            variant: 'outlined',
            value: answer,
            inputProps: {
                maxLength: CHARACTER_LIMIT,
                'aria-label': ariaLabel,
            },
            helperText: updateExpectedLength ? this.characterCheckView(answer, updateExpectedLength) : '',
        };

        if (prefix) {
            textFieldProps.InputProps.startAdornment = (
                <InputAdornment position="start" ref={this.prefixRef}>
                    <Typography style={{ color: theme.secondaryTextColor }}>
                        {prefix}
                    </Typography>
                </InputAdornment>
            );
        }
        if (multiline) {
            textFieldProps.onInput = this.updateHeight;
            textFieldProps.inputProps = {
                style: {
                    overflow: 'hidden',
                },
                maxLength: CHARACTER_LIMIT,
                'aria-label': ariaLabel,
            };
        }
        return textFieldProps;
    }

    getCorrectAnswer = (caseSensitive, answer, correctAnswerValue) => (caseSensitive ? answer === correctAnswerValue :
        answer.toUpperCase() === correctAnswerValue.toUpperCase());

    shouldRenderCorrectAnswer = (showCorrectAnswer, multiline, correctResponse) => ((showCorrectAnswer && multiline) ||
        (showCorrectAnswer && !correctResponse));

    shouldRenderCorrectIcon = (showCorrectAnswer, multiline, correctResponse, showGrading, isCorrect) => (!multiline &&
        ((showCorrectAnswer && correctResponse) || (showGrading && isCorrect && correctResponse)));

    shouldRenderIncorrectIcon = (multiline, correctResponse, showGrading, isIncorrect) => (!multiline && showGrading &&
        isIncorrect && correctResponse);

    getShowTickSpace = (multiline, showTickInGrading, isIncorrect, isCorrect) => (
        !multiline && showTickInGrading && !(isCorrect || isIncorrect)
    );

    getLimit = expectedLength => (expectedLength ? expectedLength.toString().replace(/[,]/g, '') : expectedLength);

    getupdateExpectedLength = limit => (limit === '0' ? '' : limit);

    getTextBoxWrapper =() => {
        const {
            questionHTML,
            label,
            multiline,
            showCorrectAnswer,
            correctAnswer,
            showGrading,
            userAnswerLabel,
            disable,
            correctResponse,
            expectedLength,
            isAnswerPreviewView,
            isNotAttempted,
            prefix,
            suffix,
            isRubric,
            isAuthorView,
            isQuestionWrapper,
            questionItemInstruction,
        } = this.props;
        const {
            answer, shrink, caseSensitive, prefixWidth, multilineCustomLabel,
        } = this.state;
        const limit = this.getLimit(expectedLength);
        const updateExpectedLength = this.getupdateExpectedLength(limit);
        const textFieldProps = this.getTextFieldProps();
        const parser = new DOMParser();
        let doc = parser.parseFromString(questionHTML, 'text/html');
        let textContent = doc?.querySelector('.question p')?.textContent?.trim();
        if (!textContent) {
            if (questionItemInstruction && !questionHTML) {
                doc = parser.parseFromString(questionItemInstruction, 'text/html');
            }
            textContent = (questionItemInstruction) ?
                doc?.querySelector('p')?.textContent?.trim() :
                doc?.querySelector('p:nth-of-type(2)')?.textContent?.trim();
        }
        const isCorrect = this.getCorrectAnswer(caseSensitive, answer, correctAnswer.value);
        const isIncorrect = !isCorrect;
        const isDisabled = showCorrectAnswer || showGrading || disable;
        const showTickInGrading = showGrading && correctResponse && !isNotAttempted;
        if (!isDisabled && textContent) {
            textFieldProps.label = `${label} to ${textContent}`;
        } else if (showGrading) {
            textFieldProps.label = '';
        } else if (showCorrectAnswer) {
            textFieldProps.label = '';
        } else {
            textFieldProps.label = `${label}`;
        }
        const toolTipLabel = textFieldProps.label;
        if (showCorrectAnswer) {
            textFieldProps.value = correctAnswer.value;
            textFieldProps.helperText = updateExpectedLength ?
                this.characterCheckView(correctAnswer.value, updateExpectedLength) : '';
        }
        if (this.shouldRenderCorrectAnswer(showCorrectAnswer, multiline, correctResponse)) {
            const isBottomPadding = isRubric || (isAuthorView && isQuestionWrapper);
            return (
                <React.Fragment>
                    {questionHTML && (
                        <QuestionText questionHTML={questionHTML} isBottomPadding={isBottomPadding} />
                    )}
                    {
                        isRubric ? <NoGuidanceWrapper>{rubricGuidanceText}</NoGuidanceWrapper> :
                            isAuthorView && isQuestionWrapper &&
                                <NoGuidanceWrapper>{noGuidanceOrRubric}</NoGuidanceWrapper>
                    }
                </React.Fragment>
            );
        }
        if (isAnswerPreviewView) {
            let previewDataText = answer;
            if (!multiline) {
                previewDataText = (
                    <React.Fragment>
                        <AndormentView>{prefix || ''}</AndormentView>
                        <AnswerShortView>{answer}</AnswerShortView>
                        <AndormentView>{suffix || ''}</AndormentView>
                    </React.Fragment>
                );
            }
            return (
                <AnswerPreviewWrapper multiline={multiline}>
                    <EllipisWithTooltip
                        tooltipProps={{
                            title: previewDataText,
                        }}
                        style={{
                            display: '-webkit-box',
                            WebkitLineClamp: '7',
                            WebkitBoxOrient: 'vertical',
                            whiteSpace: 'normal',
                            wordBreak: 'break-word',
                        }}
                        MultilineBlock
                    >
                        <DataTextView>
                            {previewDataText}
                        </DataTextView>
                    </EllipisWithTooltip>
                </AnswerPreviewWrapper>
            );
        }
        return (
            <TextBoxWrapper
                className="rs_skip"
                prefixWidth={this.getTextBoxWrapperPrefixWidth(shrink, prefixWidth)}
                multilineCustomLabel={multilineCustomLabel}
            >
                {
                    this.shouldRenderCorrectIcon(
                        showCorrectAnswer, multiline, correctResponse, showGrading, isCorrect,
                    ) ?
                        (
                            <TickIconWrapper>
                                {' '}
                                <CorrectIcon size={32} />
                            </TickIconWrapper>
                        ) : null

                }
                {
                    this.shouldRenderIncorrectIcon(multiline, correctResponse, showGrading, isIncorrect) ? (
                        <TickIconWrapper>
                            {' '}
                            <IncorrectIcon size={32} />
                        </TickIconWrapper>
                    ) : null
                }
                <ControlWrapper
                    showTickSpace={!multiline && showTickInGrading && !(isCorrect || isIncorrect)}
                >
                    {(!multiline && showCorrectAnswer) ?
                        <Answerlabel notCapitalize>{correctAnswerText}</Answerlabel> : null}
                    {showGrading ?
                        <Answerlabel>{userAnswerLabel}</Answerlabel> : null}
                    <StyledTextField
                        aria-label="textField"
                        inputRef={this.inputRef}
                        onFocus={this.shrinkLabel}
                        onBlur={this.expandShrunkLabel}
                        {...textFieldProps}
                        InputLabelProps={{ shrink: isDisabled ? true : shrink }}
                        disabled={isDisabled}
                        className={isDisabled ? 'disabled' : ''}
                        showCharacterLength={
                            expectedLength !== null && expectedLength !== '' && expectedLength !== '0'
                        }
                        toolTipLabel={toolTipLabel}
                        multilineCustomLabel={multilineCustomLabel}
                    />
                </ControlWrapper>
            </TextBoxWrapper>
        );
    }

    render() {
        const {
            questionHTML,
            label,
            prefix,
            suffix,
            multiline,
            showCorrectAnswer,
            correctAnswer,
            showGrading,
            disable,
            correctResponse,
            expectedLength,
            isAnswerPreviewView,
            isNotAttempted,
            isTtsEnabled,
            questionpartPrefix,
            isRubric,
            isAuthorView,
            isQuestionWrapper,
        } = this.props;

        const {
            answer, shrink, caseSensitive, prefixWidth,
        } = this.state;
        const limit = this.getLimit(expectedLength);
        const updateExpectedLength = this.getupdateExpectedLength(limit);
        const textFieldProps = this.getTextFieldProps();
        const parser = new DOMParser();
        const doc = parser.parseFromString(questionHTML, 'text/html');
        const textContent = doc?.querySelector('.question p')?.textContent?.trim();
        const isCorrect = this.getCorrectAnswer(caseSensitive, answer, correctAnswer.value);
        const isDisabled = showCorrectAnswer || showGrading || disable;
        if (!isDisabled && textContent) {
            textFieldProps.label = `${label} to ${textContent}`;
        } else if (showGrading) {
            textFieldProps.label = '';
        } else {
            textFieldProps.label = `${label}`;
        }
        if (showCorrectAnswer) {
            textFieldProps.value = correctAnswer.value;
            textFieldProps.helperText = updateExpectedLength ?
                this.characterCheckView(correctAnswer.value, updateExpectedLength) : '';
        }
        if (this.shouldRenderCorrectAnswer(showCorrectAnswer, multiline, correctResponse)) {
            const isBottomPadding = isRubric || (isAuthorView && isQuestionWrapper);
            return (
                <React.Fragment>
                    {questionHTML && (
                        <QuestionText questionHTML={questionHTML} isBottomPadding={isBottomPadding} />
                    )}
                    {
                        isRubric ? <NoGuidanceWrapper>{rubricGuidanceText}</NoGuidanceWrapper> :
                            isAuthorView && isQuestionWrapper &&
                                <NoGuidanceWrapper>{noGuidanceOrRubric}</NoGuidanceWrapper>
                    }
                </React.Fragment>
            );
        }
        if (isAnswerPreviewView) {
            let previewDataText = answer;
            if (!multiline) {
                previewDataText = (
                    <React.Fragment>
                        <AndormentView>{prefix || ''}</AndormentView>
                        <AnswerShortView>{answer}</AnswerShortView>
                        <AndormentView>{suffix || ''}</AndormentView>
                    </React.Fragment>
                );
            }
            return (
                <AnswerPreviewWrapper multiline={multiline}>
                    <EllipisWithTooltip
                        tooltipProps={{
                            title: previewDataText,
                        }}
                        style={{
                            display: '-webkit-box',
                            WebkitLineClamp: '7',
                            WebkitBoxOrient: 'vertical',
                            whiteSpace: 'normal',
                            wordBreak: 'break-word',
                        }}
                        MultilineBlock
                    >
                        <DataTextView>
                            {previewDataText}
                        </DataTextView>
                    </EllipisWithTooltip>
                </AnswerPreviewWrapper>
            );
        }
        return (
            <EditableWrapper>
                {questionHTML && !isAnswerPreviewView && (
                    <QuestionText
                        questionHTML={questionHTML}
                        isTtsEnabled={isTtsEnabled}
                        questionpartPrefix={questionpartPrefix}
                    />
                )}
                {
                    isTtsEnabled && prefix && (
                        <TtsScriptWrapper>{prefix}</TtsScriptWrapper>
                    )
                }
                {
                    isTtsEnabled && suffix && (
                        <TtsScriptWrapper>{suffix}</TtsScriptWrapper>
                    )
                }
                {this.getTextBoxWrapper()}
                {
                    !multiline && showGrading && !isCorrect && correctResponse ?
                        <Answerlabel className="correct-answer-label">{correctAnswerText}</Answerlabel> :
                        null
                }
                {!isNotAttempted && (
                    <React.Fragment>
                        {
                            (!multiline && showGrading && !isCorrect && correctResponse) ? (
                                <CorrectAnswerWrapper>
                                    <TextBoxWrapper
                                        className="correct-answer"
                                        prefixWidth={this.getTextBoxWrapperPrefixWidth(shrink, prefixWidth)}
                                    >
                                        <TickWrapper>
                                            <CorrectIcon size={32} />
                                        </TickWrapper>
                                        <StyledTextField
                                            ref={this.correctAnswerRef}
                                            {...textFieldProps}
                                            value={correctAnswer.value}
                                            className={clsx({
                                                disabled: isDisabled,
                                                rs_skip: true,
                                            })}
                                            disabled
                                            showCharacterLength={
                                                expectedLength !== null &&
                                                expectedLength !== '' &&
                                                expectedLength !== '0'
                                            }
                                            helperText={this.getStyledTextFieldHelperText(
                                                expectedLength, correctAnswer.value,
                                            )}
                                        />
                                    </TextBoxWrapper>
                                </CorrectAnswerWrapper>
                            ) : null
                        }
                    </React.Fragment>
                )}
            </EditableWrapper>
        );
    }
}

EditableQuestion.propTypes = {
    questionHTML: PropTypes.string,
    label: PropTypes.string.isRequired,
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    multiline: PropTypes.bool.isRequired,
    expectedLines: PropTypes.number,
    getResponseData: PropTypes.func,
    responseIdentifier: PropTypes.string,
    response: PropTypes.arrayOf(PropTypes.shape({
        xmlIdentifier: PropTypes.string,
        responseText: PropTypes.string,
    })),
    showCorrectAnswer: PropTypes.bool,
    showGrading: PropTypes.bool,
    correctAnswer: PropTypes.shape({
        value: PropTypes.string,
    }),
    userAnswerLabel: PropTypes.string,
    disable: PropTypes.bool,
    correctResponse: PropTypes.bool,
    isPreview: PropTypes.bool,
    // eslint-disable-next-line react/forbid-prop-types
    checkCorrectResponse: PropTypes.array,
    expectedLength: PropTypes.string,
    isTtsEnabled: PropTypes.bool,
    isAnswerPreviewView: PropTypes.bool,
    isNotAttempted: PropTypes.bool,
    setCorrectAnswerResponse: PropTypes.func,
    isEditableAssessment: PropTypes.bool,
    isCorrectAnswerView: PropTypes.bool,
    labelText: PropTypes.string,
    questionpartPrefix: PropTypes.string,
    isRubric: PropTypes.bool,
    isAuthorView: PropTypes.bool,
    isQuestionWrapper: PropTypes.bool,
    questionItemInstruction: PropTypes.string,
};

EditableQuestion.defaultProps = {
    questionHTML: '',
    prefix: '',
    suffix: '',
    expectedLines: 4,
    getResponseData: () => { /* Return Empty */ },
    responseIdentifier: '',
    response: [],
    showCorrectAnswer: false,
    showGrading: false,
    correctAnswer: {},
    userAnswerLabel: '',
    disable: false,
    correctResponse: false,
    isPreview: false,
    checkCorrectResponse: [],
    expectedLength: '',
    isTtsEnabled: false,
    isAnswerPreviewView: false,
    isNotAttempted: false,
    setCorrectAnswerResponse: () => { /* Return Empty */ },
    isEditableAssessment: false,
    isCorrectAnswerView: false,
    labelText: '',
    questionpartPrefix: '',
    isRubric: false,
    isAuthorView: false,
    isQuestionWrapper: false,
    questionItemInstruction: '',
};

export default EditableQuestion;

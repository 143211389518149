/* eslint-disable react/prop-types */
import React from 'react';
import { Button, FormControl } from '@material-ui/core';
import { isSafari } from 'react-device-detect';
import Option from '../Option';
import CorrectIcon from '../../../../../../../../tap-fe-components/src/components/Icons/Correct';
import IncorrectIcon from '../../../../../../../../tap-fe-components/src/components/Icons/Incorrect';
import {
    MultipleSelectCheckbox,
    CheckboxControlLabel,
    MultipleSelectControl,
    CheckboxWrapper,
    AnswerWrapper,
    Answerlabel,
    NoGuidanceWrapper,
    TickIconWrapper,
    StyledFormLabel,
    StyledFormGroup,
} from './index.style';
import StringTable from '../../../../../services/stringTable';
import keys from '../../../../../constants/keys';

const showAnswerText = StringTable.getString(keys.questions, keys.common, keys.showAnswer);
const hideAnswerText = StringTable.getString(keys.questions, keys.common, keys.hideAnswer);
const correctAnswerText = StringTable.getString(keys.questions, keys.common, keys.correctAnswer);
const rubricGuidanceText = StringTable.getString(keys.questions, keys.common, keys.rubricGuidanceText);
const noGuidanceOrRubric = StringTable.getString(keys.questions, keys.common, keys.noGuidanceOrRubric);

class MultipleSelectOptions extends React.Component {
    constructor(props) {
        super(props);

        let { response } = props;
        response = response || [];
        const selectedChoices = response.map(option => option.responseText);
        const options = props.choices.map(choice => (
            {
                choice: choice.getAttribute('identifier'),
                checked: selectedChoices.indexOf(choice.getAttribute('identifier')) !== -1,
            }));

        this.state = {
            showAnswer: false,
            options,
        };
        this.handleChange = this.handleChange.bind(this);
        this.getResponseData = props.getResponseData.bind(this);
    }

    getOptionProps = (choice, index) => {
        const optionProps = {};
        const children = [...choice.children];
        children.forEach((child) => {
            if (child.tagName === 'img') {
                optionProps.img = {
                    src: child.getAttribute('src'),
                    alt: child.getAttribute('alt'),
                    width: child.getAttribute('width'),
                    height: child.getAttribute('height'),
                };
            }
        });
        optionProps.text = choice.textContent.trim();
        optionProps.optionNumber = index + 1;
        return optionProps;
    }

    handleClick = () => {
        const { showAnswer } = this.state;
        this.setState({
            showAnswer: !showAnswer,
        });
    };

    handleChange = (event, index) => {
        const key = event.target.name;
        const value = event.target.checked;

        this.setState(prevState => ({
            options: [
                ...prevState.options.slice(0, index),
                {
                    choice: key,
                    checked: value,
                },
                ...prevState.options.slice(index + 1),
            ],
        }), () => {
            const { options } = this.state;
            const { responseIdentifier } = this.props;

            const selectedOptions = options.filter(option => option.checked === true).map(option => ({
                xmlIdentifier: responseIdentifier,
                responseText: option.choice,
            }));
            const isAllEmpty = selectedOptions.every(singleResponse => singleResponse.responseText === '');
            this.getResponseData({
                isAttempted: !isAllEmpty,
                response: selectedOptions,
            });
        });
    };

    showOrHideAnswerText = showAnswer => (!showAnswer ? showAnswerText : hideAnswerText);

    getShowQuestionPreview = (showCorrectAnswer, correctResponse, showGrading, isPreview) => (
        (showCorrectAnswer && correctResponse) || showGrading || (isPreview && !showCorrectAnswer)
    );

    getGuidanceComponent = () => {
        const { isRubric, isAuthorView, isQuestionWrapper } = this.props;
        const guidanceComponent = (isRubric ? (<NoGuidanceWrapper>{rubricGuidanceText}</NoGuidanceWrapper>) :
            (isAuthorView && isQuestionWrapper && <NoGuidanceWrapper>{noGuidanceOrRubric}</NoGuidanceWrapper>));
        return guidanceComponent;
    }

    getMultipleSelectControl = () => {
        const {
            choices, correctOptions, hideShowAnswerButton, showCorrectAnswer,
            showGrading, disable, correctResponse, isNotAttempted,
        } = this.props;
        const { options, showAnswer } = this.state;
        return (
            <MultipleSelectControl>
                <FormControl
                    component="fieldset"
                    disabled={showCorrectAnswer || showGrading || disable}
                >
                    <StyledFormLabel component="legend">​</StyledFormLabel>
                    <StyledFormGroup
                        showCorrectAnswer={showCorrectAnswer}
                    >
                        <CheckboxWrapper>
                            {choices.map((choice, index) => {
                                const optionProps = this.getOptionProps(choice, index);
                                const option = <Option {...optionProps} />;
                                const isCorrect =
                                    correctOptions.indexOf(choice.getAttribute('identifier')) !== -1;
                                const isIncorrect = !isCorrect && options[index].checked;
                                let checkbox = (
                                    <MultipleSelectCheckbox
                                        id={isSafari ? undefined : choice.getAttribute('identifier')}
                                        name={choice.getAttribute('identifier')}
                                        onChange={(event) => { this.handleChange(event, index); }}
                                        checked={options[index].checked}
                                        size="small"
                                    />
                                );

                                if (showAnswer && choice.attributes.identifier in correctOptions) {
                                    checkbox = (
                                        <MultipleSelectCheckbox
                                            checked
                                            name={choice.getAttribute('identifier')}
                                            size="small"
                                        />
                                    );
                                }

                                return (
                                    <AnswerWrapper
                                        key={choice.getAttribute('identifier')}
                                    >
                                        {
                                            ((showCorrectAnswer && correctResponse) ||
                                            (showGrading && correctResponse && !isNotAttempted)) &&
                                                isCorrect ? (
                                                    <TickIconWrapper>
                                                        <CorrectIcon size={32} />
                                                    </TickIconWrapper>
                                                ) : null
                                        }
                                        {
                                            showGrading && correctResponse && isIncorrect && !isNotAttempted ? (
                                                <TickIconWrapper>
                                                    <IncorrectIcon size={32} />
                                                </TickIconWrapper>
                                            ) :
                                                null
                                        }
                                        <CheckboxControlLabel
                                            htmlFor={choice.getAttribute('identifier')}
                                            control={checkbox}
                                            label={option}
                                            showTickSpace={((showCorrectAnswer && correctResponse) ||
                                                (showGrading && correctResponse && !isNotAttempted)) &&
                                                !(isCorrect || isIncorrect)
                                            }
                                        />
                                    </AnswerWrapper>
                                );
                            })}
                        </CheckboxWrapper>
                    </StyledFormGroup>
                </FormControl>
                {!hideShowAnswerButton && (
                    <Button onClick={this.handleClick}>
                        {this.showOrHideAnswerText(showAnswer)}
                    </Button>
                )}
            </MultipleSelectControl>
        );
    }

    render() {
        const {
            showCorrectAnswer, showGrading, userAnswerLabel, correctResponse, isPreview,
            isNotAttempted,
        } = this.props;
        const showQuestionPreview = this.getShowQuestionPreview(
            showCorrectAnswer, correctResponse, showGrading, isPreview,
        );

        return (
            <React.Fragment>
                {
                    showCorrectAnswer && showQuestionPreview ?
                        <Answerlabel notCapitalize noMargin={!correctResponse}>{correctAnswerText}</Answerlabel> :
                        null
                }
                {
                    showGrading ?
                        <Answerlabel noMargin={!correctResponse || isNotAttempted}>{userAnswerLabel}</Answerlabel> :
                        null
                }
                <React.Fragment>
                    {
                        showQuestionPreview ? (
                            this.getMultipleSelectControl()
                        ) :
                            (
                                this.getGuidanceComponent()
                            )
                    }
                </React.Fragment>
            </React.Fragment>

        );
    }
}

MultipleSelectOptions.defaultProps = {
    getResponseData: () => [],
    response: [],
    showCorrectAnswer: false,
    showGrading: false,
    userAnswerLabel: '',
    disable: false,
    correctResponse: false,
};

export default MultipleSelectOptions;

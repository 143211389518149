import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import userManager from './userManager';
import loadUser from './loadUser';

const navigate = (history) => {
    const nextUrl = window.localStorage.getItem('nextUrl');
    history.replace(nextUrl || '/');
};

const LoginCallback = () => {
    const history = useHistory();

    useEffect(() => {
        const callback = async () => {
            try {
                await userManager.signinRedirectCallback();
                await userManager.clearStaleState();
                await loadUser(userManager);
                navigate(history);
            } catch (e) {
                navigate(history);
            }
        };
        callback();
    }, []);

    return <p>Redirecting...</p>;
};

export default LoginCallback;

import { Log } from '@twigeducation/oidc-client-react';
import React, { useEffect } from 'react';
import userManager from './userManager';
import { clearLocalStorage } from '../../helpers/tokenHelper';

const LogoutCallback = () => {
    useEffect(() => {
        const callback = async () => {
            try {
                const a = await userManager.signoutRedirectCallback();
                await userManager.clearStaleState();
                clearLocalStorage();
            } catch (e) {
                Log.logger.error(e);
            } finally {
                window.location.replace('/');
            }
        };
        callback();
    }, []);

    return <p>Redirecting...</p>;
};

export default LogoutCallback;

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import studentUrl from './studentUrl';

const Student = React.lazy(() => import(/* webpackChunkName: "Student" */ './'));

const routes = [
    <Route
        path={studentUrl}
        component={Student}
        key={Student}
    />,
    <Route
        path="/student-assessment/:assignmentId"
        render={props => <Redirect to={`/student/assessment/player/${props.match.params.assignmentId}`} />}
        key="student-player-redirect"
    />,
];


export default routes;

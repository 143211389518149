/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../Tooltip';
import EllipsisDefaultStyle from './EllipsisDefaultStyle.style';

class EllipisWithTooltip extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasOverflowingChildren: false,
            text: undefined,
            isTextOverflowing: false,
            // eslint-disable-next-line react/no-unused-state
            prevPropsChildren: props.children,
        };
        this.updateOverflow = this.updateOverflow.bind(this);
        this.elRef = React.createRef();
    }

    static getDerivedStateFromProps = (props, state) => {
        if (props.children === state.prevPropsChildren) return null;
        return {
            hasOverflowingChildren: false,
            prevPropsChildren: props.children,
        };
    }

    componentDidMount = () => {
        const el = this.elRef.current;
        const { isTextOverflowing } = this.state;
        const { MultilineBlock } = this.props;
        if (el.scrollWidth > el.clientWidth || (MultilineBlock && el.scrollHeight > el.clientHeight)) {
            if (!isTextOverflowing) {
                this.setState({ isTextOverflowing: true });
            }
        }
    }

    updateOverflow = (e) => {
        const el = e.target;
        const { hasOverflowingChildren, text } = this.state;
        const textContent = el.textContent !== text ? el.textContent : text;
        const { MultilineBlock } = this.props;
        if (el.scrollWidth > el.clientWidth || (MultilineBlock && el.scrollHeight > el.clientHeight)) {
            if (!hasOverflowingChildren) {
                this.setState({ text: textContent, hasOverflowingChildren: true });
            }
        } else if (hasOverflowingChildren) {
            this.setState({ hasOverflowingChildren: false });
        }
    }

    render() {
        const { hasOverflowingChildren, isTextOverflowing } = this.state;
        const {
            tooltipProps, children, style, tabIndexProps,
        } = this.props;

        const ellipsisStyle = { ...EllipsisDefaultStyle, ...style };
        const updatedTooltipProps = {
            ...tooltipProps,
            title: typeof tooltipProps.title === 'number' ?
                tooltipProps.title.toLocaleString('fullwide', { useGrouping: false }) :
                tooltipProps.title,
        };

        return (
            <React.Fragment>
                <Tooltip {...updatedTooltipProps} isShowTooltipPresent showTooltip={hasOverflowingChildren}>
                    {
                        React.cloneElement(children,
                            {
                                style: ellipsisStyle,
                                onMouseEnter: this.updateOverflow,
                                onFocus: this.updateOverflow,
                                ...(tabIndexProps && isTextOverflowing ? { tabIndex: 0 } : {}),
                                ref: this.elRef,
                            })
                    }
                </Tooltip>
            </React.Fragment>
        );
    }
}

EllipisWithTooltip.propTypes = {
    children: PropTypes.node.isRequired,
    tooltipProps: PropTypes.object,
    style: PropTypes.object,
    MultilineBlock: PropTypes.bool,
    tabIndexProps: PropTypes.bool,
};

EllipisWithTooltip.defaultProps = {
    tooltipProps: {},
    style: {},
    MultilineBlock: false,
    tabIndexProps: false,
};

export default EllipisWithTooltip;

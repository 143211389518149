import actions from '../actions/actions';

const initialState = {
    list: [],
    updateData: true,
};

const filterChipsReducer = (state = initialState, action = {}) => {
    if (action.type === actions.UPDATE_FILTER_CHIPS) {
        return { ...state, list: action.newFilterData };
    }
    if (action.type === actions.RERENDER_CHIPS) {
        return { ...state, updateData: action.newChipData };
    }
    return state;
};

export default filterChipsReducer;

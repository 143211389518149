import actions from '../actions/actions';

const initialState = {
    selectedStandards: [],
};

const selectedStandardsStateReducer = (state = initialState, action = {}) => {
    if (action.type === actions.UPDATE_SELECTED_STANDARDS) {
        if (state.selectedStandards !== action.selectedStandards) {
            return { selectedStandards: action.selectedStandards };
        }
    }
    return state;
};

export default selectedStandardsStateReducer;

import {
    InMemoryWebStorage, Log, UserManager, WebStorageStateStore,
} from '@twigeducation/oidc-client-react';
import unloadUser from './unloadUser';

const userManager = new UserManager({
    authority: `${window.config.OIDC_PROVIDER_URL}`,
    client_id: window.config.SSO_CLIENT_ID,
    post_logout_redirect_uri: window.config.SSO_LOGOUT_CALLBACK_URL,
    redirect_uri: window.config.SSO_LOGIN_CALLBACK_URL,
    response_type: 'code',
    response_mode: 'query',
    scope: 'openid',
    automaticSilentRenew: true,
    userStore: new WebStorageStateStore({ store: new InMemoryWebStorage() }),
});

export const configureOIDCLogging = (environment) => {
    Log.logger = console;
    if (environment.toLowerCase() === 'production') {
        Log.level = Log.ERROR;
    } else {
        Log.level = Log.DEBUG;
    }
};
configureOIDCLogging('development');

userManager.events.addUserSignedOut(async () => {
    await userManager.clearStaleState();
    await unloadUser();
});

export default userManager;

import { isEqual } from 'lodash';
import actions from '../actions/actions';

const initialState = {
    keyword: '',
    organisations: [],
    organisationsWithoutFilters: [],
    isAllIndeterminateWithoutFilters:  false,
    isAllCheckedWithoutFilters: false,
    overallOrgUserCount: {},
    isAllIndeterminate: false,
    isAllChecked: false,
};

const sharePageFilterDataReducer = (state = initialState, action = {}) => {
    if (action.type === actions.UPDATE_SHARE_PAGE_FILTER_DATA) {
        if (!isEqual(state, action.data)) {
            return { ...state, ...action.data };
        }
    }
    return state;
};

export default sharePageFilterDataReducer;

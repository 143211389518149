import { gql } from '@apollo/client';
import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import { Translation } from '../../../Translation';
import '../scss/ImageUpload.scss';

export const userUploadedImagesQuery = gql`
    query userUploadedImages($keys: [String]!) {
        userUploadedImages(keys: $keys) {
            images {
                key
                url
            }
        }
    }
`;

const acceptedFileTypes = ['image/jpeg', 'image/png', 'image/gif'];
const maxFileSize = 5242880; // 5MB

class ImageUpload extends React.Component {
    constructor(props) {
        super(props);
        this.onCancel = this.onCancel.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.state = {
            accepted: [],
            error: false,
            rejected: [],
        };
    }

    async componentDidMount() {
        const { fabric } = await import('fabric');

        this.setState({
            fabric,
        });
    }

    async onDrop(accepted, rejected) {
        if (rejected.length > 0) {
            this.setState({ rejected });
        } else {
            this.setState({ accepted });
            try {
                await this.handleImageUpload(accepted);
            } catch (e) {
                this.setState({ error: true });
            }
        }
    }

    onCancel() {
        this.setState({
            accepted: [],
            rejected: [],
        });
    }

    async getS3ImageUrls(keys) {
        return this.props.client.query({
            query: userUploadedImagesQuery,
            variables: { keys },
        });
    }

    getRejectedReason(file) {
        if (file.size > maxFileSize) {
            return (
                <Translation
                    translationKey="image_upload.file_too_large_error"
                    defaultValue="{{name}} is too large. The maximum file size is 5MB"
                    options={{ name: file.name }}
                />
            );
        }
        if (acceptedFileTypes.indexOf(file.type) === -1) {
            return (
                <Translation
                    translationKey="image_upload.file_type_not_allowed_error"
                    defaultValue="{{name}} file type is not allowed"
                    options={{ name: file.name }}
                />
            );
        }
        return file.name;
    }

    async uploadImageToS3(url, file, fields) {
        const options = {
            headers: {
                'Content-Type': file.type,
            },
        };

        const data = new FormData();
        Object.keys(fields).forEach((fieldKey) => {
            data.append(fieldKey, fields[fieldKey]);
        });
        data.append('Content-Type', file.type);
        data.append('file', file);

        await axios.post(
            url,
            data,
            options,
        );
    }

    addImageToCanvas(url, offset) {
        const { fabric } = this.state;

        fabric.Image.fromURL(url, (image) => {
            const size = this.props.canvas.width < 800 ? this.props.canvas.width / 2 : 400;
            image.scaleToWidth(size);
            image.set({ top: 50 + offset, left: 50 + offset });
            this.props.canvas.add(image);
            this.props.saveCanvasState();
        });
    }

    async uploadFile(file) {
        const filetype = file.name.split('.').pop();
        const response = await this.props.requestPresignedUploadUrl({ variables: { filetype } });

        const { fields, key, url } = response.data.createPresignedPost;

        await this.uploadImageToS3(url, file, fields);

        return key;
    }

    async handleImageUpload(files) {
        const keys = await Promise.all(
            files.map(async file => this.uploadFile(file)),
        );
        const getS3ImageUrlsResponse = await this.getS3ImageUrls(keys);

        getS3ImageUrlsResponse.data.userUploadedImages.images.forEach((image, index) => {
            this.addImageToCanvas(image.url, index * 50);
        });

        if (getS3ImageUrlsResponse.data.userUploadedImages.images.length > 0) {
            this.props.onUpload();
        }
    }

    render() {
        if (this.state.error) {
            return (
                <div>
                    <Translation
                        translationKey="image_upload.generic_error"
                        defaultValue="Sorry, an error occurred during uploading an image"
                    />
                </div>
            );
        }

        return (
            <div>
                {
                    this.state.accepted.length > 0 ? (
                        <div>
                            <h4>
                                <Translation
                                    translationKey="image_upload.upload_in_progress"
                                    defaultValue="Uploading files. Please wait..."
                                />
                            </h4>
                            <ul>
                                {
                                    this.state.accepted.map(f => (
                                        <li key={f.name}>{f.name} - {Math.floor(f.size / 1000)}KB</li>
                                    ))
                                }
                            </ul>
                        </div>
                    ) : (
                        <Dropzone
                            accept={acceptedFileTypes.join(', ')}
                            activeClassName="image-upload__dropzone--active"
                            maxSize={maxFileSize}
                            onCancel={this.onCancel}
                            onDrop={this.onDrop}
                            className="image-upload__dropzone"
                        >
                            <div>
                                <p>
                                    <Translation
                                        translationKey="image_upload.help_text"
                                        defaultValue="Upload, or drag and drop, an image from your device."
                                    />
                                </p>
                                <p>
                                    <small>
                                        <Translation
                                            translationKey="image_upload.supported_formats"
                                            defaultValue="Only JPEG, PNG and GIF file formats will be accepted."
                                        />
                                    </small>
                                    <br />
                                    <small>
                                        <Translation
                                            translationKey="image_upload.max_file_size"
                                            defaultValue="Maximum file size - 5MB"
                                        />
                                    </small>
                                </p>

                                <button className="button">
                                    <Translation
                                        translationKey="image_upload.select_image"
                                        defaultValue="Select image"
                                    />
                                </button>
                            </div>
                        </Dropzone>
                    )}

                {
                    this.state.rejected.length > 0 && (
                        <div className="image-upload__error">
                            <p>
                                <Translation
                                    translationKey="image_upload.these_files_were_rejected"
                                    defaultValue="The following files were rejected"
                                />
                            </p>
                            <ul>
                                {
                                    this.state.rejected.map(f => (
                                        <li key={f.name}>{this.getRejectedReason(f)}</li>
                                    ))
                                }
                            </ul>
                        </div>
                    )}
            </div>
        );
    }
}

ImageUpload.propTypes = {
    canvas: PropTypes.shape({
        add: PropTypes.func.isRequired,
        width: PropTypes.number.isRequired,
    }).isRequired,
    client: PropTypes.shape({
        query: PropTypes.func.isRequired,
    }).isRequired,
    onUpload: PropTypes.func.isRequired,
    requestPresignedUploadUrl: PropTypes.func.isRequired,
    saveCanvasState: PropTypes.func.isRequired,
};

export default ImageUpload;

import { useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
    setUserId,
    setDistrictId,
    setUserRole, setUserRoleId,
    setOrganisationId,
    setUserName, setUserToken,
    setSessionId, setDate, setIsLTIUser,
    setIsLTIDeeplinkPreview, setTokenExpiry,
} from '../../../helpers/tokenHelper';
import { setInMemory } from '../InMemoryStorage';
import { pendoInitialize } from '../../../helpers/pendoHelper';

const navigate = (history, assignmentId, assessmentId, assessmentFile, role) => {
    if (assessmentFile) {
        window.open(assessmentFile, '_self');
    } else if (role === 'STUDENT') {
        history.replace(`student/assessment/player/${assignmentId}`);
    } else {
        history.replace(`/teacher/assessment/preview/${assessmentId}/assignment/${assignmentId}`);
    }
};

const LTILogin = (props) => {
    useEffect(() => {
        const callback = async () => {
            const tokenInput = { token: props.token };
            const response = await axios.post(`${window.config.AUTHENTICATION_URL}/lti/login`, tokenInput);
            const {
                headers,
                data: {
                    roleId, assessmentId, consumerReturnUrl, assessmentFile, isDeeplinkPreview,
                    assignmentId, districtId, organisationId, id: userId, role: userRole,
                    givenName: firstName, familyName: lastName, exp,
                },
            } = response;
            setInMemory('x-auth-token', headers['x-auth-token']);
            setInMemory('refresh-token', headers['refresh-token']);
            setUserName(firstName, lastName);
            setUserId(userId);
            setUserRoleId(roleId);
            setDistrictId(districtId);
            setUserRole(userRole);
            setOrganisationId(organisationId);
            setSessionId(-1);
            setDate(moment.utc().format('YYYY-MM-DD'));
            setUserToken(true);
            setIsLTIUser(true);
            localStorage.setItem('consumerReturnUrl', consumerReturnUrl);
            setIsLTIDeeplinkPreview(isDeeplinkPreview);
            setTokenExpiry(exp);
            pendoInitialize({
                ...response.data,
                district_id: districtId,
            });
            navigate(props.history, assignmentId, assessmentId, assessmentFile, userRole);
        };
        callback();
    }, []);
    return 'Redirecting...';
};

LTILogin.defaultProps = {
    token: '',
};

LTILogin.propTypes = {
    token: PropTypes.string,
};

export default LTILogin;

import React from 'react';
import { Route } from 'react-router-dom';
import cmsUrl from './CMSUrl';

const CMS = React.lazy(() => import(/* webpackChunkName: "CMS" */ './'));

const routes = [
    <Route path={cmsUrl} component={CMS} key={CMS} />,
];


export default routes;

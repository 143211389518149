/* eslint-disable import/prefer-default-export */
import { withStyles, IconButton as MaterialIconButton } from '@material-ui/core';

import theme from '../../theme';

export const StyledIconButton = withStyles({
    root: {
        padding: 2,
        width: 28,
        height: 28,
        borderRadius: '50%',
        color: theme.accentColor,
        '&:disabled': {
            opacity: '50%',
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        '&:hover:enabled': {
            backgroundColor: `${theme.accentColorHoverLight} !important`,
        },
        '&:focus': {
            backgroundColor: `${theme.accentColorHoverLight}`,
            color: `${theme.accentColor} !important`,
            outlineOffset: 4,
        },
        '&:focus:enabled': {
            backgroundColor: 'transparent',
        },
        '&:active:enabled': {
            backgroundColor: `${theme.accentColorActiveLight} !important`,
            border: `1px solid ${theme.accentColorActive} !important`,
            color: `${theme.accentColorActive} !important`,
        },
    },
})(MaterialIconButton);

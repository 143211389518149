/* eslint-disable react/prop-types */
/* eslint-disable react/no-danger */
import React from 'react';
import MultipleSelectOptions from './components/MultipleSelectOptions';
import SingleSelectOptions from './components/SingleSelectOptions';
import QuestionText from '../QuestionText';

class MultipleChoiceQuestion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    getShowQuestionPreview = (showCorrectAnswer, correctResponse, showGrading, isPreview) => (
        (showCorrectAnswer && correctResponse) || showGrading || (isPreview && !showCorrectAnswer)
    );

    render() {
        const {
            multiple,
            choices,
            correctOptions,
            questionHTML,
            getResponseData,
            responseIdentifier,
            response,
            showCorrectAnswer,
            showGrading,
            userAnswerLabel,
            disable,
            isAutoScored,
            isPreview,
            correctResponse,
            isTtsEnabled,
            isNotAttempted,
            isAnswerPreviewView,
            questionpartPrefix,
            isRubric,
            isAuthorView,
            isQuestionWrapper,
        } = this.props;
        const mcqProps = {
            choices,
            correctOptions,
            hideShowAnswerButton: true,
            getResponseData,
            responseIdentifier,
            response,
            showCorrectAnswer,
            showGrading,
            userAnswerLabel,
            disable,
            isAutoScored,
            isPreview,
            correctResponse,
            isNotAttempted,
            isRubric,
            isAuthorView,
            isQuestionWrapper,
        };
        const showQuestionPreview = this.getShowQuestionPreview(
            showCorrectAnswer, correctResponse, showGrading, isPreview,
        );
        let isBottomPadding = true;
        let isExtraTag = false;
        if (showCorrectAnswer) {
            isBottomPadding = showQuestionPreview || isRubric || (isAuthorView && isQuestionWrapper);
            isExtraTag = !multiple && !isBottomPadding;
        }
        return (
            <React.Fragment>
                <div>
                    {questionHTML && !isAnswerPreviewView && (
                        <QuestionText
                            questionHTML={questionHTML}
                            isTtsEnabled={isTtsEnabled}
                            questionpartPrefix={questionpartPrefix}
                            isBottomPadding={isBottomPadding}
                            isExtraTag={isExtraTag}
                        />
                    )}
                    {multiple ?
                        <MultipleSelectOptions {...mcqProps} /> :
                        <SingleSelectOptions {...mcqProps} />
                    }
                </div>

            </React.Fragment>
        );
    }
}

export default MultipleChoiceQuestion;

import React from 'react';
import { Route } from 'react-router-dom';
import querystringify from 'querystringify';
import LoginCallback from './LoginCallback';
import LogoutCallback from './LogoutCallback';
import RedirectToSSOLogin from './RedirectToSSOLogin';
import { getQueryParams } from '../../helpers/routeHelper';
import LTILogin from './LTILogin';
import ssoLoginUrl, {
    ssoLogoutCallback,
    ssoLoginCallback,
    ltiLoginUrl,
} from './urls';


const loginCallbackRoute = (
    <Route
        path={ssoLoginCallback}
        exact
        key="login-callback"
    >
        <LoginCallback />
    </Route>
);

const loginRoute = (
    <Route
        path={ssoLoginUrl}
        key="login"
        component={(props) => {
            const querystring = querystringify.parse(props.location.search);
            const nextUrl = querystring.next || '/';
            return <RedirectToSSOLogin nextUrl={nextUrl} />;
        }}
    />
);

const logoutCallbackRoute = (
    <Route
        path={ssoLogoutCallback}
        exact
        key="logout-callback"
    >
        <LogoutCallback />
    </Route>
);

const ltiLoginRoute = (
    <Route
        path={ltiLoginUrl}
        key="lti-login"
        component={(props) => {
            const { token } = getQueryParams(props.history);
            return <LTILogin token={token} history={props.history} />;
        }}
    />
);

export {
    loginCallbackRoute,
    logoutCallbackRoute,
    loginRoute,
    ltiLoginRoute,
};

import styled from 'styled-components';
import theme from '../../../../../../tap-fe-components/src/theme';

const EditableWrapper = styled.div`
    margin-top: 10px;
    max-width: 700px;
`;

const TextBoxWrapper = styled.div`
    margin-top: 30px;
    display:flex;
    align-items: center;

    &.correct-answer {
        margin-top: 0px;
    }
    label {
        left: ${props => props.prefixWidth}px;
        right: ${props => (props.multilineCustomLabel ? '28px' : '')};
    }
`;

const CorrectAnswerWrapper = styled.div`
`;

const NoGuidanceWrapper = styled.div`
    color: #332A7C;
`;

const Answerlabel = styled.div`
    color: #5D618E;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    margin-bottom: 16px;

    &.correct-answer-label {
        margin-left: 48px;
        margin-top: 32px;
    }
`;

const ControlWrapper = styled.div`
    width: ${props => (props.showTickSpace ? 'calc(100% - 42px)' : '100%')};
    margin-left: ${props => (props.showTickSpace ? '42px' : '0px')};
`;
const TickIconWrapper = styled.div`
    margin-left: auto;
    align-self: center;
    padding: 8px;
    padding-left: 0;
    padding-right: 16px;
    margin-top: 24px;
`;
const TickWrapper = styled.div`
    margin-left: auto;
    align-self: center;
    padding: 8px;
    padding-left: 0;
    padding-right: 16px;
`;

const CharacterCountWrapper = styled.div`
    background-color: rgba(194, 195, 212, 0.2);
    border-radius: 4px;
    text-align: center;
    padding: 10px 12px;
    color: ${theme.characterCountColor};
`;

const ErrorBox = styled.div`
    background-color: rgba(240, 96, 96, 0.2);
    border-radius: 4px;
    text-align: center;
    padding: 10px 12px;
    margin-right: 6px;
`;

const CharacterViewWrapper = styled.div`
    margin-left: auto;
    display: flex;
`;

const TtsScriptWrapper = styled.div`
     display: none;
`;

const AnswerPreviewWrapper = styled.div`
    width: 192px;
    padding: 14px;
    background: #EFEFEF;
    border: 2px solid #C2C3D4;
    border-radius: 4px;
    max-height: 174px;
    min-height: ${props => (props.multiline ? '110px' : '')};
 `;

const DataTextView = styled.div`
    color: #332A7C;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    line-height: 19px;
`;

const AndormentView = styled.span`
    color: ${theme.textDefault};
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
`;

const AnswerShortView = styled.span`
    font-weight: 700;
    margin: 0 4px;
`;

export {
    EditableWrapper,
    TextBoxWrapper,
    NoGuidanceWrapper,
    CorrectAnswerWrapper,
    Answerlabel,
    ControlWrapper,
    TickIconWrapper,
    TickWrapper,
    CharacterCountWrapper,
    ErrorBox,
    CharacterViewWrapper,
    TtsScriptWrapper,
    AnswerPreviewWrapper,
    DataTextView,
    AndormentView,
    AnswerShortView,
};

import {
    allowedHtmlTags,
    NodeTypeValues,
} from '../xmlConversion.enum';

const getFormattedAttributes = (attributes) => {
    const attributesList = {};
    if (attributes.length > 0) {
        for (let j = 0; j < attributes.length; j += 1) {
            const attribute = attributes.item(j);
            attributesList[attribute.nodeName] = attribute.nodeValue;
        }
    }
    return attributesList;
};

const checkIfEmpty = str => str === '';

const convertXMLToData = (xml, identifier) => {
    const children = [...xml.children];
    // base case for recursion for normal Text.
    if (!children.length) {
        if (checkIfEmpty(xml.innerHTML)) return [];
        return [{
            text: xml.innerHTML,
            type: NodeTypeValues.text,
        }];
    }

    // base case for recursion for html Tags to not get split.
    if (children?.every(child => allowedHtmlTags.includes(child.nodeName))) {
        if (checkIfEmpty(xml.innerHTML)) return [];
        return [{
            cdata: xml.innerHTML,
            type: NodeTypeValues.cData,
        }];
    }

    // initializing object to be returned.
    const elements = [];

    for (const child of children) {
        const attributes = getFormattedAttributes(child.attributes);
        const newElements = convertXMLToData(child, identifier);

        // const isCustomTag = checkChildrensToBeHTML(child);
        // console.log('HTML Present', child.nodeName, isCustomTag);
        // format of every node to contain tagname, type (element, text ..), attributes, elements(childrens)
        const node = {
            ...(attributes && Object.keys(attributes)?.length > 0 && { attributes }),
            ...(newElements && newElements?.length > 0 && { elements: newElements }),
            name: child.nodeName,
            type: NodeTypeValues.element,
        };
        elements.push(node);
    }

    return elements;
};

export default convertXMLToData;

import axios from 'axios';
import moment from 'moment';
import {
    setUserId,
    setDistrictId,
    setUserRole, setUserRoleId,
    setOrganisationId,
    setUserName,
    setSessionId, setDate, setTokenExpiry,
} from '../../helpers/tokenHelper';
import ssoLoginUrl from './urls';
import { pendoInitialize } from '../../helpers/pendoHelper';

const verifyUser = async (user) => {
    const authenticationUrl = `${window.config.AUTHENTICATION_URL}/validate/twigscience/user`;
    try {
        await axios({
            method: 'post',
            url: authenticationUrl,
            data: user,
            withCredentials: true,
        }).then((response) => {
            if (response.status === 200) {
                const {
                    data: {
                        sessionId, roleId, id: userId, district_id: districtId, role: userRole,
                        organisation_id: organisationId, givenName: firstName, familyName: lastName,
                        exp,
                    },
                } = response;
                setUserName(firstName, lastName);
                setUserId(userId);
                setUserRoleId(roleId);
                setDistrictId(districtId);
                setUserRole(userRole);
                setOrganisationId(organisationId);
                setSessionId(sessionId);
                setDate(moment.utc().format('YYYY-MM-DD'));
                setTokenExpiry(exp);
                pendoInitialize(response.data);
                return true;
            }
            return false;
        });
    } catch (error) { /* Do nothing. */ }

    return false;
};

const loadUser = async (userManager) => {
    try {
        const user = await userManager.getUser();
        if (user) {
            const userVerified = await verifyUser(user);
            if (userVerified) return user;
        } else {
            return window.location.assign(`${ssoLoginUrl}?next=${window.location.pathname}`);
        }
        return false;
    } catch (error) {
        return false;
    }
};

export default loadUser;

import actions from '../actions/actions';

const initialState = {
    title: '',
    showBack: false,
    showBreadcrumb: false,
    list: [],
    titleComponent: null,
};


const headerStateReducer = (state = initialState, action = {}) => {
    if (action.type === actions.CHANGE_HEADER) {
        if (action.newHeaderData?.titleComponent) {
            return { ...action.newHeaderData, title: '' };
        }
        if (action.newHeaderData?.title) {
            return { ...action.newHeaderData, titleComponent: null };
        }
        return { ...action.newHeaderData };
    }
    return state;
};

export default headerStateReducer;

import colors from './colors';
import fillIcon from './icons/fill.svg';
import fillNoneIcon from './icons/fill-none.svg';
import strokeIcon from './icons/stroke.svg';
import strokeNoneIcon from './icons/stroke-none.svg';
import strokeWidth1Icon from './icons/stroke-width-1.svg';
import strokeWidth2Icon from './icons/stroke-width-2.svg';
import strokeWidth3Icon from './icons/stroke-width-3.svg';
import strokeWidth4Icon from './icons/stroke-width-4.svg';
import strokeWidth5Icon from './icons/stroke-width-5.svg';
import strokeWidth6Icon from './icons/stroke-width-6.svg';
import textIcon from './icons/text.svg';

const fillOptions = [
    {
        defaultText: 'Transparent',
        translationKey: 'general.colors_transparent',
        icon: fillNoneIcon,
        useIcon: true,
        value: 'rgba(0, 0, 0, 0)',
    },
    ...colors.map(color => ({
        defaultText: color.defaultText,
        translationKey: color.translationKey,
        icon: fillIcon,
        useIcon: false,
        value: color.value,
    })),
];

const stampOptions = stamps => stamps.filter(Boolean).map(stamp => (
    {
        defaultText: stamp,
        translationKey: stamp,
        icon: stamp,
        useIcon: true,
        value: stamp,
        scale: 2,
    }
));

const strokeOptions = [
    {
        defaultText: 'Transparent',
        translationKey: 'general.colors_transparent',
        icon: strokeNoneIcon,
        useIcon: true,
        value: 'rgba(0, 0, 0, 0)',
    },
    ...colors.map(color => ({
        defaultText: color.defaultText,
        translationKey: color.translationKey,
        icon: strokeIcon,
        useIcon: false,
        value: color.value,
    })),
];

const strokeWidthOptions = [
    {
        defaultText: '1',
        translationKey: 'general.stroke_1',
        icon: strokeWidth1Icon,
        useIcon: true,
        value: 1,
    },
    {
        defaultText: '2',
        translationKey: 'general.stroke_2',
        icon: strokeWidth2Icon,
        useIcon: true,
        value: 2,
    },
    {
        defaultText: '3',
        translationKey: 'general.stroke_3',
        icon: strokeWidth3Icon,
        useIcon: true,
        value: 5,
    },
    {
        defaultText: '4',
        translationKey: 'general.stroke_4',
        icon: strokeWidth4Icon,
        useIcon: true,
        value: 10,
    },
    {
        defaultText: '5',
        translationKey: 'general.stroke_5',
        icon: strokeWidth5Icon,
        useIcon: true,
        value: 15,
    },
    {
        defaultText: '6',
        translationKey: 'general.stroke_6',
        icon: strokeWidth6Icon,
        useIcon: true,
        value: 20,
    },
];

const textOptions = [
    {
        defaultText: 'text size 1',
        translationKey: 'general.text_1',
        icon: textIcon,
        useIcon: true,
        scale: 0.5,
        value: 20,
    },
    {
        defaultText: 'text size 2',
        translationKey: 'general.text_2',
        icon: textIcon,
        useIcon: true,
        scale: 0.6,
        value: 30,
    },
    {
        defaultText: 'text size 3',
        translationKey: 'general.text_3',
        icon: textIcon,
        useIcon: true,
        scale: 0.75,
        value: 40,
    },
    {
        defaultText: 'text size 4',
        translationKey: 'general.text_4',
        icon: textIcon,
        useIcon: true,
        scale: 0.85,
        value: 50,
    },
    {
        defaultText: 'text size 5',
        translationKey: 'general.text_5',
        icon: textIcon,
        useIcon: true,
        scale: 1,
        value: 60,
    },
];

export {
    fillOptions,
    stampOptions,
    strokeOptions,
    strokeWidthOptions,
    textOptions,
};

import actions from '../actions/actions';

const initialState = {
    showSnackBar: false,
};

const showSnackBarOnSaveReducer = (state = initialState, action = {}) => {
    if (action.type === actions.SHOW_SNACKBAR) {
        if (state.showSnackBar !== action.showSnackBar) {
            return { showSnackBar: action.showSnackBar };
        }
    }
    return state;
};

export default showSnackBarOnSaveReducer;

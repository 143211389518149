import actions from '../actions/actions';

const initialState = {
    isPageFormDirty: false,
    logoutCallback: () => { /* */ },
    showConfirmationPopup: () => { /* */ },
};

const logoutCallbackReducer = (state = initialState, action = {}) => {
    if (action.type === actions.UPDATE_IS_PAGE_FORM_DIRTY) {
        return { ...state, isPageFormDirty: action.isPageFormDirty };
    }
    if (action.type === actions.SET_LOGOUT_CALLBACK) {
        return {
            ...state,
            logoutCallback: action.logoutCallback,
        };
    }
    if (action.type === actions.SET_SHOW_CONFIRMATION_POPUP) {
        return {
            ...state,
            showConfirmationPopup: action.showConfirmationPopup,
        };
    }
    return state;
};

export default logoutCallbackReducer;

import actions from '../actions/actions';

const initialState = {
    authToken: null,
};

const tokenStateReducer = (state = initialState, action = {}) => {
    if (action.type === actions.SET_AUTH_TOKEN) {
        return {
            ...state,
            authToken: action.authToken,
        };
    }
    return state;
};

export default tokenStateReducer;

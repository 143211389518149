const focusHandler = () => {
    const KEY_CODES = [38, 39, 40, 37, 9, 13, 32, 27];

    const onKeyDown = (event) => {
        if (KEY_CODES.indexOf(event.keyCode) !== -1) {
            document.body.classList.add('show-focus');
        }
    };

    const onMouseDown = () => {
        document.body.classList.remove('show-focus');
    };

    const attachListeners = () => {
        document.body.addEventListener('keydown', onKeyDown);
        document.body.addEventListener('mousedown', onMouseDown);
    };

    const removeListeners = () => {
        document.body.removeEventListener('keydown', onKeyDown);
        document.body.removeEventListener('mousedown', onMouseDown);
    };

    return {
        attachListeners,
        removeListeners,
    };
};

export default focusHandler;

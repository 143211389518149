const styles = () => ({
    tooltip: {
        fontSize: 14,
        fontWeight: 400,
    },
    hideTooltip: {
        visibility: 'collapse',
    },
    tooltipWidthFalse: {
        maxWidth: 'calc(100% - 64px)',
    },
    tooltipWidthSm: {
        maxWidth: 200,
    },
    tooltipWidthMd: {
        maxWidth: 400,
    },
    capitalizeText: {
        textTransform: 'capitalize',
    },
});

export default styles;

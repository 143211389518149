const CHANGE_HEADER = 'CHANGE_HEADER';
const CHANGE_STATUS = 'CHANGE_STATUS';
const UPDATE_SELECTED_STANDARDS = 'UPDATE_SELECTED_STANDARDS';
const UPDATE_FILTER_CHIPS = 'UPDATE_FILTER_CHIPS';
const UPDATE_FILTER_CHECKBOXES = 'UPDATE_FILTER_CHECKBOXES';
const CHANGE_TAB = 'CHANGE_TAB';
const UPDATE_STANDARDS_IN_QUESTION = 'UPDATE_STANDARDS_IN_QUESTION';
const MAINTAIN_PREVIOUS_CHECKBOX_STATE = 'MAINTAIN_CHECKBOX_STATE';
const RERENDER_CHIPS = 'RERENDER_CHIPS';
const PRINT_REPORT = 'PRINT_REPORT';
const SHOW_LOADER = 'SHOW_LOADER';
const HIDE_LOADER = 'HIDE_LOADER';
const UPDATE_VIEW_MODE = 'UPDATE_VIEW_MODE';
const UPDATE_DIRTY_STATE = 'UPDATE_DIRTY_STATE';
const UPDATE_EDIT_MODE_STATE = 'UPDATE_EDIT_MODE_STATE';
const UPDATE_ACCEPT_DIALOG_STATE = 'UPDATE_ACCEPT_DIALOG_STATE';
const UPDATE_RESET_STATE = 'UPDATE_RESET_STATE';
const UPDATE_APPLIED_CHANGES = 'UPDATE_APPLIED_CHANGES';
const UPDATE_TTS_LANGUAGE_DATA = 'UPDATE_TTS_LANGUAGE_DATA';
const SHOW_SNACKBAR = 'SHOW_SNACKBAR';
const CHANGE_NAVIGATION_FUNCTION = 'CHANGE_NAVIGATION_FUNCTION';
const SET_LOGOUT_CALLBACK = 'SET_LOGOUT_CALLBACK';
const SET_SHOW_CONFIRMATION_POPUP = 'SET_SHOW_CONFIRMATION_POPUP';
const UPDATE_IS_PAGE_FORM_DIRTY = 'UPDATE_IS_PAGE_FORM_DIRTY';
const UPDATE_SHARE_PAGE_FILTER_DATA = 'UPDATE_SHARE_PAGE_FILTER_DATA';
const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';

const changeHeader = headerData => ({
    type: CHANGE_HEADER,
    newHeaderData: headerData,
});

const changeStatus = loaderData => ({
    type: CHANGE_STATUS,
    newStatusData: loaderData,
});

const updateSelectedStandards = selectedStandards => ({
    type: UPDATE_SELECTED_STANDARDS,
    selectedStandards,
});

const updateFilterChips = filterData => ({
    type: UPDATE_FILTER_CHIPS,
    newFilterData: filterData,
});

const reRenderChips = chipData => ({
    type: RERENDER_CHIPS,
    newChipData: chipData,
});

const updateCheckBoxes = checkBoxData => ({
    type: UPDATE_FILTER_CHECKBOXES,
    newCheckBoxData: checkBoxData,
});

const updateState = selectedData => ({
    type: CHANGE_TAB,
    newSelectedData: selectedData,
});

const updateStandardsInQuestion = standardsInQuestion => ({
    type: UPDATE_STANDARDS_IN_QUESTION,
    standardsInQuestion,
});

const maintainPreviousCheckboxState = checkBoxState => ({
    type: MAINTAIN_PREVIOUS_CHECKBOX_STATE,
    newCheckBoxState: checkBoxState,
});

const printReport = isPrinting => ({
    type: PRINT_REPORT,
    isPrinting,
});

const showLoader = loader => ({
    type: SHOW_LOADER,
    loader,
});

const hideLoader = loader => ({
    type: HIDE_LOADER,
    loader,
});

const updateViewModeData = data => ({
    type: UPDATE_VIEW_MODE,
    newViewModeData: data,
});

const updateDirtyState = isDirty => ({
    type: UPDATE_DIRTY_STATE,
    isDirty,
});

const updateEditModeState = isEdit => ({
    type: UPDATE_EDIT_MODE_STATE,
    isEditOpen: isEdit,
});

const updateAcceptDialogState = data => ({
    type: UPDATE_ACCEPT_DIALOG_STATE,
    showAcceptDialog: data,
});

const updateResetState = isReset => ({
    type: UPDATE_RESET_STATE,
    isResetCalled: isReset,
});

const updateAppliedChangesState = appliedData => ({
    type: UPDATE_APPLIED_CHANGES,
    appliedChanges: appliedData,
});

const updateTtsLanguageData = data => ({
    type: UPDATE_TTS_LANGUAGE_DATA,
    ttsLanguageData: data,
});

const showSnackBar = data => ({
    type: SHOW_SNACKBAR,
    showSnackBar: data,
});

const updateNavigationFunction = selectedData => ({
    type: CHANGE_NAVIGATION_FUNCTION,
    navigationFunction: selectedData,
});

const setLogoutCallback = logoutCallback => ({
    type: SET_LOGOUT_CALLBACK,
    logoutCallback,
});

const setShowConfirmationPopup = showConfirmationPopup => ({
    type: SET_SHOW_CONFIRMATION_POPUP,
    showConfirmationPopup,
});

const updateIsPageFormDirty = isPageFormDirty => ({
    type: UPDATE_IS_PAGE_FORM_DIRTY,
    isPageFormDirty,
});

const updateSharePageFilterData = data => ({
    type: UPDATE_SHARE_PAGE_FILTER_DATA,
    data,
});

const setAuthToken = token => ({
    type: SET_AUTH_TOKEN,
    authToken: token,
});

export default {
    CHANGE_HEADER,
    UPDATE_FILTER_CHIPS,
    UPDATE_FILTER_CHECKBOXES,
    UPDATE_SELECTED_STANDARDS,
    UPDATE_STANDARDS_IN_QUESTION,
    CHANGE_STATUS,
    CHANGE_TAB,
    MAINTAIN_PREVIOUS_CHECKBOX_STATE,
    RERENDER_CHIPS,
    PRINT_REPORT,
    SHOW_LOADER,
    HIDE_LOADER,
    UPDATE_VIEW_MODE,
    UPDATE_DIRTY_STATE,
    UPDATE_EDIT_MODE_STATE,
    UPDATE_ACCEPT_DIALOG_STATE,
    UPDATE_RESET_STATE,
    UPDATE_APPLIED_CHANGES,
    UPDATE_TTS_LANGUAGE_DATA,
    SHOW_SNACKBAR,
    CHANGE_NAVIGATION_FUNCTION,
    SET_LOGOUT_CALLBACK,
    SET_SHOW_CONFIRMATION_POPUP,
    UPDATE_IS_PAGE_FORM_DIRTY,
    UPDATE_SHARE_PAGE_FILTER_DATA,
    SET_AUTH_TOKEN,
    changeHeader,
    changeStatus,
    updateFilterChips,
    updateCheckBoxes,
    updateSelectedStandards,
    updateState,
    updateStandardsInQuestion,
    maintainPreviousCheckboxState,
    reRenderChips,
    printReport,
    showLoader,
    hideLoader,
    updateViewModeData,
    updateDirtyState,
    updateEditModeState,
    updateAcceptDialogState,
    updateResetState,
    updateAppliedChangesState,
    updateTtsLanguageData,
    showSnackBar,
    updateNavigationFunction,
    setLogoutCallback,
    setShowConfirmationPopup,
    updateIsPageFormDirty,
    updateSharePageFilterData,
    setAuthToken,
};

import {
    ApolloClient,
    InMemoryCache,
    defaultDataIdFromObject,
    ApolloLink,
    from as compileLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink as CreateUploadLink } from 'apollo-upload-client';
import HttpService from './HttpService';
import possibleTypes from '../../../../possibleTypes.json';
import refreshAccessTokenMiddleware from '../../apolloClient/middleware/refreshAccessToken';
import { getIsLTIUser } from '../../helpers/tokenHelper';
import { getInMemory } from '../../apps/Authentication/InMemoryStorage';

const graphqlUrl = `${window.config.GRAPHQL_URL}/graphql/`;

const httpLink = new CreateUploadLink({
    uri: graphqlUrl,
    fetch: HttpService.fetch,
    credentials: 'include',
});

const prepareHeadersLink = new ApolloLink((operation, forward) => {
    const headers = {};

    if (getIsLTIUser()) {
        headers.Authorization = `${getInMemory('x-auth-token')}`;
    }

    operation.setContext({
        headers,
    });
    return forward(operation);
});

const link = compileLink([
    setContext(refreshAccessTokenMiddleware),
    prepareHeadersLink,
    httpLink,
]);

const client = new ApolloClient({
    link,
    cache: new InMemoryCache({
        possibleTypes,
        dataIdFromObject: (object) => {
            switch (object.__typename) {
            case 'Question':
                return `${object.collectionId}:${object.id}:${object.version}`;
            case 'Rubric':
                return `${object.collectionId}:${object.id}:${object.version}`;
            case 'PlayerStudentQuestionPart':
                return `${object.questionGroupId}:${object.questionId}:${object.version}:${object.id}`;
            case 'PlayerStudentQuestion':
                return `${object.questionGroupId}:${object.id}:${object.version}`;
            case 'QuestionPartGrading':
                return `${object.questionGroupId}:${object.questionId}:${object.version}:${object.id}`;
            case 'QuestionGrading':
                return `${object.questionGroupId}:${object.id}:${object.version}`;
            case 'RubricGrading':
                return `${object.id}:${object.version}`;
            case 'StandardMasteryClasses':
                return `${object.id}:${object.classId}`;
            case 'StandardMasteryAverageScoreStudent':
                return `${object.id}:${object.studentId}`;
            case 'StandardMasteryAverageScoreAssignment':
                return `${object.id}:${object.assignmentId}`;
            default:
                return defaultDataIdFromObject(object);
            }
        },
    }),
});

export default client;

import actions from '../actions/actions';

const initialState = {
    isDirty: false,
    isEditOpen: 0,
    showAcceptDialog: false,
    isResetCalled: false,
    appliedChanges: [],
};

const editAssessmentDirtyReducer = (state = initialState, action = {}) => {
    if (action.type === actions.UPDATE_DIRTY_STATE) {
        return { ...state, isDirty: action.isDirty };
    }
    if (action.type === actions.UPDATE_EDIT_MODE_STATE) {
        return { ...state, isEditOpen: action.isEditOpen };
    }
    if (action.type === actions.UPDATE_ACCEPT_DIALOG_STATE) {
        return { ...state, showAcceptDialog: action.showAcceptDialog };
    }
    if (action.type === actions.UPDATE_RESET_STATE) {
        return { ...state, isResetCalled: action.isResetCalled };
    }
    if (action.type === actions.UPDATE_APPLIED_CHANGES) {
        return { ...state, appliedChanges: action.appliedChanges };
    }
    return state;
};

export default editAssessmentDirtyReducer;

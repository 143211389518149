export const getUserId = () => localStorage.getItem('userId');
export const getDistrictId = () => localStorage.getItem('districtId');
export const getUserRole = () => localStorage.getItem('userRole');
export const getUserRoleId = () => localStorage.getItem('roleId');
export const getOrganisationId = () => localStorage.getItem('organisationId');
export const getdate = () => localStorage.getItem('date');
export const getIsLTIUser = () => {
    const isLtiUser = localStorage.getItem('isLTIUser');
    return isLtiUser === 'true';
};
export const getIsDeeplinkPreview = () => {
    const val = localStorage.getItem('isDeeplinkPreview');
    return val === 'true';
};

export const setUserId = (userId) => {
    localStorage.setItem('userId', userId);
};
export const setDistrictId = (districtId) => {
    localStorage.setItem('districtId', districtId);
};
export const setUserRole = (userRole) => {
    localStorage.setItem('userRole', userRole);
};
export const setOrganisationId = (organisationId) => {
    localStorage.setItem('organisationId', organisationId);
};
export const setSessionId = (sessionId) => {
    localStorage.setItem('sessionId', sessionId);
};
export const setUserRoleId = (roleId) => {
    localStorage.setItem('roleId', roleId);
};
export const setDate = (date) => {
    localStorage.setItem('date', date);
};
export const setIsLTIUser = (value) => {
    localStorage.setItem('isLTIUser', value);
};
export const setIsLTIDeeplinkPreview = (value) => {
    localStorage.setItem('isDeeplinkPreview', value);
};

export const deleteUserId = () => {
    localStorage.removeItem('userId');
};
export const deleteDistrictId = () => {
    localStorage.removeItem('districtId');
};
export const deleteUserRole = () => {
    localStorage.removeItem('userRole');
};
export const deleteOrganisationId = () => {
    localStorage.removeItem('organisationId');
};

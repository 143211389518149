/* eslint-disable import/prefer-default-export  */
import { useTranslation as useTranslationNext } from 'react-i18next';
import { name } from '../package.json';

export const useTranslation = (ns = null, props = {}) => {
    const { t } = useTranslationNext(ns, props);
    return {
        t: (translationKey, defaultValue, options) => t(`${name}.${translationKey}`, defaultValue, options),
    };
};

import actions from '../actions/actions';

const initialState = {
    list: [],
};

const maintainCheckboxStateReducer = (state = initialState, action = {}) => {
    if (action.type === actions.MAINTAIN_PREVIOUS_CHECKBOX_STATE) {
        return { list: action.newCheckBoxState };
    }
    return state;
};

export default maintainCheckboxStateReducer;

import { gql } from '@apollo/client';
import { graphql, withApollo } from '@apollo/client/react/hoc';
import { compose } from 'redux';
import Camera from './Camera';

const requestPresignedUploadUrl = gql`
    mutation createPresignedPost($filetype: String!) {
        createPresignedPost(filetype:  $filetype) {
            url
            key
            fields
        }
    }
`;

const enhance = compose(
    graphql(requestPresignedUploadUrl, { name: 'requestPresignedUploadUrl' }),
    withApollo,
);

export default enhance(Camera);

/* eslint-disable max-len */
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import Button from '../../../../../../tap-fe-components/src/components/Button';
import cameraIcon from './icons/camera.svg';
import closeIcon from './icons/close.svg';
import deleteIcon from './icons/delete.svg';
import duplicateIcon from './icons/duplicate.svg';
import ellipseIcon from './icons/ellipse.svg';
import fillColorIcon from './icons/fill-color.svg';
import fullscreenIcon from './icons/fullscreen.svg';
import imageUploadIcon from './icons/image-upload.svg';
import pencilIcon from './icons/pencil.svg';
import redoIcon from './icons/redo.svg';
import strokeWidthIcon from './icons/stroke-width.svg';
import textSizeIcon from './icons/text-size.svg';
import undoIcon from './icons/undo.svg';
import theme from '../../../../../../tap-fe-components/src/theme';

const DrawingWrapper = styled.div`

    .drawing-tool {
        align-items: center;
        background-color: #F4F4F4;
        border-radius: 6px;
        border: 0;
        padding: 24px;
        max-height: 582px;
        margin-bottom: ${props => (props.isPreview && '0px')};

        &__canvas {
            border-width: 2px;
            overflow: hidden;
            width: inherit !important;
        }

        &__container {
            margin-left: 0;
        }

        &__toolbar {
            width: 5.1rem;
            margin-right: 24px; 
            &-tools {
                background: #D0E6E6;
                border: 1px solid #CFCDCD;
                border-radius: 5px;
                width: 82px;
                &:first-of-type {
                    
                }
            }
        }

        &__button {
            border: 2px solid #CFCDCD;
            height: 40px;
            width: 40px;

            &-group {
                width: 82px;
                &:first-of-type { 
                    .drawing-tool-options {
                        &:first-of-type {
                            + div {
                                .drawing-tool-options__options {
                                    max-width: 280px;
                                    min-width: ${props => (props.stampImageCount > 4 ? 280 : (props.stampImageCount * 68 + 8))}px;
                                    padding: 4px;
                                    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 24%);
                                    border-radius: 8px;
                                    background-color: #FFFFFF;
                                    margin-left: 8px;
            
                                    li {
                                        margin: 4px;
                                        background-color: #F9F9F9;
                                        border-radius: 4px;
                                        width: 60px;
                                        height: 60px;
            
                                        button {
                                            background-color: #F9F9F9 !important;
                                            border: none;
                                            width: inherit;
                                            height: inherit;
                                            padding: 0;
                                            border-radius: 4px;
            
                                            span {
                                                height: inherit;
                                                width: inherit;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;

                                                img {
                                                    width: auto;
                                                    height: auto;
                                                    max-width: 100%;
                                                    max-height: 100%;
                                                    transform: none !important;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &-group:last-child {
                    margin: 0;
            }
            // &-select { img { content: url(${closeIcon});}}
            &-pencil { img { content: url(${pencilIcon});}}
            // &-line { img { content: url(${closeIcon});}}
            // &-arrow { img { content: url(${closeIcon});}}
            // &-rectangle { img { content: url(${closeIcon});}}
            &-ellipse { img { content: url(${ellipseIcon});}}
            &-camera { img { content: url(${cameraIcon});}}
            &-image-upload { img { content: url(${imageUploadIcon});}}
            &-fullscreen { img { content: url(${fullscreenIcon});}}
            // &-bring-to-front { img { content: url(${closeIcon});}}
            // &-send-to-back { img { content: url(${closeIcon});}}
            &-undo { img { content: url(${undoIcon});}}
            &-redo { img { content: url(${redoIcon});}}
            &-duplicate { img { content: url(${duplicateIcon});}}
            &-delete { img { content: url(${deleteIcon});}}
        }

        &-options {
            border: 2px solid #CFCDCD;
            width: 2.5rem;
            height: 2.5rem;
            &:first-of-type { 
                .drawing-tool-options__button-with-options {img { content: url(${textSizeIcon});}}
            }
            &:first-child { // needs to be changed later
                .drawing-tool-options__button-with-options {img { content: url(${fillColorIcon});}}
            }
            &:nth-child(3) { 
                .drawing-tool-options__button-with-options {img { content: url(${strokeWidthIcon});}}
            }
        }

        &-modal__close-button {
            height: 26px;
            width: 26px;
            background: url(${closeIcon});
            filter: invert(44%) sepia(17%) saturate(1440%) hue-rotate(114deg) brightness(100%) contrast(88%);
            border: 0;
        }

        &--no-controls {
            pointer-events: none;
            margin-top: 16px;
            margin-bottom: 0;
        }

        &-modal {
            z-index: 1302;

            &__content {
                position: fixed;
                top: 50%;
                transform: translate(-50%, -50%);

                @media (min-width: 1280px) {
                    max-width: 60%;
                    left: 55%;
                }
                @media (max-width: 1279px) {
                    max-width: 80%;
                    left: 54%;
                }
            }
        }
    }

    .image-upload__dropzone {
        button {
            background-color: ${theme.accentColor};
            border-radius: 5px;
            height: 36px;
            color: #FFFFFF;
            font-family: Roboto;
            font-size: 16px;
            font-weight: bold;
            line-height: 21.1px;
            text-align: center;
            border: 0;
            cursor: pointer;
            :hover {
                box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
            }
        }
    }

    &.answer-preview {
        .drawing-tool {
            &--no-controls {
                padding: 0;
            }

            &__loading-container {
                & > p {
                    display: none;
                }
            }
        }
    }
`;

const StyledButton = withStyles({
    root: {
        width: '82px',
        padding: '0',
        margin: '24px 0 0 0',
    },
})(Button);

const NoGuidanceWrapper = styled.div`
    color: #332A7C;
    margin-top: 16px;
`;

const Answerlabel = styled.div`
    color: #5D618E;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
`;

const PreviewDrawingWrapper = styled.div`
    .drawing-tool {
        &--no-controls {
            pointer-events: none;
        }
    }
`;

const DrawingToolWrapper = styled.div`
    display: contents;
`;

const TTSOnly = styled.div`
    display: none;
`;

export {
    DrawingWrapper, StyledButton, NoGuidanceWrapper,
    Answerlabel, DrawingToolWrapper, TTSOnly,
    PreviewDrawingWrapper,
};

/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const Fill = ({ color }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23">
        <path d="M21.25.5H1.75A1.25,1.25,0,0,0,.5,1.75v19.5A1.25,1.25,0,0,0,1.75,22.5h19.5a1.25,1.25,0,0,0,1.25-1.25V1.75A1.25,1.25,0,0,0,21.25.5ZM16.5,16a.5.5,0,0,1-.5.5H7a.5.5,0,0,1-.5-.5V7A.5.5,0,0,1,7,6.5h9a.5.5,0,0,1,.5.5Z" fill={color} stroke="#cfcdcd" strokeMiterlimit="10" />
    </svg>
);

Fill.propTypes = {
    color: PropTypes.string.isRequired,
};

export default Fill;

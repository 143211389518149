/* eslint-disable react/prop-types */
import React from 'react';
import uuid from 'react-uuid';
import PropTypes from 'prop-types';
import { FormControl } from '@material-ui/core';
import { isSafari } from 'react-device-detect';
import Option from '../Option';
import Button from '../../../../../../../../tap-fe-components/src/components/Button';
import CorrectIcon from '../../../../../../../../tap-fe-components/src/components/Icons/Correct';
import IncorrectIcon from '../../../../../../../../tap-fe-components/src/components/Icons/Incorrect';
import {
    SingleSelectRadio,
    RadioControlLabel,
    SingleSelectControl,
    RadioButtonsWrapper,
    AnswerWrapper,
    Answerlabel,
    NoGuidanceWrapper,
    TickIconWrapper,
    StyledFormLabel,
    StyledRadioGroup,
} from './index.style';

import StringTable from '../../../../../services/stringTable';
import keys from '../../../../../constants/keys';

const showAnswerText = StringTable.getString(keys.questions, keys.common, keys.showAnswer);
const hideAnswerText = StringTable.getString(keys.questions, keys.common, keys.hideAnswer);
const correctAnswerText = StringTable.getString(keys.questions, keys.common, keys.correctAnswer);
const rubricGuidanceText = StringTable.getString(keys.questions, keys.common, keys.rubricGuidanceText);
const noGuidanceOrRubric = StringTable.getString(keys.questions, keys.common, keys.noGuidanceOrRubric);
const chooseFromFollowingText = StringTable.getString(keys.questions, keys.common, keys.chooseFromFollowingText);

class SingleSelectOptions extends React.Component {
    constructor(props) {
        super(props);
        let { response } = props;
        response = response || undefined;
        this.state = {
            showAnswer: false,
            selectedOption: response[0]?.responseText,
            uniqueId: uuid(),
        };
        this.handleChange = this.handleChange.bind(this);
        this.getResponseData = props.getResponseData.bind(this);
    }

    getOptionProps = (choice, index) => {
        const optionProps = {};
        const children = [...choice.children];
        children.forEach((child) => {
            if (child.tagName === 'img') {
                optionProps.img = {
                    src: child.getAttribute('src'),
                    alt: child.getAttribute('alt'),
                    width: child.getAttribute('width'),
                    height: child.getAttribute('height'),
                };
            }
        });
        optionProps.text = choice.textContent.trim();
        optionProps.optionNumber = index + 1;
        return optionProps;
    }

    handleClick = () => {
        const { showAnswer } = this.state;
        this.setState({
            showAnswer: !showAnswer,
        });
    }

    handleChange = (event) => {
        this.setState({ selectedOption: event.target.value }, () => {
            const { selectedOption } = this.state;
            const { responseIdentifier } = this.props;
            const response = [{
                xmlIdentifier: responseIdentifier,
                responseText: selectedOption,
            }];
            this.getResponseData({
                isAttempted: response[0].responseText !== '',
                response,
            });
        });
    };

    showOrHideAnswerText = showAnswer => (!showAnswer ? showAnswerText : hideAnswerText);

    getShowQuestionPreview = (showCorrectAnswer, correctResponse, showGrading, isPreview) => (
        (showCorrectAnswer && correctResponse) || showGrading || (isPreview && !showCorrectAnswer)
    );

    getCorrectAnswer = correctOptions => (correctOptions ? correctOptions[0] : '');

    getSingleSelectControl = () => {
        const {
            choices,
            correctOptions,
            hideShowAnswerButton,
            showCorrectAnswer,
            showGrading,
            disable,
            correctResponse,
            isNotAttempted,
        } = this.props;
        const correctAnswer = this.getCorrectAnswer(correctOptions);
        const { showAnswer, selectedOption, uniqueId } = this.state;
        const radioGroupProps = {
            onChange: this.handleChange,
            overflowX: 'auto',
        };
        return (
            <SingleSelectControl>
                <FormControl
                    component="fieldset"
                    disabled={showCorrectAnswer || showGrading || disable}
                >
                    <StyledFormLabel component="legend">​</StyledFormLabel>
                    <StyledRadioGroup
                        {...radioGroupProps}
                        aria-labelledby={`form-group-${uniqueId}`}
                        showCorrectAnswer={showCorrectAnswer}
                    >
                        <RadioButtonsWrapper
                            id={`form-group-${uniqueId}`}
                            aria-label={chooseFromFollowingText}
                        >
                            {choices.map((choice, index) => {
                                const optionProps = this.getOptionProps(choice, index);
                                const option = <Option {...optionProps} />;
                                const isChecked = choice.getAttribute('identifier') === selectedOption;
                                const isCorrect = choice.getAttribute('identifier') === correctAnswer;
                                const isIncorrect = !isCorrect && isChecked;
                                return (
                                    <AnswerWrapper
                                        key={choice.getAttribute('identifier')}
                                    >
                                        {
                                            ((showCorrectAnswer && correctResponse) ||
                                            (showGrading && correctResponse && !isNotAttempted)) && isCorrect ?
                                                (
                                                    <TickIconWrapper>
                                                        <CorrectIcon size={32} />
                                                        {' '}
                                                    </TickIconWrapper>
                                                ) :
                                                null
                                        }
                                        {
                                            showGrading && correctResponse && isIncorrect && !isNotAttempted ? (
                                                <TickIconWrapper>
                                                    <IncorrectIcon size={32} />
                                                    {' '}
                                                </TickIconWrapper>
                                            ) :
                                                null
                                        }

                                        <RadioControlLabel
                                            value={choice.getAttribute('identifier')}
                                            control={(
                                                <SingleSelectRadio
                                                    id={isSafari ?
                                                        undefined : `${choice.getAttribute('identifier')}-${uniqueId}`}
                                                    size="small"
                                                />
                                            )}
                                            label={option}
                                            checked={isChecked}
                                            showTickSpace={((showCorrectAnswer && correctResponse) ||
                                                (showGrading && correctResponse && !isNotAttempted)) &&
                                                !(isCorrect || isIncorrect)
                                            }
                                            htmlFor={`${choice.getAttribute('identifier')}-${uniqueId}`}
                                        />
                                    </AnswerWrapper>
                                );
                            })}
                        </RadioButtonsWrapper>
                    </StyledRadioGroup>
                </FormControl>
                {!hideShowAnswerButton && (
                    <Button onClick={this.handleClick}>
                        {this.showOrHideAnswerText(showAnswer)}
                    </Button>
                )}
            </SingleSelectControl>
        );
    }

    getGuidanceComponent = () => {
        const { isRubric, isAuthorView, isQuestionWrapper } = this.props;
        const guidanceComponent = (isRubric ? (<NoGuidanceWrapper>{rubricGuidanceText}</NoGuidanceWrapper>) :
            (isAuthorView && isQuestionWrapper && <NoGuidanceWrapper>{noGuidanceOrRubric}</NoGuidanceWrapper>));
        return guidanceComponent;
    }

    render() {
        const {
            showCorrectAnswer,
            showGrading,
            userAnswerLabel,
            correctResponse,
            isPreview,
            isNotAttempted,
        } = this.props;
        const showQuestionPreview = this.getShowQuestionPreview(
            showCorrectAnswer, correctResponse, showGrading, isPreview,
        );

        return (
            <React.Fragment>
                {
                    showCorrectAnswer && showQuestionPreview ? (
                        <Answerlabel
                            noMargin={!correctResponse}
                            notCapitalize
                        >
                            {correctAnswerText}
                        </Answerlabel>
                    ) : null
                }
                {
                    showGrading ?
                        (
                            <Answerlabel
                                noMargin={!correctResponse || isNotAttempted}
                            >
                                {userAnswerLabel}
                            </Answerlabel>
                        ) : null
                }
                <React.Fragment>
                    {
                        showQuestionPreview ? (
                            this.getSingleSelectControl()
                        ) :
                            (
                                this.getGuidanceComponent()
                            )
                    }
                </React.Fragment>
            </React.Fragment>
        );
    }
}

SingleSelectOptions.propTypes = {
    response: PropTypes.arrayOf(PropTypes.shape({
        xmlIdentifier: PropTypes.string,
        responseText: PropTypes.string,
    })),
    getResponseData: PropTypes.func,
    responseIdentifier: PropTypes.string.isRequired,
    showCorrectAnswer: PropTypes.bool,
    showGrading: PropTypes.bool,
    correctOptions: PropTypes.arrayOf(PropTypes.string.isRequired),
    userAnswerLabel: PropTypes.string,
    disable: PropTypes.bool,
    correctResponse: PropTypes.bool,
    isNotAttempted: PropTypes.bool,
};

SingleSelectOptions.defaultProps = {
    getResponseData: () => [],
    response: [{ xmlIdentifier: '', responseText: '' }],
    showCorrectAnswer: false,
    showGrading: false,
    correctOptions: [],
    userAnswerLabel: '',
    disable: false,
    correctResponse: false,
    isNotAttempted: false,
};

export default SingleSelectOptions;

import React from 'react';
import { StyledIconButton } from './IconButton.styles';
import theme from '../../theme';

const IconButton = (props) => {
    const iconButtonProps = { ...props };
    return (
        <StyledIconButton
            disableFocusRipple
            disableTouchRipple
            {...props}
            style={{
                ...iconButtonProps.style,
                backgroundColor: iconButtonProps.isDragging ? theme.accentColorActiveLight : '',
                border: iconButtonProps.isDragging ? `1px solid ${theme.accentColorActive}` : '',
                color: iconButtonProps.isDragging ? theme.accentColorActive : '',
            }}
        >
            {iconButtonProps.children}
        </StyledIconButton>
    );
};

export default IconButton;

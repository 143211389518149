import React from 'react';
import uuid from 'react-uuid';
import Modal from '@mui/material/Modal';
import styled from 'styled-components';
import BaseComponent from '../BaseComponent';
import {
    FullScreenImage, ImageWrapDiv, StyledOpen, StyledClose, OpenButton,
    HeaderTitle, HeaderImageStyle, DummyImageRender,
    OuterWrapper, InnerWrapper, OpenButtonWrapper,
} from './ImageModal.styles';
import Tooltip from '../../../../tap-fe-components/src/components/Tooltip';
import StringTable from '../../services/stringTable';
import keys from '../../constants';
import IconButton from '../../../../tap-fe-components/src/components/IconButton';

const imagePreviewText = StringTable.getString(keys.app, keys.common, keys.imagePreviewText);

export default class ImageModal extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            originalHeight: null,
            originalWidth: null,
            imageWidth: null,
            imageHeight: null,
            attrHeight: null,
            attrWidth: null,
            divWidth: null,
            id: `image-${uuid()}`,
        };
        this.dummyImageRef = React.createRef();
        this.imageLoaded = this.imageLoaded.bind(this);
        this.onWindowResize = this.onWindowResize.bind(this);
        this.onWindowResizeTimer = null;
    }

    componentDidMount() {
        window.addEventListener('resize', this.onWindowResize);
    }

    componentWillUnmount() {
        clearTimeout(this.onWindowResizeTimer);
        window.removeEventListener('resize', this.onWindowResize);
    }

    handleOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    onWindowResize = () => {
        clearTimeout(this.onWindowResizeTimer);
        this.onWindowResizeTimer = setTimeout(this.forceUpdate.bind(this), 100);
    }

    getAttributeDimentions = () => {
        const { styleImg } = this.props;
        let attrWidth = 0;
        let attrHeight = 0;
        if (styleImg) {
            const result = {};
            const attributes = styleImg.split(';');
            attributes.forEach((current) => {
                const entry = current.trim().split(':');
                result[entry.splice(0, 1)[0]] = entry.join(':');
            });
            const { width, height } = result;
            attrHeight = Number(height.replace('px', ''));
            attrWidth = Number(width.replace('px', ''));
        }
        return {
            attrWidth,
            attrHeight,
        };
    }

    getUpdateImageDimensions = (originalHeight, originalWidth) => {
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;
        let imageWidth = 0;
        let imageHeight = 0;
        let { attrHeight, attrWidth } = this.state;
        if (originalHeight && originalWidth) {
            if (originalWidth >= screenWidth - 40) {
                imageWidth = screenWidth - 40;
                imageHeight = imageWidth * originalHeight / originalWidth;
                if (imageHeight > screenHeight - 80) {
                    imageHeight = screenHeight - 80;
                    imageWidth = imageHeight * originalWidth / originalHeight;
                }
            } else if (originalHeight >= screenHeight - 80) {
                imageHeight = screenHeight - 80;
                imageWidth = imageHeight * originalWidth / originalHeight;
            } else {
                imageHeight = originalHeight;
                imageWidth = originalWidth;
            }
            if (!attrHeight && !attrWidth) {
                attrHeight = originalHeight;
                attrWidth = originalWidth;
            } else if (!attrHeight) {
                attrHeight = attrWidth * originalHeight / originalWidth;
            } else if (!attrWidth) {
                attrWidth = attrHeight * originalWidth / originalHeight;
            }
        }
        return {
            imageHeight,
            imageWidth,
            attrWidth,
            attrHeight,
        };
    }

    imageLoaded = () => {
        if (this.dummyImageRef?.current) {
            const { height: originalHeight, width: originalWidth } = this.dummyImageRef?.current;
            const { attrHeight, attrWidth } = this.getAttributeDimentions();
            this.setState({
                originalHeight,
                originalWidth,
                attrHeight,
                attrWidth,
            });
        }
    }

    render = () => {
        const {
            open, id, originalHeight, originalWidth,
        } = this.state;
        const {
            imageSrc, styleImg,
            alt, isPresentationModeEnabled,
        } = this.props;
        const SmallImg = styled.img`
        ${styleImg}
    `;
        const imageDimentions = this.getUpdateImageDimensions(originalHeight, originalWidth);
        const {
            imageWidth, imageHeight, attrHeight, attrWidth,
        } = imageDimentions;
        const headerTitleId = `title-${id}`;
        if (!originalHeight || !originalWidth) {
            return (
                <DummyImageRender>
                    <img
                        ref={this.dummyImageRef}
                        src={imageSrc}
                        onLoad={this.imageLoaded}
                        alt={alt || ''}
                        aria-hidden={alt ? undefined : 'true'}
                    />
                </DummyImageRender>
            );
        }
        return (
            <div style={{ overflowX: 'auto' }}>
                <ImageWrapDiv style={{ width: attrWidth, maxWidth: '100%' }}>
                    <Tooltip title="Full Screen">
                        <OpenButtonWrapper>
                            <OpenButton
                                type="button"
                                onClick={this.handleOpen}
                                disabled={!isPresentationModeEnabled}
                                aria-label="Full Screen"
                                className="rs_skip"
                            >
                                <StyledOpen />
                            </OpenButton>
                        </OpenButtonWrapper>
                    </Tooltip>
                    <OuterWrapper ratio={attrWidth / attrHeight}>
                        <InnerWrapper>
                            <SmallImg
                                id={id}
                                src={imageSrc}
                                alt={alt || ''}
                                style={{
                                    maxWidth: '100%',
                                    width: attrWidth,
                                    height: 'auto',
                                    marginTop: '0',
                                }}
                                aria-hidden={alt ? undefined : 'true'}
                            />
                        </InnerWrapper>
                    </OuterWrapper>

                    <Modal
                        open={open}
                        onClose={this.handleClose}
                        role="dialog"
                        aria-labelledby={headerTitleId}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 1500,
                        }}
                    >
                        <FullScreenImage>
                            <HeaderImageStyle style={{ width: imageWidth }}>
                                <HeaderTitle id={headerTitleId}>{imagePreviewText}</HeaderTitle>
                                <IconButton aria-label="Close" onClick={this.handleClose}>
                                    <StyledClose />
                                </IconButton>
                            </HeaderImageStyle>
                            <img
                                id={`light-box-${id}`}
                                src={imageSrc}
                                alt={alt || ''}
                                style={{ width: imageWidth, maxHeight: imageHeight }}
                                aria-hidden={alt ? undefined : 'true'}
                            />
                        </FullScreenImage>
                    </Modal>
                </ImageWrapDiv>
            </div>
        );
    }
}

/* eslint-disable react/prop-types */
import React from 'react';
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';
import { IMAGETEXT } from '../../../services/stringConstants';
import { QuestionTextWrapper, TTSOnly } from './index.style';
import StringTable from '../../../services/stringTable';
import keys from '../../../constants/keys';
import ImageModal from '../../../../../../src/js/components/ImageModal';

const imageForQuestionText = StringTable.getString(keys.questions, keys.common, keys.imageForQuestionText);

class QuestionText extends React.Component {
    getFilename = (url) => {
        if (url && !url.toString().startsWith('data:image/')) {
            const m = url.toString().match(/.*\/(.+?)\./);
            if (m && m.length > 1) {
                return m[1];
            }
        }
        return '';
    };

    transform = (node, index) => {
        const {
            isTtsEnabled, questionHTML, questionpartPrefix, disabled,
        } = this.props;
        const parser = new DOMParser();
        const doc = parser.parseFromString(questionHTML, 'text/html');
        const questionElement = doc.getElementsByClassName('question');
        const textContent = questionElement[0]?.querySelector('p')?.innerHTML || '';
        if (node.type === 'tag' && node.name === 'img') {
            if (node.attribs['presentation-mode'] === 'true') {
                const imageName = this.getFilename(node.attribs.src);
                const { alt, 'presentation-mode': isPresentationModeEnabled } = node.attribs;

                if (isTtsEnabled) {
                    return (
                        <React.Fragment>
                            <TTSOnly>
                                {IMAGETEXT}
                            </TTSOnly>
                            <ImageModal
                                key={`image-${this.id}-number-${index}`}
                                imageSrc={node.attribs.src}
                                thumbnail={imageName}
                                styleImg={node.attribs.style}
                                disabled={disabled}
                                alt={alt}
                                isPresentationModeEnabled={isPresentationModeEnabled === 'true'}
                            />
                        </React.Fragment>
                    );
                }

                return (
                    <ImageModal
                        key={`image-${this.id}-number-${index}`}
                        imageSrc={node.attribs.src}
                        thumbnail={imageName}
                        styleImg={node.attribs.style}
                        disabled={disabled}
                        alt={alt}
                        isPresentationModeEnabled={isPresentationModeEnabled === 'true'}
                    />
                );
            }
            const { attribs } = node;
            let { style } = attribs;
            const { alt } = attribs;
            style = `${style}; max-width: 100%;`;
            const newNode = node;
            newNode.attribs.style = style;
            newNode.attribs.alt = alt || `${questionpartPrefix || ''}`;
            if (isTtsEnabled) {
                return (
                    <React.Fragment>
                        <TTSOnly>
                            {IMAGETEXT}
                        </TTSOnly>
                        {convertNodeToElement(newNode, index, this.transform)}
                    </React.Fragment>
                );
            }
            return convertNodeToElement(newNode, index, this.transform);
        }
        if (node.type === 'tag' && node.name === 'table') {
            return (
                <div style={{ overflowX: 'auto' }}>
                    { convertNodeToElement(node, index, this.transform) }
                </div>
            );
        }
        return undefined;
    }

    render() {
        const {
            questionHTML, isBottomPadding, isExtraTag, isOtherTag,
        } = this.props;
        const options = {
            decodeEntities: true,
            transform: this.transform,
        };
        let bottomPadding = true;
        let extraTag = false;
        let otherTag = false;
        if (isBottomPadding !== undefined) {
            bottomPadding = isBottomPadding;
        }
        if (isExtraTag !== undefined) {
            extraTag = isExtraTag;
        }
        if (isOtherTag !== undefined) {
            otherTag = isOtherTag;
        }
        return (
            <QuestionTextWrapper
                isBottomPadding={bottomPadding}
                isExtraTag={extraTag}
                isOtherTag={otherTag}
            >
                {ReactHtmlParser(questionHTML, options)}
            </QuestionTextWrapper>
        );
    }
}

export default QuestionText;

import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import {
    DrawingWrapper, StyledButton, NoGuidanceWrapper,
    Answerlabel, DrawingToolWrapper, TTSOnly,
} from './index.styles';
import DrawingTool from '../../../../../twig-drawing-tool/src/DrawingTool';
import LoadingPlaceholder from '../../../../../../tap-fe-components/src/components/LoadingPlaceholder';
import QuestionText from '../QuestionText';
import StringTable from '../../../services/stringTable';
import keys from '../../../constants/keys';
import americanEnglish from '../../../../../twig-drawing-tool/src/i18n/English-US/translation.json';
import { IMAGETEXT } from '../../../services/stringConstants';

const i18nextConfig = {
    lng: 'en-US',
    fallbackLng: 'en-US',
    interpolation: {
        escapeValue: false,
    },
    load: 'currentOnly',
};

i18next.use(initReactI18next);

i18nextConfig.resources = {
    'en-US': {
        translation: americanEnglish,
    },
};

i18next.init(i18nextConfig);

const buttonText = StringTable.getString(keys.questions, keys.drawing, keys.buttonText);
const rubricGuidanceText = StringTable.getString(keys.questions, keys.common, keys.rubricGuidanceText);
const loadingText = StringTable.getString(keys.questions, keys.common, keys.loading);
const noGuidanceOrRubric = StringTable.getString(keys.questions, keys.common, keys.noGuidanceOrRubric);

class DrawingQuestion extends React.Component {
    constructor(props) {
        super(props);
        let { response } = props;
        response = response || [];

        const previousLessonAnswer = response[0] ? JSON.parse(response[0].responseText) : null;
        this.state = {
            showAnswer: false,
            previousLessonAnswer,
        };
        this.handleChange = this.handleChange.bind(this);
        this.getResponseData = props.getResponseData.bind(this);
        this.timer = null;
        this.DrawingWrapperRef = React.createRef();
    }

    componentDidMount() {
        this.timer = setTimeout(() => {
            this.resetTabindex();
        }, 5000);
    }

    componentDidUpdate() {
        this.resetTabindex();
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    resetTabindex = () => {
        if (this.DrawingWrapperRef.current) {
            // eslint-disable-next-line no-unused-expressions
            this.DrawingWrapperRef.current.getElementsByClassName('drawing-tool drawing-tool--no-controls')[0]?.
                removeAttribute('tabindex');
            // eslint-disable-next-line no-unused-expressions
            this.DrawingWrapperRef.current.getElementsByClassName('drawing-tool')[0]?.removeAttribute('tabindex');
        }
    }


    handleClick = () => {
        const { showAnswer } = this.state;
        this.setState({
            showAnswer: !showAnswer,
        });
    };

    handleChange = (responseString) => {
        const { responseIdentifier } = this.props;
        const parsedResponseString = JSON.parse(responseString);
        const response = parsedResponseString.objects.length !== 0 ? [{
            xmlIdentifier: responseIdentifier,
            responseText: responseString,
        }] : [];
        this.getResponseData({
            isAttempted: parsedResponseString.objects.length !== 0,
            response,
        });
    };

    render() {
        const {
            aspectRatio, backgroundImageUrl,
            questionHTML, showCorrectAnswer,
            showGrading, userAnswerLabel, disable,
            predeterminedImageUrls, isAnswerPreviewView, isTtsEnabled,
            isRubric, isAuthorView, isQuestionWrapper,
        } = this.props;
        const { previousLessonAnswer } = this.state;
        const ClearButton = props => (
            <StyledButton onClick={props.onClick} variant="contained">
                {buttonText}
            </StyledButton>
        );
        const loadingComponent = () => (
            <div>
                {loadingText}
            </div>
        );
        const loadingView = isAnswerPreviewView ? loadingComponent : LoadingPlaceholder;
        const frontEndComponents = { Button: ClearButton, LoadingPlaceholder: loadingView };
        let isBottomPadding = true;
        if (showCorrectAnswer) {
            isBottomPadding = isRubric || (isAuthorView && isQuestionWrapper);
            const isExtraTag = !isBottomPadding;
            return (
                <React.Fragment>
                    {questionHTML && (
                        <QuestionText
                            questionHTML={questionHTML}
                            isBottomPadding={isBottomPadding}
                            isExtraTag={isExtraTag}
                        />
                    )}
                    {
                        isRubric ? <NoGuidanceWrapper>{rubricGuidanceText}</NoGuidanceWrapper> :
                            isAuthorView && isQuestionWrapper &&
                                <NoGuidanceWrapper>{noGuidanceOrRubric}</NoGuidanceWrapper>
                    }
                </React.Fragment>
            );
        }
        return (
            <React.Fragment>
                <DrawingWrapper
                    ref={this.DrawingWrapperRef}
                    stampImageCount={predeterminedImageUrls.length}
                    className={isAnswerPreviewView ? 'answer-preview' : ''}
                >
                    <div>
                        {questionHTML && !isAnswerPreviewView && (
                            <QuestionText
                                questionHTML={questionHTML}
                                isTtsEnabled={isTtsEnabled}
                            />
                        )}
                    </div>
                    {backgroundImageUrl !== '' ? (
                        <TTSOnly>
                            {IMAGETEXT}
                        </TTSOnly>
                    ) : null}

                    {showGrading ? <Answerlabel>{userAnswerLabel}</Answerlabel> : null}
                    <DrawingToolWrapper
                        className="rs_skip"
                    >
                        <DrawingTool
                            frontEndComponents={frontEndComponents}
                            aspectRatio={aspectRatio}
                            backgroundImageUrl={backgroundImageUrl}
                            predeterminedImageUrls={predeterminedImageUrls}
                            saveToStore={this.handleChange}
                            initialState={previousLessonAnswer}
                            hideControls={showGrading || disable}
                        />
                    </DrawingToolWrapper>
                </DrawingWrapper>
            </React.Fragment>

        );
    }
}

DrawingQuestion.propTypes = {
    aspectRatio: PropTypes.shape({
        height: PropTypes.number,
        width: PropTypes.number,
    }),
    backgroundImageUrl: PropTypes.string,
    questionHTML: PropTypes.string,
    getResponseData: PropTypes.func,
    responseIdentifier: PropTypes.string,
    response: PropTypes.arrayOf(PropTypes.shape({
        xmlIdentifier: PropTypes.string,
        responseText: PropTypes.string,
    })),
    showCorrectAnswer: PropTypes.bool,
    showGrading: PropTypes.bool,
    userAnswerLabel: PropTypes.string,
    disable: PropTypes.bool,
    predeterminedImageUrls: PropTypes.arrayOf(PropTypes.string),
    isAnswerPreviewView: PropTypes.bool,
    isTtsEnabled: PropTypes.bool,
    isRubric: PropTypes.bool,
    isAuthorView: PropTypes.bool,
    isQuestionWrapper: PropTypes.bool,
};

DrawingQuestion.defaultProps = {
    aspectRatio: {
        height: 3,
        width: 4,
    },
    backgroundImageUrl: '',
    predeterminedImageUrls: [],
    questionHTML: '',
    responseIdentifier: '',
    getResponseData: () => [],
    response: [],
    showCorrectAnswer: false,
    showGrading: false,
    userAnswerLabel: '',
    disable: false,
    isAnswerPreviewView: false,
    isTtsEnabled: false,
    isRubric: false,
    isAuthorView: false,
    isQuestionWrapper: false,
};

export default DrawingQuestion;

/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const Incorrect = ({ size }) => (
    <svg
        role="img"
        aria-label="Incorrect"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        data-name="Group 9 Copy 15"
        viewBox="0 0 32 32"
    >
        <rect
            width="32"
            height="32"
            fill="#ca2017"
            data-name="Rectangle Copy"
            rx="16"
        />
        <g data-name="clear-24px (1)" transform="translate(6.4 6.4)">
            <path fill="none" d="M0 0h19.2v19.2H0z" />
            <path
                fill="#fff"
                d="M11.2 1.128L10.072 0 5.6 4.472 1.128 0 0 1.128 4.472 5.6 0 10.072 1.128 11.2 5.6 6.728l4.472 4.472 1.128-1.128L6.728 5.6z"
                data-name="Path"
                transform="translate(4 4)"
            />
        </g>
    </svg>
);

Incorrect.propTypes = {
    size: PropTypes.number.isRequired,
};


export default Incorrect;

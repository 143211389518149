import { withStyles } from '@material-ui/core/styles';
import { Checkbox as MuiCheckbox } from '@material-ui/core';
import theme from '../../../../../../../tap-fe-components/src/theme';

const Checkbox = withStyles({
    root: {
        color: theme.studentViewInputColor,
        backgroundColor: '#FFFFFF',
        backgroundClip: 'content-box',
        width: '40px',
        height: '40px',
        marginLeft: '6px',
        '&:hover': {
            background: theme.studentViewHoverBackground,
        },
        '&:active, &.Mui-focusVisible': {
            background: theme.studentViewActiveBackground,
        },
        '&.Mui-focusVisible': {
            outline: `1px solid ${theme.focusOutline}`,
        },
        '&.Mui-disabled': {
            color: theme.studentViewInputColor,
            opacity: '0.5',
        },
        '&$checked': {
            color: theme.studentViewInputColor,
            '&:hover': {
                background: theme.studentViewHoverBackground,
            },
            '&.Mui-disabled': {
                color: theme.studentViewInputColor,
                opacity: 0.5,
            },
        },
        '& svg': {
            fill: 'currentColor',
            width: '20px',
            height: '20px',
        },
    },
    checked: {},
    disabled: {},
})(MuiCheckbox);

export default Checkbox;

import hexRgb from 'hex-to-rgb';

const formatColor = (color) => {
    let formattedColor = color;
    if (color.charAt(0) === '#') {
        const rgb = hexRgb(color);
        formattedColor = `rgb(${rgb[0]},${rgb[1]},${rgb[2]})`;
    }
    return formattedColor.split(' ').join('');
};

export default formatColor;

const teacherUrl = '/teacher';
const assessmentsLibraryUrl = `${teacherUrl}/assessmentslibrary`;
const assessmentsCenterUrl = `${teacherUrl}/assessmentscenter`;
const reportsUrl = `${teacherUrl}/reports`;
const assessmentDetailUrl = `${teacherUrl}/assessmentslibrary/assessment/detail`;
const settingsUrl = `${teacherUrl}/settings`;

export {
    assessmentsLibraryUrl,
    assessmentsCenterUrl,
    reportsUrl,
    assessmentDetailUrl,
    settingsUrl,
};
export default teacherUrl;

import actions from '../actions/actions';

const initialState = {
    navigationFunction: () => { /* */ },
};

const subscriptionManagerReducer = (state = initialState, action = {}) => {
    if (action.type === actions.CHANGE_NAVIGATION_FUNCTION) {
        return { navigationFunction: action.navigationFunction };
    }
    return state;
};

export default subscriptionManagerReducer;

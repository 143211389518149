import { isEqual } from 'lodash';
import actions from '../actions/actions';

const initialState = {
    viewMode: 'Points',
};

const liveClassMonitoringTableCellStateReducer = (state = initialState, action = {}) => {
    if (action.type === actions.UPDATE_VIEW_MODE) {
        if (!isEqual(state.viewMode, action.newViewModeData)) {
            return { ...state, viewMode: action.newViewModeData };
        }
    }
    return state;
};

export default liveClassMonitoringTableCellStateReducer;

// function to add attributes inside tag
const appendAtrributes = (oldXml, attributes) => {
    let xml = oldXml;
    if (attributes && Object.keys(attributes)?.length > 0) {
        for (const [key, value] of Object.entries(attributes)) {
            xml += ` ${key}="${value}"`;
        }
    }
    return xml;
};

const addTextAndCdata = (oldXML, text, cdata) => {
    let xml = oldXML;
    if (text) xml += text;
    if (cdata) xml += cdata;
    return xml;
};

const convertDataToXML = (obj) => {
    let xml = '';
    for (const tag of obj) {
        const {
            name, attributes, elements, text, cdata,
        } = tag;

        const hasChilds = name && elements?.length > 0;

        // decide to create opening tag or self closing tag and break loop
        if (hasChilds) {
            xml += `<${name}`;
            xml = appendAtrributes(xml, attributes);
            xml += '>';
        } else if (name && !(elements && elements?.length > 0)) {
            xml += `<${name}`;
            xml = appendAtrributes(xml, attributes);
            xml += '/>';
            break;
        }

        // loop over childrens recursively
        if (elements && elements?.length > 0) {
            for (const ele of elements) {
                xml += convertDataToXML([ele]);
            }
        }

        // append text or xml based on value of text/cdata
        xml = addTextAndCdata(xml, text, cdata);

        // add closing tag if childrens are available
        if (hasChilds) {
            xml += `</${name}>`;
        }
    }
    xml = xml.replace(/<\/?\d+>/g, '');
    return xml;
};

export default convertDataToXML;

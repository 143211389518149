import tokenHasExpired from './tokenHasExpired';

export const getRefreshToken = () => localStorage.getItem('refreshToken');
export const getRefreshTokenExpiry = () => localStorage.getItem('refreshTokenExpiry');
export const setRefreshToken = (token) => {
    localStorage.setItem('refreshToken', token);
};
export const deleteRefreshToken = () => {
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('refreshTokenExpiry');
};
export const refreshTokenIsValid = () => (
    !!(localStorage.getItem('refreshExp') && !tokenHasExpired(localStorage.getItem('refreshExp'))));

export const setRefreshTokenExpiry = (exp) => {
    localStorage.setItem('refreshExp', exp);
};

import React from 'react';
import { Route } from 'react-router-dom';
import adminUrl from './adminUrl';

const PsychometricAdmin = React.lazy(() => import(/* webpackChunkName: "PsychometricAdmin" */ './'));

const routes = [
    <Route
        path={adminUrl}
        component={PsychometricAdmin}
        key={PsychometricAdmin}
    />,
];


export default routes;

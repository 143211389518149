import queryString from 'query-string';

const setQueryParams = (history, params, refreshPage) => {
    if (refreshPage) {
        history.replace({
            pathname: `${history.location.pathname}`,
            search: `?${queryString.stringify(params,
                { arrayFormat: 'bracket' })}`,
        });
    } else {
        const newURL = new URL(window.location.href);
        newURL.search = `?${queryString.stringify(params,
            { arrayFormat: 'bracket' })}`;
        window.history.replaceState({ path: newURL.href }, '', newURL.href);
    }
};

const getQueryParams = () => queryString.parse(window.location.search,
    { arrayFormat: 'bracket', parseNumbers: true });

export {
    setQueryParams,
    getQueryParams,
};

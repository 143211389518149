import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import { ApolloProvider } from '@apollo/client';
import { Provider as ReduxProvider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { createMuiTheme, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import * as scrollIntoView from 'scroll-into-view';
import { throttle } from 'lodash';
import client from './services/apolloClient';
import store from './services/reduxStore';
import Routes from './routes';
import LoadingPlaceholder from './components/LoadingPlaceholder';
import theme from '../../tap-fe-components/src/theme';
import focusHandler from '../../tap-fe-components/src/focus-handler';
import proxyLocalStorage from './helpers/proxyLocalStorage';

proxyLocalStorage();

if (window.config.TRACK_COMPONENT_RE_RENDERS) {
    whyDidYouRender(React, {
        onlyLogs: false,
        titleColor: 'green',
        diffNameColor: 'aqua',
        trackAllPureComponents: true,
    });
}
const muiTheme = createMuiTheme({
    palette: {
        primary: {
            main: theme.accentColor,
        },
        action: {
            disabled: 'rgba(22, 115, 74, 1)',
            opacity: 0.5,
        },
    },
    overrides: {
        MuiButton: {
            padding: '0 12px',
            outlinedPrimary: {
                borderColor: theme.accentColor,
                '&$disabled': {
                    borderColor: 'rgba(27, 131, 103, 0.12)',
                },
            },
        },
        MuiIconButton: {
            root: {
                textTransform: 'none',
                '&$disabled': {
                    opacity: '0.5',
                },
            },
        },
        MuiOutlinedInput: {
            root: {
                '&$focused $notchedOutline': {
                    borderWidth: 1,
                },
            },
        },
    },
});

// Temporary fix to prevent page crash due to readSpeaker

const originalRemoveChild = HTMLElement.prototype.removeChild;
HTMLElement.prototype.removeChild = function removeChild(...args) {
    let returnVal;
    try {
        returnVal = originalRemoveChild.apply(this, args);
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
    }
    return returnVal;
};

focusHandler().attachListeners();

const READSPEAKER_SCROLL_THROTTLE_INTERVAL = 1000;
const READSPEAKER_SCROLL_SETTINGS = 'hlscroll';
const READSPEAKER_SCROLL_ON = 'scrollon';

const next = (ele) => {
    const scrollValue = window.rspkr.Common.Settings.get(READSPEAKER_SCROLL_SETTINGS);
    const isCustomScrollEnabled = window.isReadSpeakerCustomScrollStrategyEnabled;
    if (scrollValue === READSPEAKER_SCROLL_ON && isCustomScrollEnabled && ele) {
        const rect = ele.getBoundingClientRect();
        if (!(
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        )) {
            scrollIntoView(ele, {
                time: 700,
            });
        }
    }
};

const throttledNext = throttle(
    next,
    READSPEAKER_SCROLL_THROTTLE_INTERVAL,
    { leading: false },
);

window.scrollElementWithId = function scrollElementWithId(id) {
    const ele = document.getElementById(id);
    throttledNext(ele);
};

// temporary fix to avoid unwanted autoscroll from readspeaker for Safari Catalina
window.ignoreRSAutoScroll = function ignoreRSAutoScroll() {
    const originalScrollFunc = window?.ReadSpeaker?.ui?.AutoScroll?.scroll;
    if (window?.ReadSpeaker?.ui?.AutoScroll?.scroll) {
        window.ReadSpeaker.ui.AutoScroll.scroll = function scroll(...args) {
            if (window.ReadSpeaker?.Browser.info) {
                const { name, version, OS } = window.ReadSpeaker?.Browser.info;
                if (name === 'Safari' && version === 13.1 && OS === 'Mac') {
                    return null;
                }
            }
            return originalScrollFunc.apply(this, args);
        };
    }
};

ReactDOM.render(
    <ApolloProvider client={client}>
        <ReduxProvider store={store}>
            <Suspense fallback={<LoadingPlaceholder />}>
                <ThemeProvider theme={theme}>
                    <MuiThemeProvider theme={muiTheme}>
                        <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
                            <Routes />
                        </DndProvider>
                    </MuiThemeProvider>
                </ThemeProvider>
            </Suspense>
        </ReduxProvider>
    </ApolloProvider>,
    document.getElementById('app'),
);

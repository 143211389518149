import actions from '../actions/actions';

const initialState = {
    status: false,
};

const loaderStateReducer = (state = initialState, action = {}) => {
    if (action.type === actions.CHANGE_STATUS) {
        if (state.status !== action.newStatusData) {
            return { status: action.newStatusData };
        }
    }
    return state;
};

export default loaderStateReducer;

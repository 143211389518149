import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import {
    FormControlLabel, FormControl, FormLabel, FormGroup,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import Checkbox from '../../../common/Checkbox';

const MultipleSelectCheckbox = Checkbox;

const CheckboxControlLabel = withStyles({
    root: {
        wordWrap: 'break-word',
        backgroundColor: '#FAFAFA',
        color: '#332A7C',
        padding: '9px',
        paddingRight: '20px',
        width: '100%',
        borderRadius: '6px',
        marginLeft: props => (props.showTickSpace ? '48px' : '0px'),
        marginRight: '0',
        '& .MuiFormControlLabel-label': {
            paddingLeft: '8px',
        },
    },
})(FormControlLabel);

const StyledFormGroup = withStyles({
    root: {
        overflowX: props => (props.showCorrectAnswer ? '' : 'auto'),
    },
})(FormGroup);

const CheckboxWrapper = styled.div`
    div + div {
        margin-top: 12px;
    } 
`;

const MultipleSelectControl = withStyles({
    root: {
        width: '100%',
    },
})(FormControl);

const CheckCircle = withStyles({
    root: {
        fill: '#0DB43F',
        width: '32px',
        height: '32px',
        margin: 'auto 10px auto 0',
    },
})(CheckCircleIcon);

const CrossCircle = withStyles({
    root: {
        fill: '#D43027',
        width: '32px',
        height: '32px',
        margin: 'auto 10px auto 0',
    },
})(CancelIcon);

const AnswerWrapper = styled.div`
    display: flex;
`;

const Answerlabel = styled.div`
    color: #5D618E;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    margin-left:${props => (props.noMargin ? '0px' : '48px')};
    margin-bottom: 16px;
`;

const NoGuidanceWrapper = styled.div`
    color: #332A7C;
`;

const StyledFormLabel = withStyles({
    root: {
        visibility: 'hidden',
        height: 0,
        padding: 0,
    },
})(FormLabel);

export const TickIconWrapper = styled.div`
    margin-left: auto;
    align-self: center;
    padding-right: 16px;
    padding-left: 0;
`;
export {
    MultipleSelectCheckbox,
    CheckboxControlLabel,
    MultipleSelectControl,
    CheckboxWrapper,
    CheckCircle,
    AnswerWrapper,
    CrossCircle,
    Answerlabel,
    NoGuidanceWrapper,
    StyledFormLabel,
    StyledFormGroup,
};

import React from 'react';
import { Route } from 'react-router-dom';
import loginUrl from './urls';

const Login = React.lazy(() => import(/* webpackChunkName: "Login" */ './'));
const LoginWithSSO = React.lazy(() => import(/* webpackChunkName: "LoginWithSSO" */ './components/LoginWithSSO'));

const routes = [
    <Route path={loginUrl} component={Login} key={Login} />,
    <Route exact path="/" component={LoginWithSSO} key={LoginWithSSO} />,
];


export default routes;

import {
    InMemoryWebStorage,
} from '@twigeducation/oidc-client-react';

class InMemoryStorage {
    static instance;

    static getInstance() {
        InMemoryStorage.instance = InMemoryStorage.instance ?? new InMemoryWebStorage();

        return InMemoryStorage.instance;
    }
}

const setInMemory = (key, value) => {
    return InMemoryStorage.getInstance().setItem(key, value);
};

const getInMemory = (key) => {
    return InMemoryStorage.getInstance().getItem(key);
};

const removeInMemory = (key) => {
    return InMemoryStorage.getInstance().removeItem(key);
};

const clearInMemory = () => {
    return InMemoryStorage.getInstance().clear();
};

export default InMemoryStorage;
export { setInMemory, getInMemory, removeInMemory, clearInMemory };

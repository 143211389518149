const colors = [
    {
        defaultText: 'White',
        translationKey: 'general.colors_white',
        value: 'rgb(255, 255, 255)',
    },
    {
        defaultText: 'Light grey',
        translationKey: 'general.colors_light_grey',
        value: 'rgb(234, 235, 240)',
    },
    {
        defaultText: 'Mid grey',
        translationKey: 'general.colors_mid_grey',
        value: 'rgb(207, 205, 205)',
    },
    {
        defaultText: 'Dark grey',
        translationKey: 'general.colors_dark_grey',
        value: 'rgb(89, 89, 89)',
    },
    {
        defaultText: 'Black',
        translationKey: 'general.colors_black',
        value: 'rgb(0, 0, 0)',
    },
    {
        defaultText: 'Skin 1',
        translationKey: 'general.colors_skin_1',
        value: 'rgb(248, 229, 214)',
    },
    {
        defaultText: 'Skin 2',
        translationKey: 'general.colors_skin_2',
        value: 'rgb(240, 178, 137)',
    },
    {
        defaultText: 'Skin 3',
        translationKey: 'general.colors_skin_3',
        value: 'rgb(200, 124, 82)',
    },
    {
        defaultText: 'Skin 4',
        translationKey: 'general.colors_skin_4',
        value: 'rgb(176, 89, 26)',
    },
    {
        defaultText: 'Skin 5',
        translationKey: 'general.colors_skin_5',
        value: 'rgb(97, 44, 6)',
    },
    {
        defaultText: 'Skin 6',
        translationKey: 'general.colors_skin_6',
        value: 'rgb(77, 37, 35)',
    },
    {
        defaultText: 'Red',
        translationKey: 'general.colors_red',
        value: 'rgb(235, 51, 51)',
    },
    {
        defaultText: 'Orange',
        translationKey: 'general.colors_orange',
        value: 'rgb(234, 174, 62)',
    },
    {
        defaultText: 'Mustard',
        translationKey: 'general.colors_mustard',
        value: 'rgb(241, 228, 78)',
    },
    {
        defaultText: 'Yellow',
        translationKey: 'general.colors_yellow',
        value: 'rgb(245, 244, 13)',
    },
    {
        defaultText: 'Lime',
        translationKey: 'general.colors_lime',
        value: 'rgb(169, 208, 74)',
    },
    {
        defaultText: 'Forest',
        translationKey: 'general.colors_forest',
        value: 'rgb(78, 174, 102)',
    },
    {
        defaultText: 'Teal',
        translationKey: 'general.colors_teal',
        value: 'rgb(42, 129, 133)',
    },
    {
        defaultText: 'Light blue',
        translationKey: 'general.colors_light_blue',
        value: 'rgb(122, 202, 226)',
    },
    {
        defaultText: 'Mid blue',
        translationKey: 'general.colors_mid_blue',
        value: 'rgb(84, 138, 198)',
    },
    {
        defaultText: 'Dark blue',
        translationKey: 'general.colors_dark_blue',
        value: 'rgb(52, 58, 114)',
    },
    {
        defaultText: 'Aubergine',
        translationKey: 'general.colors_aubergine',
        value: 'rgb(81, 43, 108)',
    },
    {
        defaultText: 'Mauve',
        translationKey: 'general.colors_mauve',
        value: 'rgb(166, 81, 144)',
    },
    {
        defaultText: 'Pink',
        translationKey: 'general.colors_pink',
        value: 'rgb(226, 134, 167)',
    },
];

export default colors;

import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import {
    FormControlLabel,
    FormControl,
    Radio,
    FormLabel,
    RadioGroup,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import theme from '../../../../../../../../tap-fe-components/src/theme';

const SingleSelectRadio = withStyles({
    root: {
        color: theme.studentViewInputColor,
        backgroundColor: '#FFFFFF',
        backgroundClip: 'content-box',
        width: '40px',
        height: '40px',
        marginBottom: 'auto',
        marginLeft: '6px',
        '&$checked': {
            color: theme.studentViewInputColor,
            '&:hover': {
                background: theme.studentViewHoverBackground,
            },
        },
        '&:hover': {
            background: theme.studentViewHoverBackground,
        },
        '&:active, &.Mui-focusVisible': {
            background: theme.studentViewActiveBackground,
        },
        '&.Mui-focusVisible': {
            outline: `1px solid ${theme.focusOutline}`,
        },
        '&.Mui-disabled': {
            color: theme.studentViewInputColor,
            opacity: '0.5',
        },
        '& svg': {
            fill: 'currentColor',
            width: '20px',
            height: '20px',
        },
    },
    checked: {},
    disabled: {},
})(Radio);

const RadioControlLabel = withStyles({
    root: {
        wordWrap: 'break-word',
        backgroundColor: '#FAFAFA',
        padding: '9px',
        paddingRight: '20px',
        width: '100%',
        borderRadius: '6px',
        marginLeft: props => (props.showTickSpace ? '48px' : '0px'),
        marginRight: '0',
        alignItems: 'unset',
        '& .MuiFormControlLabel-label': {
            paddingLeft: '8px',
        },
    },
})(FormControlLabel);

const SingleSelectControl = withStyles({
    root: {
        width: '100%',
    },
})(FormControl);

const RadioButtonsWrapper = styled.div`
    div + div {
        margin-top: 12px;
    } 
`;

const CheckCircle = withStyles({
    root: {
        fill: '#0DB43F',
        width: '32px',
        height: '32px',
        margin: 'auto 10px auto 0',
    },
})(CheckCircleIcon);

const CrossCircle = withStyles({
    root: {
        fill: '#D43027',
        width: '32px',
        height: '32px',
        margin: 'auto 10px auto 0',
    },
})(CancelIcon);

const AnswerWrapper = styled.div`
    display: flex;
`;

const Answerlabel = styled.div`
    color: #5D618E;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    margin-bottom: 16px;
    margin-left:${props => (props.noMargin ? '0px' : '48px')};
`;

const NoGuidanceWrapper = styled.div`
    color: #332A7C;
`;

const StyledFormLabel = withStyles({
    root: {
        visibility: 'hidden',
        height: 0,
        padding: 0,
    },
})(FormLabel);

export const TickIconWrapper = styled.div`
    margin-left: auto;
    align-self: center;
    padding-right: 16px;
    padding-left: 0;
`;

const StyledRadioGroup = withStyles({
    root: {
        overflowX: props => (props.showCorrectAnswer ? '' : 'auto'),
    },
})(RadioGroup);

export {
    SingleSelectRadio,
    RadioControlLabel,
    SingleSelectControl,
    RadioButtonsWrapper,
    CheckCircle,
    AnswerWrapper,
    CrossCircle,
    Answerlabel,
    NoGuidanceWrapper,
    StyledFormLabel,
    StyledRadioGroup,
};

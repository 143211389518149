import React from 'react';
import { Route } from 'react-router-dom';
import MCQ from './components/Questions/components/MultipleChoiceQuestion';
import Editable from './components/Questions/components/EditableQuestion';
import Choice from './components/Questions/components/ChoiceMatrix';
import Drawing from './components/Questions/components/DrawingQuestion';

const previewUrl = '/preview';
const routes = [
    <Route path={`${previewUrl}/questions`} component={Choice} key={Choice} />,
    <Route path={`${previewUrl}/MCQ`} component={MCQ} key={MCQ} />,
    <Route path={`${previewUrl}/Editable`} component={Editable} key={Editable} />,
    <Route path={`${previewUrl}/Drawing`} component={Drawing} key={Drawing} />,
];

export default routes;
